import { Typography } from "@mui/material";
import SimpleAccordion from "src/qubCommon/Accordions/SimpleAccordion";
import "./Intro.css";
import LegalFiles from "./LegalFiles";

export default function Intro() {
  return (
    <div className="intro-text">
      <p>
        Σκοπός του <b>Προγράμματος Ημιαυτόνομης Διαβίωσης</b> είναι η υποστήριξη{" "}
        <b>
          των ατόμων ηλικίας δεκαπέντε (15) έως είκοσι έξι (26) ετών που
          διαβιούν σε Μονάδες Παιδικής Προστασίας και Φροντίδας ή σε
          Νοσηλευτικές Μονάδες, με την παροχή
        </b>{" "}
        κατάλληλου πλαισίου εξατομικευμένης φροντίδας και υποστήριξης,
        λαμβάνοντας υπόψη τις αναπτυξιακές τους ανάγκες, προκειμένου να
        επιτευχθεί η σταδιακή και ομαλή αυτονόμησή τους.
      </p>
      <p>
        Για την επίτευξη του σκοπού του Προγράμματος, δημιουργούνται Μονάδες
        Ημιαυτόνομης Διαβίωσης.{" "}
        <b>
          Οι ενδιαφερόμενοι φορείς, μπορούν να ιδρύσουν μία ή περισσότερες
          Μονάδες Ημιαυτόνομης Διαβίωσης (ΜΗΔ) μετά την έγκριση της αίτησης
          χρηματοδότησής τους από το Υπουργείο Κοινωνικής Συνοχής και
          Οικογένειας και την αδειοδότησή τους από την οικεία Περιφερειακή
          Ενότητα.
        </b>
      </p>
      <p>
        Ο εκάστοτε Δικαιούχος Φορέας οφείλει να μεριμνά για τη πλήρη σχολική και
        ακαδημαϊκή ένταξη των ωφελούμενων. Παράλληλα, ο Δικαιούχος Φορέας
        παρέχει υπηρεσίες στέγασης και εξασφαλίζει την ασφαλή και άνετη διαμονή
        των ωφελούμενων, την ψυχοκοινωνική τους υποστήριξη και τη διασύνδεσή
        τους με συμπληρωματικές παροχές και υπηρεσίες.
      </p>
      <p>
        Το Πρόγραμμα Ημιαυτόνομης Διαβίωσης, χρηματοδοτείται από το Ταμείο
        Ανάπτυξης και Ανθεκτικότητας «Ελλάδα 2.0»., στα πλαίσια του έργου «SUB
        3: Μεταφορά εφήβων από Μονάδες Προστασίας Παιδιών (ΜΠΠ) σε Διαμερίσματα
        Ημιαυτόνομης Διαβίωσης», της Δράσης 16919 «Παιδική Προστασία» του
        Εθνικού Σχεδίου Ανάκαμψης και Ανθεκτικότητας «Ελλάδα 2.0» και στοχεύει
        στην σταδιακή και ομαλή αυτονόμηση των ωφελούμενων σε εφαρμογή των
        δημόσιων πολιτικών αποϊδρυματοποίησης.
      </p>
      <LegalFiles />
    </div>
  );
}
