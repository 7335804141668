import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { Grid, Stack, Typography } from "@mui/material";
import CustomSimpleAccordion from "qubCommon/Accordions/CustomSimpleAccordion";
import TextInput from "src/qubCommon/Inputs/TextInput";

const Institute = ({ institute, type = "additional", index }) => {
  const AccordionTitle = () => (
    <Stack direction="row" gap={1}>
      <AccountBalanceIcon
        color="primary"
        fontSize="small"
        sx={{ marginLeft: "2px" }}
      />

      <Typography color="primary" sx={{ fontWeight: 1000 }}>
        {type !== "branch"
          ? "ΔΙΚΑΙΟΥΧΟΣ ΦΟΡΕΑΣ"
          : "ΠΑΡΑΡΤΗΜΑ ΥΠΑΓΩΓΗΣ ΝΕΑΣ ΜΗΔ"}
      </Typography>
      <Typography color="primary" sx={{ marginLeft: "18px", fontWeight: 500 }}>
        {institute?.abbr}
      </Typography>
    </Stack>
  );

  const formName =
    type === "additional" ? `additionalInstitutes.${index}` : type;

  const AccordionContent = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextInput
          disabled
          name={`${formName}.name`}
          label="Επωνυμία"
          defaultValue={institute?.name}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          disabled
          name={`${formName}.abbr`}
          label="Διακριτικός Τίτλος"
          defaultValue={institute?.abbr}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          disabled
          name={`${formName}.address`}
          label="Διεύθυνση"
          defaultValue={institute?.address}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextInput
          disabled
          name={`${formName}.afm`}
          label="ΑΦΜ"
          defaultValue={institute?.afm}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextInput
          disabled
          name={`${formName}.legalForm`}
          label="Νομική Μορφή"
          defaultValue={institute?.legalForm}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          disabled
          name={`${formName}.type`}
          label="Τύπος φορέα"
          defaultValue={institute?.type}
        />
      </Grid>
    </Grid>
  );

  return (
    <CustomSimpleAccordion
      title={<AccordionTitle />}
      content={<AccordionContent />}
    />
  );
};

export default Institute;
