import React, { useState, useEffect } from "react";
import "./qubCommon.css";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const FilePreviewer = ({ file, onClose }) => {
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (!file) return;

    setPreview(URL.createObjectURL(file));
  }, [file]);

  const renderPreview = () => {
    if (!file) return null;

    const fileType = file.type.split("/")[0];

    switch (fileType) {
      case "image":
        return (
          <img src={preview} alt="file preview" style={{ maxWidth: "100%" }} />
        );
      case "text":
        return <textarea readOnly value={preview} />;
      case "application":
        if (file.type === "application/pdf") {
          return (
            <iframe
              src={preview}
              title="PDF preview"
              style={{ width: "100%", height: "80vh" }}
            />
          );
        }
        return <p>Cannot preview this file type.</p>;
      default:
        return <p>Cannot preview this file type.</p>;
    }
  };

  return (
    <Dialog open={!!file} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Typography varianh="h2" sx={{ flexGrow: 1 }}>
          Προεπισκόπηση αρχείου
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <Close />
      </IconButton>
      <Divider />
      <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
        {renderPreview()}
      </DialogContent>
    </Dialog>
  );
};

export default FilePreviewer;
