import axios from "axios";
import { store } from "src/store/store";
import moment from "moment";
/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
import { refreshToken } from "src/apis/controllers/auth";
import { refreshToken as reduxRefreshToken } from "src/store/authReducer";
import { logout } from "store/authReducer";

const env = import.meta.env;

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: env.VITE_REACT_APP_BASE_URL,
});

axiosInstance.interceptors.request.use((config) => {
  if (config.data) {
    Object.keys(config.data).forEach(async (key) => {
      // Find a valid date object and format it
      if (config.data[key] instanceof Date && !Number.isNaN(config.data[key])) {
        config.data[key] = moment(config.data[key]).format(
          "YYYY-MM-DDTHH:mm:ss.SSS"
        );
      }
    });
  }
  const state = store.getState();
  if (!config.headers.Authorization) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${state.auth.token}`,
    };
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log("error", error);
    const originalRequest = error.config;
    console.log(originalRequest._retry, "hello");

    if (error?.message === "Network Error") {
      const errorObject = {
        response: {
          message: error.message,
          status: 503,
        },
      };
      return Promise.reject(errorObject);
    }
    if (error?.response?.status === 453 && !originalRequest._retry) {
      const { dispatch } = store;
      dispatch(logout());
    }

    if (error?.response?.status === 401 && !originalRequest._retry) {
      const state = store.getState();
      const { dispatch } = store;
      originalRequest._retry = true;
      try {
        if (state.auth.refreshToken) {
          const refreshResponse = await refreshToken({
            refreshToken: state.auth.refreshToken,
          });
          console.log("refresh token");
          //   dispatch({ type: REFRESH_TOKEN, payload: refreshResponse });
          originalRequest.headers.Authorization = `Bearer ${refreshResponse?.token}`;
          dispatch(reduxRefreshToken(refreshResponse));
          return axiosInstance(originalRequest);
        }
        dispatch(logout());
      } catch (ex) {
        dispatch(logout());
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
