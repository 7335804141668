import {
  ArrowBack,
  CheckCircleOutline,
  NotInterested,
  Save,
  Send,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ApplicationActions = (props) => {
  const navigate = useNavigate();

  const {
    canEdit = false,
    canCancel = false,
    canSubmit = false,
    approveReject = {},
    handleReject = () => {},
    handleCancel = () => {},
    handleSave = () => {},
    handleSubmit = () => {},
    handleApprove = () => {},
    handlePartiallyApprove = () => {},
    handleApproveSave = () => {},
    handleBackToHome = () => {
      navigate("/home");
    },
  } = props;
  const [disabledButtons, setDisabledButtons] = useState(false);

  const handlePressButton = async (actionHandler) => {
    setDisabledButtons(true);
    await actionHandler();
    setDisabledButtons(false);
  };

  return (
    <div className="buttons-container">
      <Button
        disabled={disabledButtons}
        sx={{ color: "#fefefe" }}
        startIcon={<ArrowBack />}
        color="secondary"
        variant="contained"
        onClick={handleBackToHome}
      >
        Επιστροφή στις αιτήσεις
      </Button>
      {canCancel && (
        <Button
          onClick={handleCancel}
          disabled={disabledButtons}
          startIcon={<NotInterested />}
          color="error"
          variant="contained"
        >
          Ακύρωση
        </Button>
      )}

      {canEdit && (
        <Button
          disabled={disabledButtons}
          hidden={!canEdit}
          onClick={() => handlePressButton(handleSave)}
          startIcon={<Save />}
          variant="contained"
        >
          Αποθήκευση
        </Button>
      )}
      {canSubmit && (
        <Button
          onClick={handleSubmit}
          disabled={disabledButtons}
          hidden={canSubmit}
          startIcon={<Send />}
          variant="contained"
          color="success"
        >
          Υποβολή
        </Button>
      )}

      {approveReject?.canApprove && (
        <Button
          disabled={disabledButtons}
          startIcon={<NotInterested />}
          color="error"
          variant="contained"
          onClick={handleReject}
        >
          {approveReject?.rejectBtnTxt}
        </Button>
      )}
      {approveReject?.canApproveAttachments && (
        <Button
          disabled={disabledButtons}
          startIcon={<Save />}
          variant="contained"
          onClick={handleApproveSave}
        >
          Αποθήκευση
        </Button>
      )}
      {approveReject?.canApprove && (
        <Button
          onClick={handleApprove}
          disabled={disabledButtons}
          hidden={canSubmit}
          startIcon={<CheckCircleOutline />}
          variant="contained"
          color="success"
        >
          {approveReject?.approveBtnTxt}
        </Button>
      )}
      {approveReject?.showPartiallyApprove && (
        <Button
          disabled={disabledButtons}
          hidden={canSubmit}
          startIcon={<CheckCircleOutline />}
          variant="contained"
        >
          {approveReject?.partiallyApproveBtnTxt}
        </Button>
      )}
    </div>
  );
};

export default ApplicationActions;
