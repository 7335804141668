import {
  Menu,
  MenuItem,
  MenuList,
  Divider,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ContentCopy from "@mui/icons-material/ContentCopy";
import { useNavigate } from "react-router";
import {
  IconUser,
  IconBuilding,
  IconWallet,
  IconCertificate,
  IconFileText,
  IconAward,
} from "@tabler/icons-react";
import {
  dataKeyApplicationAthleteDetails,
  dataKeyApplicationClubDetails,
  dataKeyApplicationOfficialDetails,
  dataKeyOfficialDetails,
  dataKeyTransactionHeaders,
  redirectTo,
} from "src/qubCommon/helperMethods/redirectionHelper";

const ExplorerContextMenu = (props) => {
  const { contextMenu, navUrl = "", extraNavs = [], contextMenuIds } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleOpenInNewTab = () => {
    const newWindow = window.open(
      navUrl.replace("{:id}", contextMenu.contextMenuData?.Id),
      "_blank"
    );
    newWindow.focus();
    contextMenu?.handleCloseContextMenu();
  };

  const handleCopyRow = () => {
    navigator.clipboard.writeText(
      Object.entries(contextMenu.contextMenuData)
        .map((item) => `${item[1]}`)
        .join("\t ")
    );
    contextMenu?.handleCloseContextMenu();
  };

  const handleCopyItem = () => {
    navigator.clipboard.writeText(contextMenu.contextMenuCellData);
    contextMenu?.handleCloseContextMenu();
  };
  const handleCopyAll = () => {
    navigator.clipboard.writeText(contextMenu.contextMenuAllData);
    contextMenu?.handleCloseContextMenu();
  };

  const handleNavigate = (path) => {
    window.open(path, "_blank");
    contextMenu?.handleCloseContextMenu();
  };
  const handleApplicationNavigate = (id, options) => {
    console.log("handleApplicationNavigate");
  };
  return (
    <Menu
      anchorEl={contextMenu?.contextMenuAnchor}
      anchorOrigin={{ horizontal: "center", vertical: "center" }}
      open={Boolean(contextMenu?.contextMenuAnchor)}
      onClose={contextMenu?.handleCloseContextMenu}
    >
      {navUrl !== "" && (
        <MenuItem onClick={handleOpenInNewTab}>{t("OpenInNewTab")}</MenuItem>
      )}
      <MenuList>
        {contextMenuIds?.personFunctionIdAthlete && (
          <MenuItem
            onClick={() =>
              handleNavigate(
                `/athletes/manage/${contextMenuIds?.personFunctionIdAthlete}/details`
              )
            }
          >
            <ListItemIcon>
              <IconUser fontSize="small" />
            </ListItemIcon>
            <ListItemText> {t("AthleteDetails")}</ListItemText>
          </MenuItem>
        )}
        {contextMenuIds?.personFunctionIdOfficial && (
          <MenuItem
            onClick={() =>
              handleNavigate(
                `/officials/manage/${contextMenuIds?.personFunctionIdOfficial}/details`
              )
            }
          >
            <ListItemIcon>
              <IconCertificate fontSize="small" />
            </ListItemIcon>
            <ListItemText> {t("OfficialDetails")}</ListItemText>
          </MenuItem>
        )}
        {contextMenuIds?.clubId && (
          <MenuItem
            onClick={() =>
              handleNavigate(`/clubs/manage/${contextMenuIds?.clubId}/details`)
            }
          >
            <ListItemIcon>
              <IconBuilding fontSize="small" />
            </ListItemIcon>
            <ListItemText> {t("ClubDetails")}</ListItemText>
          </MenuItem>
        )}
        {contextMenuIds?.applicationId && (
          <MenuItem
            onClick={() =>
              handleApplicationNavigate(
                contextMenuIds?.applicationId,
                contextMenuIds?.applicationRenderOptions
              )
            }
          >
            <ListItemIcon>
              <IconFileText fontSize="small" />
            </ListItemIcon>
            <ListItemText> {t("ApplicationDetails")}</ListItemText>
          </MenuItem>
        )}
        {contextMenuIds?.transactionId && (
          <MenuItem
            onClick={() =>
              redirectTo(
                navigate,
                dataKeyTransactionHeaders,
                contextMenuIds?.transactionId,
                true,
                null
              )
            }
          >
            <ListItemIcon>
              <IconWallet fontSize="small" />
            </ListItemIcon>
            <ListItemText> {t("TransactionDetails")}</ListItemText>
          </MenuItem>
        )}
        {contextMenuIds?.competitionId && (
          <MenuItem
            onClick={() =>
              handleNavigate(
                `/competitions/manage/${contextMenuIds?.competitionId}/details`
              )
            }
          >
            <ListItemIcon>
              <IconAward fontSize="small" />
            </ListItemIcon>
            <ListItemText> {t("TransactionDetails")}</ListItemText>
          </MenuItem>
        )}

        <Divider />
      </MenuList>

      {extraNavs && extraNavs.length > 0 && (
        <MenuList>
          <Divider />
          <MenuItem onClick={() => console.log("w/e")}>
            {t("NavigateTo")}
          </MenuItem>
          <MenuItem onClick={() => console.log("w/e")}>
            {t("NavigateTo")}
          </MenuItem>
          <MenuItem onClick={() => console.log("w/e")}>
            {t("NavigateTo")}
          </MenuItem>
          <Divider />
        </MenuList>
      )}
      <MenuItem onClick={handleCopyRow}>
        <ListItemIcon>
          <ContentCopy fontSize="small" />
        </ListItemIcon>
        <ListItemText> {t("CopyRow")}</ListItemText>
      </MenuItem>
      {contextMenu.contextMenuCellData && (
        <MenuItem onClick={handleCopyItem}>
          <ListItemIcon>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("CopyItem")}</ListItemText>
        </MenuItem>
      )}
      {contextMenu.contextMenuAllData && (
        <MenuItem onClick={handleCopyAll}>
          <ListItemIcon>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText> {t("CopyAll")}</ListItemText>
        </MenuItem>
      )}
    </Menu>
  );
};

export default ExplorerContextMenu;
