import Login from "../pages/Authentication/Login";
import { Navigate, Outlet } from "react-router-dom";
import { MinimalWrapper } from "../wrappers";
import { Box, Stack } from "@mui/material";
import MainLayout from "src/layout/MainLayout";
import MinimalLayout from "src/layout/MinimalLayout";

const AuthenticationRoutes = () => {
  return [
    {
      path: "/",
      element: <MinimalLayout />,
      children: [
        { path: "/login", element: <Login /> },
        {
          path: "",
          element: <Navigate to="/login" />,
        },
        {
          path: "*",
          element: <Navigate to={`/login`} />,
        },
      ],
    },
  ];
};

export default AuthenticationRoutes;
