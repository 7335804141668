import { Grid, Stack, Typography } from "@mui/material";
import SimpleAccordion from "src/qubCommon/Accordions/SimpleAccordion";
import TextInput from "src/qubCommon/Inputs/TextInput";
import { GavelOutlined } from "@mui/icons-material";
import CustomSimpleAccordion from "qubCommon/Accordions/CustomSimpleAccordion";

const LegalRepresentative = ({ rep }) => {
  const AccordionTitle = () => (
    <Stack direction="row" gap={1}>
      <GavelOutlined
        color="primary"
        fontSize="small"
        sx={{ marginLeft: "2px" }}
      />

      <Typography color="primary" sx={{ fontWeight: 1000 }}>
        ΝΟΜΙΜΟΣ ΕΚΠΡΟΣΩΠΟΣ
      </Typography>
      <Typography color="primary" sx={{ marginLeft: "18px", fontWeight: 500 }}>
        {rep?.lastName} {rep?.firstName}
      </Typography>
    </Stack>
  );

  const AccordionContent = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextInput
          disabled
          name="legalRepresentative.lastName"
          label="Επώνυμο"
          defaultValue={rep?.lastName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          disabled
          name="legalRepresentative.firstName"
          label="Όνομα"
          defaultValue={rep?.firstName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          disabled
          name="legalRepresentative.phone"
          label="Τηλέφωνο"
          defaultValue={rep?.phone}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          disabled
          name="legalRepresentative.email"
          label="E-mail"
          defaultValue={rep?.email}
        />
      </Grid>
    </Grid>
  );

  return (
    <CustomSimpleAccordion
      title={<AccordionTitle />}
      content={<AccordionContent />}
    />
  );
};

export default LegalRepresentative;
