import {
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  Stack,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useTheme } from "@emotion/react";

const DynamicDialog = (props) => {
  const {
    open,
    onClose,
    onAccept,
    meta,
    content,
    type,
    hideSecondaryButton = false,
    ...rest
  } = props;
  const { t } = useTranslation();
  const methods = useFormContext();
  const { errors } = methods?.formState?.errors;
  const theme = useTheme();

  const DialogIcon = () => {
    if (type === "error")
      return <CloseIcon sx={{ fontSize: 50, color: "#c42c0e" }} />;
    if (type === "warning")
      return <WarningIcon sx={{ fontSize: 50, color: "#FF5F15" }} />;
    return (
      <InfoIcon sx={{ fontSize: 50, color: theme.palette.success.main }} />
    );
  };

  const iconBgcolor = () => {
    switch (type) {
      case "error":
        return "#ffe6e6";
      case "warning":
        return theme?.palette?.warning?.main;
      case "success":
        return "#DAF7A6";
      default:
        return theme?.palette?.primary?.main;
    }
  };

  const titleColor = () => {
    switch (type) {
      case "error":
        return "error";
      case "warning":
        return "#FF5F15";
      case "success":
        return theme?.palette?.success?.main;
      default:
        return theme?.palette?.primary?.main;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} {...rest}>
      <DialogTitle>
        <Typography varianh="h3" color={titleColor()} sx={{ flexGrow: 1 }}>
          {meta?.title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={4}
          sx={{ mb: 0 }}
        >
          <Box sx={{ p: 1, background: iconBgcolor(), borderRadius: 50 }}>
            <DialogIcon />
          </Box>
          <DialogContentText>
            <Box
              sx={{
                p: 1,
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word",
              }}
            >
              <Typography>{meta?.description ?? t("UnknownError")}</Typography>
            </Box>
          </DialogContentText>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onAccept} color="primary">
          {t(meta?.action) ?? t("OK")}
        </Button>
        {!hideSecondaryButton && (
          <Button variant="contained" onClick={onClose} color="error">
            {t(meta?.cancel) ?? t("Cancel")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DynamicDialog;
