import { Grid, Stack, Typography } from "@mui/material";
import SimpleAccordion from "src/qubCommon/Accordions/SimpleAccordion";
import TextInput from "src/qubCommon/Inputs/TextInput";
import { AccountCircleOutlined } from "@mui/icons-material";
import Dropdown from "src/qubCommon/Inputs/Dropdown";
import { getAmkaRegistryInfo } from "src/apis/controllers/applications";
import { getByAmkaAfm } from "src/util/getAmkaRegistryInfo";
import { useFormContext } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import { useRef, useState } from "react";
import DynamicDialog from "src/qubCommon/Dialogs/DynamicDialog";
import CustomSimpleAccordion from "qubCommon/Accordions/CustomSimpleAccordion";

const ScientificDirector = ({ dir, jobTypes, canEdit }) => {
  const form = useFormContext();
  const containerRef = useRef();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialog, setDialog] = useState({ message: "Σφάλμα" });

  const AccordionTitle = () => (
    <Stack direction="row" gap={1}>
      <AccountCircleOutlined
        color="primary"
        fontSize="small"
        sx={{ marginLeft: "2px" }}
      />

      <Typography color="primary" sx={{ fontWeight: 1000 }}>
        ΕΠΙΣΤΗΜΟΝΙΚΟΣ ΥΠΕΥΘΥΝΟΣ ΣΧΕΔΙΟΥ
      </Typography>
      <Typography color="primary" sx={{ marginLeft: "18px", fontWeight: 500 }}>
        {dir?.lastName} {dir?.firstName}
      </Typography>
    </Stack>
  );

  const amkaAfmOnBlur = async (e) => {
    const amka = form.getValues("scientificDirector.amka");
    const afm = form.getValues("scientificDirector.afm");
    const { status, data, message } = await getByAmkaAfm(amka, afm);
    if (status === "success") {
      form.setValue("scientificDirector.lastName", data?.lastName);
      form.setValue("scientificDirector.firstName", data?.firstName);
    }
    if (status === "error") {
      setDialog({
        title: "Σφαλμα",
        message,
        onAccept: () => setDialogOpen(false),
      });
      setDialogOpen(true);
      form.setValue("scientificDirector.lastName", null);
      form.setValue("scientificDirector.firstName", null);
    }
  };

  return (
    <CustomSimpleAccordion
      title={<AccordionTitle />}
      content={
        <Grid container spacing={2}>
          <DynamicDialog
            open={dialogOpen}
            meta={{
              title: dialog?.title,
              description: dialog?.message,
              action: "OK",
            }}
            onAccept={dialog.onAccept}
            hideSecondaryButton
            type="error"
          />
          <Grid item xs={6}>
            <div ref={containerRef} tabIndex="-1" />
            <TextInput
              name="scientificDirector.amka"
              label="ΑΜΚΑ"
              blurFn={amkaAfmOnBlur}
              defaultValue={dir?.amka}
              disabled={!canEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              name="scientificDirector.afm"
              label="ΑΦΜ"
              blurFn={amkaAfmOnBlur}
              defaultValue={dir?.afm}
              disabled={!canEdit}
            />
          </Grid>

          <Grid item xs={6}>
            <TextInput
              disabled
              name="scientificDirector.lastName"
              label="Επώνυμο"
              defaultValue={dir?.lastName}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              disabled
              name="scientificDirector.firstName"
              label="Όνομα"
              defaultValue={dir?.firstName}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              name="scientificDirector.jobTitle"
              label="Ιδιότητα στο φορέα"
              defaultValue={dir?.jobTitle}
              disabled={!canEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              name="scientificDirector.phone"
              label="Τηλέφωνο"
              defaultValue={dir?.phone}
              disabled={!canEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              name="scientificDirector.email"
              label="E-mail"
              defaultValue={dir?.email}
              disabled={!canEdit}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

export default ScientificDirector;
