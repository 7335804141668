import { ArrowForward, ArrowRight, ArrowRightAlt } from "@mui/icons-material";
import {
  Grid,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Button,
  Box,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  createApplication,
  getAdditionalInstitutes,
  getInstitutes,
  getNewApplicationTypes,
} from "src/apis/controllers/applications";
import { useLoading } from "src/hooks/useLoading";
import AutocompleteCheckboxes from "src/qubCommon/Inputs/AutoCompleteCheckboxes";
import Dropdown from "src/qubCommon/Inputs/Dropdown";

const NewApplicationPanel = (props) => {
  const form = useForm();
  const { visible } = props;
  const [applicationTypes, setApplicationTypes] = useState([]);
  const [selected, setSelected] = useState(null);
  const [institutes, setInstitutes] = useState([]);
  const [additionalInstitutes, setAdditionalInstitutes] = useState([]);
  const [autoSelectDefaultValues, setAutoSelectDefaultValues] = useState([]);
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  const handleChange = async (id) => {
    setLoading(true);
    form.reset({});
    setSelected(selected === id ? null : id);
    try {
      const res = await getInstitutes(id);

      setInstitutes(res?.data);
      const defaultSelected = res?.data?.filter(
        (inst) => inst?.selected === true
      );
      setAutoSelectDefaultValues(defaultSelected);
      form.setValue(
        "ids",
        defaultSelected.map((sel) => sel?.id)
      );
      await handleAdditionalInstitutes(defaultSelected.map((sel) => sel?.id));
    } catch (e) {
      setLoading(false);
    }
    setLoading(false);
  };

  const fetchApplicationTypes = async () => {
    setLoading(true);
    try {
      const res = await getNewApplicationTypes();
      setLoading(false);
      setApplicationTypes(res?.data);
    } catch {
      setLoading(false);
    }
  };
  const handleAdditionalInstitutes = async (defaultSelectedIds = []) => {
    form.setValue("selid", null);
    const selectedIds = form
      ?.getValues("ids")
      ?.map((selection) => selection?.id);
    setLoading(true);
    try {
      const res = await getAdditionalInstitutes(
        defaultSelectedIds?.length > 0 ? defaultSelectedIds : selectedIds
      );
      setAdditionalInstitutes(res?.data);
    } catch {
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchApplicationTypes();
  }, []);

  const handleCreateApplication = async (data) => {
    try {
      setLoading(true);
      let params = {};
      if (data?.ids?.length > 0) params.ids = data?.ids.map((item) => item?.id);
      if (data?.selid) params.id = data.selid;
      if (data?.id) params.id = data.id;
      const res = await createApplication(params);
      if (res?.status === "success") navigate(`/application/${res?.data}`);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  return (
    <div
      className={visible ? "card with-slide open" : " card with-slide closed"}
    >
      <div className="card-header">
        <h4>
          <b>Λεπτομέρειες Αίτησης</b>
        </h4>
      </div>
      <div className="card-body">
        <Grid container>
          <FormProvider {...form}>
            <Grid item xs={12} md={4}>
              <Stack direction="column">
                <Typography>Τύπος Αίτησης:</Typography>
                <FormGroup>
                  {applicationTypes.map((item) => (
                    <FormControlLabel
                      key={item.id}
                      control={
                        <Checkbox
                          checked={selected === item.id}
                          onChange={() => handleChange(item.id)}
                          name={item.display}
                          disabled={!item.isActive}
                        />
                      }
                      label={item.display}
                    />
                  ))}
                </FormGroup>
              </Stack>
            </Grid>
            <Grid container item xs={12} md={8} spacing={2}>
              {selected === 1 && (
                <>
                  <Grid item xs={12} md={6}>
                    <Dropdown
                      name="id"
                      labelName="name"
                      valueName="id"
                      label="Επιλογή Φορέα ή Παραρτήματος όπου θα υπαχθεί η ΜΗΔ"
                      options={institutes}
                    />
                  </Grid>
                  {form?.watch("id") && (
                    <Grid item xs={12} md={6}>
                      <Button
                        variant="contained"
                        color="secondary"
                        endIcon={<ArrowForward />}
                        sx={{ color: "#fefefe", marginTop: "28px" }}
                        onClick={form?.handleSubmit(handleCreateApplication)}
                      >
                        Συνέχεια
                      </Button>
                    </Grid>
                  )}
                </>
              )}
              {selected === 2 && (
                <>
                  <Grid item xs={12} md={8}>
                    <AutocompleteCheckboxes
                      name="ids"
                      label="Επιλογή Φορέων Σύμπραξης"
                      options={institutes}
                      labelName="display"
                      valueName="id"
                      extraOnChange={handleAdditionalInstitutes}
                      defaultValues={autoSelectDefaultValues}
                    />
                  </Grid>
                  {form?.watch("selid") && form?.watch("ids")?.length > 1 ? (
                    <Grid item xs={12} md={4}>
                      <Button
                        variant="contained"
                        color="secondary"
                        endIcon={<ArrowForward />}
                        sx={{ color: "#fefefe", marginTop: "28px" }}
                        onClick={form?.handleSubmit(handleCreateApplication)}
                      >
                        Συνέχεια
                      </Button>
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={4} />
                  )}
                  <Grid item xs={12} md={8}>
                    <Dropdown
                      name="selid"
                      labelName="name"
                      valueName="id"
                      label="Επιλογή Φορέα ή Παραρτήματος όπου θα υπαχθεί η ΜΗΔ"
                      options={additionalInstitutes}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </FormProvider>
        </Grid>
      </div>
    </div>
  );
};

export default NewApplicationPanel;
