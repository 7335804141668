/* eslint-disable */
import React, { useState, useEffect } from "react";
// mui
import { Stack, Tooltip } from "@mui/material";

// hook forms
import { useFormContext } from "react-hook-form";

import { CssTextField } from "./CssTextField";
import RowLabel from "./RowLabel";
import ColumnLabel from "./ColumnLabel";
import HelpIcon from "@mui/icons-material/HelpOutline";
import { useSelector } from "react-redux";

const TextInput = (props) => {
  const { register, formState, setValue, getValues } = useFormContext();
  const { errors } = formState;
  const { fieldsVariant } = useSelector((state) => state.customization);
  const {
    name,
    rules,
    rowDirection = false,
    label,
    labelWidth,
    variant = fieldsVariant ?? "standard",
    defaultValue,
    innerRef,
    inputProps,
    otherInputProps,
    blurFn,
    fixedPadding = 8,
    inputAlign = "left",
    defaultFromProps = true,
    ...other
  } = props;

  useEffect(() => {
    if (defaultFromProps && defaultValue)
      setValue(name, defaultValue, { shouldDirty: false });
  }, [setValue, name, defaultValue]);

  // const [helpColor, setHelpColor] = useState("black");

  return (
    <Stack direction={rowDirection ? "row" : "column"} spacing={1}>
      {variant === "standard" && (
        <>
          {rowDirection ? ( // variant = 'standard'
            <RowLabel labelWidth={labelWidth} label={label} />
          ) : (
            <ColumnLabel label={label} />
          )}
        </>
      )}
      <CssTextField
        ref={innerRef}
        inputProps={{
          ...register(name, rules),
          style: { textAlign: inputAlign },
        }}
        // variant="outlined"
        label={variant === "standard" ? null : label} // variant = 'outlined
        variant={variant}
        InputProps={{
          disableUnderline: true,
          ...otherInputProps,
          // endAdornment: (
          //   <Tooltip title="I need help">
          //     <HelpIcon sx={{ color: helpColor }} />
          //   </Tooltip>
          // ),
        }}
        error={!!errors[name]}
        // onFocus={() => setHelpColor("orange")}
        onBlur={(e) => {
          if (blurFn) blurFn(e);
        }}
        helperText={errors[name]?.message}
        required={rules?.required}
        size="small"
        InputLabelProps={{ fontSize: "1.25em" }}
        // sx={{
        //   "& .MuiFormLabel-root.Mui-disabled": {
        //     color: "red",
        //   },
        // }}
        fixedPadding={fixedPadding}
        fullWidth
        {...other}
      />
    </Stack>
  );
};

export default TextInput;
