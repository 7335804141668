import { Euro } from "@mui/icons-material";
import { Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import SimpleAccordion from "src/qubCommon/Accordions/SimpleAccordion";
import { calculate } from "src/qubCommon/helperMethods/dateHelper";
import TextInput from "src/qubCommon/Inputs/TextInput";
import {
  formatFloat,
  showFloat,
} from "src/qubCommon/wijmoHelpers/numberMethods";
import "./Expenses.css";
import CurrencyInput from "qubCommon/Inputs/CurrencyInput";
import CustomSimpleAccordion from "qubCommon/Accordions/CustomSimpleAccordion";

const Budget = ({ expenses, budget, expensesForm, canEdit }) => {
  const [childrenInputs, setChildrenInputs] = useState([]);

  useEffect(() => {
    let total = 0;
    budget?.forEach((value) => {
      expensesForm.setValue(`budget.${value?.id}`, showFloat(value?.amount));
      total += value?.amount ?? 0;
    });
    expensesForm.setValue("budgetTotal", showFloat(total));
    let children = [];
    expenses.forEach((expense) => {
      if (expense.children && expense.children.length > 0) {
        expense.children.forEach((child) => {
          children.push({
            id: child?.id,
            parentId: expense?.header?.id,
            isSummary: child?.isSummary,
            hasDataEntry: child?.hasDataEntry,
          });
        });
      }
    });
    setChildrenInputs(children);
    const summaries = children.filter((child) => child?.isSummary);

    summaries.forEach((summary) => {
      let calculatedSum = 0;
      children.forEach((child) => {
        if (child?.parentId === summary?.parentId && child?.hasDataEntry) {
          const childAmount =
            budget?.find((b) => b.id === child?.id)?.amount ?? 0;
          calculatedSum += childAmount;
        }
      });
      expensesForm.setValue(
        `budgetSummary.${summary?.id}`,
        showFloat(calculatedSum)
      );
    });
  }, []);

  const AccordionTitle = () => (
    <Stack direction="row" gap={1}>
      <Euro color="primary" fontSize="small" sx={{ marginLeft: "2px" }} />
      <Typography color="primary" sx={{ fontWeight: 1000 }}>
        ΑΝΑΛΥΤΙΚΟΣ ΠΡΟΫΠΟΛΟΓΙΣΜΟΣ ΔΑΠΑΝΩΝ
      </Typography>
    </Stack>
  );

  const inputOnBlur = (e, id) => {
    if (Number.isNaN(formatFloat(e.target.value)))
      expensesForm.setValue(`budget.${id}`, "");
    else
      expensesForm.setValue(
        `budget.${id}`,
        showFloat(formatFloat(e.target.value))
      );
    const summaries = childrenInputs.filter((child) => child?.isSummary);

    summaries.forEach((summary) => {
      let calculatedSum = 0;
      childrenInputs?.forEach((child) => {
        if (child?.parentId === summary?.parentId && child?.hasDataEntry) {
          const childAmount = expensesForm.getValues(`budget.${child?.id}`);
          if (childAmount) calculatedSum += formatFloat(childAmount);
        }
      });
      expensesForm.setValue(
        `budgetSummary.${summary?.id}`,
        showFloat(calculatedSum)
      );
    });
    const childrenValues = expensesForm.getValues("budget");
    let total = 0;
    childrenValues.forEach((value) => {
      if (value) total += formatFloat(value);
    });
    expensesForm.setValue("budgetTotal", showFloat(total));
  };

  const AccordionContent = () => (
    <FormProvider {...expensesForm}>
      <Grid container>
        <table className="table table-sm bordered-table" id="budgetTable">
          <thead>
            <tr>
              <th scope="col">ΚΑΤΗΓΟΡΙΑ ΔΑΠΑΝΗΣ </th>
              <th scope="col" className="responsive-col">
                ΠΟΣΟ
              </th>
              <th scope="col">ΠΕΡΙΓΡΑΦΗ - ΕΠΕΞΗΓΗΣΗ</th>
            </tr>
          </thead>
          <tbody>
            {expenses?.map((expense) => (
              <React.Fragment key={expense?.header?.id}>
                <tr>
                  <td>
                    <b>{expense?.header?.name}</b>
                    <br />
                    {expense?.header?.description}
                  </td>
                  <td>
                    {expense?.header?.isTotal ? (
                      <CurrencyInput
                        disabled
                        inputAlign="right"
                        name={`budgetTotal`}
                        defaultFromProps={false}
                      />
                    ) : null}
                    {expense?.header?.hasDataEntry ? (
                      <CurrencyInput
                        disabled={!canEdit}
                        inputAlign="right"
                        name={`budget.${expense?.header?.id}`}
                        blurFn={(e) => inputOnBlur(e, expense?.header?.id)}
                        defaultFromProps={false}
                      />
                    ) : null}
                  </td>
                  <td>{expense?.header?.hint}</td>
                </tr>
                {expense?.children?.map((child) => (
                  <tr key={child?.id}>
                    <td>
                      <b> {child?.name} </b>
                      <br />
                      {child?.description}
                    </td>
                    <td>
                      {child?.isSummary ? (
                        <CurrencyInput
                          disabled
                          inputAlign="right"
                          name={`budgetSummary.${child?.id}`}
                          defaultFromProps={false}
                        />
                      ) : null}
                      {child?.hasDataEntry ? (
                        <CurrencyInput
                          disabled={!canEdit}
                          inputAlign="right"
                          name={`budget.${child?.id}`}
                          blurFn={(e) => inputOnBlur(e, child?.id)}
                          defaultFromProps={false}
                        />
                      ) : null}
                    </td>
                    <td>{child?.hint}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </Grid>
    </FormProvider>
  );

  return (
    <CustomSimpleAccordion
      title={<AccordionTitle />}
      content={<AccordionContent />}
    />
  );
};

export default Budget;
