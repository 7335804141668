import { TreeItem } from "@mui/x-tree-view";
import { Box, Stack, Typography, TextField } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import styled from "@emotion/styled";

const StyledTreeItem = (props) => {
  const {
    item,
    handleClickItem,
    handleRightClickItem,
    itemIcon,
    children,
    allowed,
    ...other
  } = props;

  return (
    <TreeItem
      sx={{
        root: {
          "&.Mui-selected > .MuiTreeItem-content": {
            background: "#89CFF0",
          },
          "&.MuiTreeItem-root > .MuiTreeItem-content:hover": {
            background: "gray",
          },
          "&.MuiTreeItem-root > .MuiTreeItem-content:hover > .MuiTreeItem-label":
            {
              background: "#89CFF0",
            },
          "@media (hover: none)": {
            backgroundColor: "transparent",
          },
        },
      }}
      onClick={(e) => {
        if (!item?.isFolder) {
          try {
            console.log(JSON.parse(item?.ViewDefinition));
            if (item?.ViewDefinition)
              handleClickItem(JSON.parse(item?.ViewDefinition), item?.Id);
            else handleClickItem(null, item?.Id);
          } catch (e) {
            handleClickItem(null, item?.Id);
          }
        }
      }}
      onContextMenu={(e) => {
        if (allowed) {
          e.stopPropagation();
          handleRightClickItem(e, item);
        }
      }}
      key={`${item?.Id}`}
      nodeId={`${item?.Id}`}
      label={
        <Stack direction="row">
          {itemIcon}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography>{item?.Description}</Typography>
          </Box>
        </Stack>
      }
      {...other}
    >
      {children}
    </TreeItem>
  );
};

const CustomTreeItem = styled(StyledTreeItem)`
  & > .MuiTreeItem-content.Mui-selected {
    background: #ffd35e;
  }
`;

const RenamingTreeItem = (props) => {
  const {
    renaming,
    inputRef,
    handleTextFieldKeyDown,
    handleRenameOnBlur,
    item,
    handleClickItem,
    handleRightClickItem,
    itemIcon,
    children,
    allowed,
    ...other
  } = props;

  if (renaming)
    return (
      <Stack key={`${item?.Id}`} sx={{ px: "8px" }} direction="row">
        <div style={{ marginRight: "4px", fontSize: "18px" }}>{itemIcon}</div>
        <div style={{ marginTop: "1px", width: "100%" }}>
          <TextField
            inputRef={inputRef}
            onKeyDown={(ev) => handleTextFieldKeyDown(ev)}
            onBlur={(ev) => handleRenameOnBlur(ev)}
            defaultValue={item?.Description}
            fullWidth
            inputProps={{ style: { padding: 1, fontSize: "14px" } }}
          />
        </div>
      </Stack>
    );
  return (
    <CustomTreeItem
      item={item}
      itemIcon={itemIcon}
      handleClickItem={handleClickItem}
      handleRightClickItem={handleRightClickItem}
      allowed={allowed}
    >
      {children}
    </CustomTreeItem>
  );
};

export default RenamingTreeItem;
