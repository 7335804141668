import { Box, Grid, Typography } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { FileFormProps } from '../../types';
import FileFormField from './FileFormField';
import { useEffect } from 'react';
import { useFileContext } from 'context/FilesV2';

const FileForm = (props: FileFormProps) => {
    const { methods, isReadOnly, fields } = props;
    const { control } = useFileContext();

    useEffect(()=>{
        if(methods.formState.isDirty) control.setIsDirty(true)
    },[methods.formState.isDirty])

    return (
        <FormProvider {...methods}>
            <Grid container spacing={1} sx={{ pb: 1 }}>
                {fields?.map((field) => (
                    <Grid item xs={12} key={field.name}>
                        <FileFormField {...field} isReadOnly={isReadOnly} />
                    </Grid>
                ))}
            </Grid>
        </FormProvider>
    );
};

export default FileForm;
