import * as Yup from "yup";

const loginResolver = Yup.object().shape({
  email: Yup.string()
    .required("Το email είναι υποχρεωτικό.")
    .email("Το email δεν είναι έγκυρο."),
  password: Yup.string().required("Ο κωδικός πρόσβασης είναι υποχρεωτικός."),
});

export default loginResolver;
