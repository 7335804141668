import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  fieldsVariant: "standard",
  backgroundColor: "#fefefe",
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationSlice = createSlice({
  name: "menu",
  initialState: initialState,
  reducers: {
    setCustomization: (state, action) => {
      return {
        ...state,
        fieldsVariant: action.payload?.fieldsVariant,
        backgroundColor: action.payload?.backgroundColor,
      };
    },
    resetCustomization: () => initialState,
  },
});

export const { setCustomization, resetCustomization } =
  customizationSlice.actions;

export default customizationSlice.reducer;
