import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getApplications } from "src/apis/controllers/applications";
import { useLoading } from "src/hooks/useLoading";

const ApplicationsGrid = ({ applications }) => {
  const navigate = useNavigate();

  const handleOpenApplication = (id) => {
    navigate(`/application/${id}`);
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4>
          <b>Οι Αιτήσεις μου</b>
        </h4>
      </div>
      <div className="card-body">
        {applications?.length > 0}
        <table className="bordered-table-applications">
          <thead>
            <tr>
              <th>#</th>
              <th>A/A Υποβολής</th>
              <th>Ημερομηνία</th>
              <th>Παράρτημα</th>
              <th>Διαμ/Θέσεις</th>
              <th>Π/Υ</th>
              <th>Καταστάση</th>
              <th>Ενέργειες</th>
            </tr>
          </thead>
          {applications?.length > 0 ? (
            <tbody>
              {applications?.map((application) => {
                return (
                  <tr key={application?.id}>
                    <td>{application?.position}</td>
                    <td>{application?.protocolNumber}</td>
                    <td>
                      {application?.submittedAt ?? application?.createdAt}
                    </td>
                    <td>{application?.branch}</td>
                    <td>
                      {application?.numAptms}/{application?.sumCapacity}
                    </td>
                    <td>{application?.budget}</td>
                    <td>{application?.status}</td>
                    <td>
                      {application?.canEdit ? (
                        <div className="action-buttons">
                          <button
                            onClick={() =>
                              handleOpenApplication(application?.id)
                            }
                            className="btn btn-edit"
                          >
                            <EditIcon />
                          </button>
                        </div>
                      ) : (
                        <div className="action-buttons">
                          <button
                            onClick={() =>
                              handleOpenApplication(application?.id)
                            }
                            className="btn btn-view"
                          >
                            <VisibilityIcon />
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tr>
              <td
                colspan="8"
                align="center"
                style={{ border: "none", padding: 20 }}
              >
                <h3 style={{ fontSize: "1.25em" }}> Δεν βρέθηκαν αιτήσεις.</h3>
              </td>
            </tr>
          )}
        </table>
      </div>
    </div>
  );
};

export default ApplicationsGrid;
