import { ImageCropProps, ImageCropRef } from '../types';
import { Box, Stack, Menu, MenuItem, ListItemText, IconButton, Grid } from '@mui/material';
import ReactCrop, { type Crop } from 'react-image-crop';
import { useState, forwardRef, useImperativeHandle, useEffect, useCallback, useRef, ReactEventHandler } from 'react';
import { CropRotateOutlined, FlipRounded, AspectRatioRounded } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import 'react-image-crop/dist/ReactCrop.css';

const ImageCrop = forwardRef<ImageCropRef, ImageCropProps>((props, ref) => {
    const { src, editMode, onCrop, fixedAspectRatio } = props;
    const aspectRatioList = [
        { id: 1, label: 'Landscape (16/9)', value: 16 / 9 },
        { id: 2, label: 'Portrait (4/5)', value: 4 / 5 },
        { id: 3, label: 'Square (1/1)', value: 1 },
        { id: 4, label: 'A4 (21/29.7)', value: 21 / 29.7 },
        { id: 5, label: 'A3 (29.7/42)', value: 29.7 / 42 },
        { id: 6, label: 'A2 (42/59.4)', value: 42 / 59.4 },
        { id: 7, label: 'A1 (59.4/84.1)', value: 59.4 / 84.1 },
        { id: 8, label: 'A0 (84.1/118.9)', value: 84.1 / 118.9 },
        { id: 9, label: 'Free', value: undefined }
    ];
    const { enqueueSnackbar } = useSnackbar();
    const [selectedId, setSelectedId] = useState<number>(1);
    const [aspectRatio, setAspectRatio] = useState<number | undefined>(fixedAspectRatio ?? aspectRatioList[0].value);
    const [rotation, setRotation] = useState<number>(0);
    const [flip, setFlip] = useState<boolean>(false);
    const [crop, setCrop] = useState<Crop>({
        x: 5,
        y: 5,
        unit: '%',
        height: 20,
        width: 20 * (aspectRatio ?? 1)
    });
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openArMenu = Boolean(anchorEl);

    const [imageSrc, setImageSrc] = useState<string>(src instanceof File ? URL.createObjectURL(src) : src);

    useEffect(() => {
        if (src) setImageSrc(src instanceof File ? URL.createObjectURL(src) : src);
    }, [src]);

    const handleRotation = () => {
        const image = new Image();
        image.src = src instanceof File ? URL.createObjectURL(src) : src;

        image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            if (rotation === 0 || rotation === 180) {
                canvas.width = image.height;
                canvas.height = image.width;
            } else {
                canvas.width = image.width;
                canvas.height = image.height;
            }

            ctx?.clearRect(0, 0, canvas.width, canvas.height);
            ctx?.translate(canvas.width / 2, canvas.height / 2);
            ctx?.rotate(((rotation + 90) * Math.PI) / 180);
            ctx?.drawImage(image, -image.width / 2, -image.height / 2);

            const newSrc = canvas.toDataURL();
            setRotation((prev) => (prev + 90) % 360);
            setImageSrc(newSrc);
        };
    };

    useImperativeHandle(ref, () => ({
        handleSave() {
            const image = new Image();
            image.src = imageSrc;

            image.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                // Adjust canvas dimensions based on crop selection
                canvas.width = crop.width;
                canvas.height = crop.height;
                if (ctx) ctx.fillStyle = '#fff';
                ctx?.fillRect(0, 0, canvas.width, canvas.height);
                ctx?.drawImage(image, crop.x, crop.y, crop.width, crop.height, 0, 0, crop.width, crop.height);

                // Get data URL of cropped image
                // const newSrc = canvas.toDataURL();
                canvas.toBlob((blob) => {
                    const file = new File([blob as Blob], src instanceof File ? src.name : 'cropped_image.jpg', { type: 'image/jpeg' });
                    onCrop(file);
                }, 'image/jpeg');
                // Optionally, you can save this newSrc or perform further actions
                // For example:
                // enqueueSnackbar("Image cropped and saved successfully", { variant: "success" });
            };
            return true;
        }
    }));

    const handleSelectAspectRatio = (aspect: number | undefined, id: number) => {
        setSelectedId(id);
        setAspectRatio(aspect);
        setAnchorEl(null);
    };

    return (
        <Grid
            container
            sx={{
                justifyContent: 'center',
                alignItems: !editMode ? 'center' : 'flex-end',
                height: '100%'
            }}
        >
            {editMode && (
                <Grid
                    item
                    container
                    xs={12}
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: 'white',
                        height: '90%'
                    }}
                >
                    <ReactCrop
                        crop={crop}
                        onChange={(c) => setCrop(c)}
                        aspect={aspectRatio}
                        style={{
                            alignItems: 'center',
                            height: 'fit-content'
                        }}
                    >
                        <img
                            src={imageSrc}
                            alt={src instanceof File ? src.name : 'Crop'}
                            style={{
                                transform: `scaleX(${flip ? -1 : 1})`
                            }}
                        />
                    </ReactCrop>
                </Grid>
            )}
            {editMode && (
                <Grid
                    item
                    container
                    xs={12}
                    sx={{
                        justifyContent: 'space-evenly',
                        alignItems: 'flex-end',
                        height: 'fit-content'
                    }}
                >
                    <IconButton onClick={handleRotation}>
                        <CropRotateOutlined />
                    </IconButton>
                    <IconButton onClick={() => setFlip((prev: boolean) => !prev)}>
                        <FlipRounded />
                    </IconButton>
                    <IconButton disabled={!!fixedAspectRatio} onClick={(e: React.MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget)}>
                        <AspectRatioRounded />
                    </IconButton>
                </Grid>
            )}
            {!editMode && (
                <Grid item container xs={12} sx={{ justifyContent: 'center' }}>
                    <img
                        src={imageSrc}
                        alt={src instanceof File ? src.name : 'Crop'}
                        style={{
                            transform: `scaleX(${flip ? -1 : 1})`
                        }}
                    />
                </Grid>
            )}
            <Menu anchorEl={anchorEl} open={openArMenu} onClose={() => setAnchorEl(null)}>
                {aspectRatioList.map((item) => (
                    <MenuItem
                        key={item.id}
                        onClick={() => handleSelectAspectRatio(item?.value, item.id)}
                        sx={{
                            background: selectedId === item.id ? '#4d79bc' : ''
                        }}
                    >
                        <ListItemText primary={item.label} />
                    </MenuItem>
                ))}
            </Menu>
        </Grid>
    );
});

export default ImageCrop;
