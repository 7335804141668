import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { menuActive } from "src/store/menuReducer";

/**
 * [setMenuItem]
 * void function to set the active menu item
 * @param  {[String]} menuItemId [Menu Item Id]
 * @example
 *  const { setMenuItem } = useMenu();
 *  setMenuItem("HomeMenuItem");
 *
 *
 */
/* eslint-disable */
function recursiveSearch(matchArray, searchArray, index = 0) {
  if (index >= matchArray.length) {
    return null; // All items have been successfully matched or the matchArray is empty
  }

  for (const item of searchArray) {
    // Check if the URL part matches the title (or another relevant property)
    if (
      item.url
        .toLowerCase()
        .split("/")
        .includes(matchArray[index].toLowerCase())
    ) {
      if (index === matchArray.length - 1 || !item.children) {
        return item.id; // Match found and no further items to match or no children to search into
      } else {
        // Search in the children
        return recursiveSearch(matchArray, item.children, index + 1);
      }
    }
  }

  return null; // No match found
}

export const useMenu = () => {
  let pathArray = [];
  try {
    pathArray = window?.location?.pathname.split("/");
    pathArray.shift();
  } catch (e) {}
  const { menuItems } = useSelector((state) => state.menu);

  const activeItem = recursiveSearch(pathArray, menuItems);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(menuActive(activeItem));
  }, [dispatch, activeItem]);
};
