import { Grid, Typography } from "@mui/material";

const url = import.meta.env.VITE_REACT_APP_BASE_URL;

const Footer = () => {
  return (
    <div
      style={{
        background: "#ffffff",
        borderTop: "4px solid #0065B2",
        width: "100%",
        height: 100,
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          py: 2,
          width: "100%",
        }}
      >
        <Grid container item sm={6} md={3} justifyContent="center">
          <img
            style={{ height: "56px" }}
            src={`${url}/images/ykoiso.png`}
            alt="gov"
          />
        </Grid>
        <Grid container item sm={6} md={2} justifyContent="center">
          <img
            style={{ height: "56px" }}
            src={`${url}/images/greece_2_0.png`}
            alt="gov"
          />
        </Grid>
        <Grid container item sm={6} md={2} justifyContent="center">
          <img
            style={{ height: "56px" }}
            src={`${url}/images/hellenic_energy.png`}
            alt="gov"
          />
        </Grid>
        <Grid
          container
          item
          sm={6}
          md={5}
          justifyContent="flex-end"
          alignContent="flex-end"
        >
          <a className="footer-link" href="https://qubiteq.gr/">
            Copyright © 2024 anynet.gr | Developed by
          </a>
          &nbsp;
          <a href="https://qubiteq.gr/"> Qubiteq</a>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
