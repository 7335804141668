import React, { createContext } from "react";
import { Ability, defineAbility } from "@casl/ability";
import { createContextualCan, useAbility } from "@casl/react";
import propTypes from "prop-types";
import { useSelector } from "react-redux";

const Context = createContext();

function usePermissions() {
  const permissions = useAbility(Context);
  if (permissions === undefined) {
    throw new Error(
      "usePermissions should be used within a PermissionProvider"
    );
  }
  return permissions;
}

const PermissionProvider = ({ children }) => {
  const user = useSelector((state) => state?.auth?.user);
  const formatedPermissions = user?.permissions?.map((dbPermission) => {
    const [subject, action] = dbPermission?.split(".");
    return {
      action,
      subject,
    };
  });
  const abilities = defineAbility((can) => {
    formatedPermissions?.forEach((element) => {
      can(element?.action, element?.subject);
    });
  });
  return <Context.Provider value={abilities}>{children}</Context.Provider>;
};

PermissionProvider.propTypes = {
  children: propTypes.node,
};

export { usePermissions, PermissionProvider };
export const Can = createContextualCan(Context.Consumer);
