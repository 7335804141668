import axiosInstance from "../axiosInstance";

export const filesApi = {
  post: (path, params, headers, cancelToken) =>
    axiosInstance
      .post(`/Files${path}`, params, {
        cancelToken: cancelToken?.token,
        headers,
      })
      .then((res) => {
        if (res?.data?.error) throw res.data;
        return res?.data;
        // if (res?.data?.error || res?.data?.status === 'error') throw res.data;
      })
      .catch((err) => {
        throw err.error ? err : err?.response?.data;
      }),
  get: (path, params, options, cancelToken) =>
    axiosInstance
      .get(`/Files${path}`, {
        params,
        ...options,
        cancelToken: cancelToken?.token,
      })
      .then((res) => {
        if (res?.data?.error) throw res.data;
        return res?.data;
      })
      .catch((err) => {
        throw err.error ? err : err?.response?.data;
      }),
};

export const getNewApplicationTypes = async () =>
  filesApi.get("/GetNewApplicationTypes");

export const getFiles = async (params) =>
  filesApi.post("/GetFiles", JSON.stringify(params), {
    "Content-Type": "application/json",
  });

export const downloadFile = async (params, options) =>
  filesApi.get("/Downloadfile", params, options);

export const downloadFileAsBase64 = async (params) =>
  filesApi.get("/downloadfileasbase64", params);

export const uploadFiles = async (params, options) =>
  filesApi.post("/uploadfiles", params, options);

export const deleteFile = async (params, options) =>
  filesApi.post("/deleteFile", JSON.stringify(params), {
    "Content-Type": "application/json",
  });
