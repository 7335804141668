import { FileInputHandle, FileInputProps, FileListHandle } from '../types';
import { IdentifiedFile } from 'context/FilesV2/types';
import { fileFormValidation } from '../utils';
import { useSnackbar } from 'notistack';
import { useRef, useState, useImperativeHandle, ForwardedRef, useEffect, useMemo, memo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { v4 as uuidv4 } from 'uuid';
import imageCompression from 'browser-image-compression';
import { FileRejection } from 'React-dropzone';
import { useFileContext } from 'context/FilesV2';
import { debounce } from 'lodash';
import { uploadFiles } from 'apis/controllers/files';

const useFileInput = (props: FileInputProps, ref: ForwardedRef<FileInputHandle>) => {
    const { fields, bundle, onUpload, maxSize, maxFiles, aspectRatio } = props;
    const { control } = useFileContext();
    const { enqueueSnackbar } = useSnackbar();
    const listRef = useRef<FileListHandle>(null);
    const gRef = useRef<HTMLDivElement>(null);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<string | null>(null);


    const formValidation = fileFormValidation(fields);
    const methods = useForm({
        resolver: yupResolver(formValidation),
        defaultValues: bundle?.values
    });
    const watch = useWatch({ control: methods.control });


    // useEffect(()=>{
    //     if (methods.formState.errors) {
    //         let errorsArray = Object.keys(methods.formState.errors)
    //         console.log(errorsArray)
    //         if (errorsArray.length) {
    //             let temp = document.getElementById(errorsArray[0])
    //             if (temp) {
    //                 console.log(temp)
    //                 temp.scrollIntoView({ behavior: `smooth`, block: 'center'});
    //             }
    //         }
    //     }
    // },[methods.formState.errors])

    const debouncedUpdate = useMemo(
        () =>
            debounce((data: { [key: string]: string | number | boolean | undefined }) => {
                if (bundle) {
                    control.updateBundle(bundle.id, data);
                }
            }, 500),
        []
    );

    useEffect(() => {
        debouncedUpdate(watch);
    }, [watch]);

    const upload = () => {
        if (bundle && bundle.files.length === 0) {
            enqueueSnackbar('No files to upload', { variant: 'error' });
            return Promise.resolve(false);
        }
        // todo refactor error on files
        // const fileErrors = files.filter((file) => file.error);
        // if (fileErrors.length > 0) {
        //     fileErrors.forEach((file) => {
        //         enqueueSnackbar(`${file.name}: ${file.error}`, {
        //             variant: 'error'
        //         });
        //     });
        //     return Promise.resolve(false);
        // }
        return new Promise<boolean>((resolve, reject) => {
            methods.handleSubmit(async (data) => {
                try {
                    if (bundle) await onUpload(bundle.files, data);
                    resolve(true);
                } catch (error) {
                    enqueueSnackbar((error as Error).message, { variant: 'error' });
                    reject(error);
                }
            })();
            // resolve(false);
            // onUpload(files, data);
        });
    };

    const validateForm = () => {
        return new Promise<boolean>((resolve) => {
            methods
                .trigger()
                .then((valid) => {
                    resolve(valid);
                })
                .catch((error) => {
                    console.error(error);
                    resolve(false);
                });

        });
    };

    const validateFile = (file?: File | null, aspectRatio?: number): Promise<string | null> => {
        return new Promise((resolve) => {
            if (!file) {
                resolve(null);
            } else if (!file.type.startsWith('image') && maxSize && file.size > maxSize) {
                resolve(`File size exceeds ${maxSize / 1024 / 1024} MB`);
            } else if (file.type.startsWith('image') && aspectRatio && aspectRatio !== 0) {
                const img = new Image();
                img.src = URL.createObjectURL(file);
                img.onload = () => {
                    if ((img.width / img.height).toFixed(2) !== aspectRatio.toFixed(2)) {
                        resolve('Aspect ratio must mismatch');
                    } else {
                        resolve(null);
                    }
                };
                img.onerror = () => resolve('Error loading image');
            } else {
                resolve(null);
            }
        });
    };

    const addFiles = async (newFiles: File[]) => {
        if (maxFiles && bundle && bundle.files.length + newFiles.length > maxFiles) {
            newFiles = newFiles.slice(0, maxFiles - bundle.files.length);
            enqueueSnackbar(`Only ${maxFiles} files can be uploaded at a time`, {
                variant: 'error'
            });
        }

        // todo refactor loading
        const placeholders = newFiles.map((file) => ({
            id: uuidv4(),
            name: file.name,
            type: file.type,
            fileObject: file.type.startsWith('image') ? undefined : file,
            position: 0,
            // compressed: false,
            // loading: file.type.startsWith('image'),
            saved: false
        }));
        if (bundle) {
            control.addFiles(bundle.id, placeholders);
        }
        // setFiles((prev: IdentifiedFile[]) => [...prev, ...placeholders]);

        const processFile = async (file: File, id: string): Promise<void> => {
            if (file.type.startsWith('image')) {
                try {
                    const compression = await imageCompression(file, {
                        maxSizeMB: 1,
                        maxWidthOrHeight: 1024,
                        useWebWorker: true
                    });
                    const newFile = new File([compression], file.name, {
                        type: file.type
                    });
                    const img = new Image();
                    img.src = URL.createObjectURL(newFile);
                    // todo refactor loading a/r and error
                    img.onload = async () => {
                        const validationError = await validateFile(file, aspectRatio);
                        control.updateFile(id, {
                            name: file.name,
                            type: file.type,
                            position: 0,
                            saved: false,
                            fileObject: newFile
                        });
                        // setFiles((prev: IdentifiedFile[]) =>
                        //     prev.map((f) =>
                        //         f.id === id
                        //             ? {
                        //                   id: id,
                        //                   name: file.name,
                        //                   type: file.type,
                        //                   fileObject: newFile,
                        //                   position: 0,
                        //                   saved: false
                        //                   compressed: true,
                        //                   aspectRatio: img.width / img.height,
                        //                   loading: false,
                        //                   error: validationError
                        //               }
                        //             : f
                        //     )
                        // );
                        // onAddFile &&
                        //     onAddFile({
                        //         id: id,
                        //         fileObject: newFile,
                        //         name: file.name,
                        //         type: file.type,
                        //         position: 0,
                        //         compressed: true,
                        //         saved: false
                        //     });
                    };
                    img.onerror = (error) => {
                        console.error(error);
                    };
                } catch (error) {
                    console.error(error);
                }
            } else {
                // todo refactor loading a/r and error
                const validationError = await validateFile(file, aspectRatio);
                control.updateFile(id, {
                    name: file.name,
                    type: file.type,
                    position: 0,
                    saved: false,
                    fileObject: file
                });
                // setFiles((prev: IdentifiedFile[]) =>
                //     prev.map((f) =>
                //         f.id === id
                //             ? {
                //                   id: id,
                //                   name: file.name,
                //                   type: file.type,
                //                   fileObject: file,
                //                   position: 0,
                //                   saved: false
                //                   compressed: false,
                //                   loading: false,
                //                   error: validationError
                //               }
                //             : f
                //     )
                // );
                // onAddFile &&
                //     onAddFile({
                //         id: id,
                //         name: file.name,
                //         type: file.type,
                //         fileObject: file,
                //         position: 0,
                //         compressed: false,
                //         saved: false
                //     });
            }
        };

        newFiles.forEach((file, index) => {
            processFile(file, placeholders[index].id);
        });
    };

    const updateFile = async (file: IdentifiedFile) => {
        const validationError = await validateFile(file.fileObject, aspectRatio);
        // todo refactor loading a/r and error
        control.updateFile(file.id, {
            name: file.name,
            type: file.type,
            position: 0,
            saved: false,
            fileObject: file.fileObject
        });
        // setFiles((prev) =>
        //     prev.map((f) =>
        //         f.id === file.id
        //             ? {
        //                   ...f,
        //                   file: file.fileObject,
        //                   aspectRatio: aspectRatio,
        //                   error: validationError,
        //                   saved: false
        //               }
        //             : f
        //     )
        // );
    };

    const removeFile = (id: string) => {
        control.deleteFile(id);
        // setFiles((prev) => prev.filter((file) => file.id !== id));
    };

    const rejectFiles = (rejections: FileRejection[]) => {
        rejections.forEach(() => {
            enqueueSnackbar(
                'Σφάλμα: Tο μέγεθος του αρχείου δεν πρέπει να υπερβαίνει τα 2MB.',
                {
                    variant: 'error'
                }
            );
        });
    };

    
    const updateStatus = async (data: Record<string, any>) => {
        try {
        const formData = new FormData()
        formData.append('Files[0].BundleId',data.id)
        formData.append('Files[0].FileTypeId',data.fileType)
        formData.append('Files[0].Status',data.status)
        formData.append('Files[0].ReviewComments',data.comments)
        formData.append('Files[0].RowGuid', uuidv4())
        if (bundle?.values?.dateFrom) formData.append(`Files[0].DateFrom`, bundle.values.dateFrom.toString());
        if (bundle?.values?.dateTo) formData.append(`Files[0].DateTo`, bundle.values.dateTo.toString());
        if (bundle?.values?.issuedAt) formData.append(`Files[0].IssuedAt`, bundle.values.issuedAt.toString());
        if (bundle?.values?.issuedBy) formData.append(`Files[0].IssuedBy`, bundle.values.issuedBy.toString());
        if (bundle?.values?.number) formData.append(`Files[0].Number`, bundle.values.number.toString());
        if (bundle?.values?.common) formData.append(`Files[0].CommonId`, bundle.values.common.toString());
        if (bundle?.values?.checkboxChecked) formData.append(`Files[0].Checked`, bundle.values.checkboxChecked.toString());
        if (bundle?.values?.approvedAt) formData.append(`Files[0].ApprovedAt`, bundle.values.approvedAt.toString());
        if (bundle?.values?.approvedBy) formData.append(`Files[0].ApprovedBy`, bundle.values.approvedBy.toString());
        if (bundle?.values?.approvedComment) formData.append(`Files[0].ApprovedComment`, bundle.values.approvedComment.toString());
        if (bundle?.values?.rejectedAt) formData.append(`Files[0].RejectedAt`, bundle.values.rejectedAt.toString());
        if (bundle?.values?.rejectedBy) formData.append(`Files[0].RejectedBy`, bundle.values.rejectedBy.toString());
        if (bundle?.values?.rejectedComment) formData.append(`Files[0].RejectedComment`, bundle.values.rejectedComment.toString());
        const res = await uploadFiles(formData);
        if (res?.status === 'success') enqueueSnackbar('Η κατάσταση του δικαιολογητικού ενημερώθηκε επιτυχώς!',{variant:'success'})
        else enqueueSnackbar(`Σφάλμα: ${res?.message}`,{variant:'error'} )
        }
        catch (e: any) {
            enqueueSnackbar(`Σφάλμα: ${e.error}`,{variant:'error'} )
        }
        

    }

    const openEdit = (id: string) => {
        setEditMode(true);
        setSelectedFile(id);
    };

    const expandAll = () => {
        listRef.current?.expandAll();
    };

    const collapseAll = () => {
        listRef.current?.collapseAll();
    };

    useImperativeHandle(ref, () => ({
        focus: () => {
            gRef.current?.scrollIntoView({ behavior: 'smooth' });
        },
        upload: () => upload(),
        validateFileForm: () => validateForm()
    }));

    return {
        methods,
        selectedFile,
        setSelectedFile,
        editMode,
        setEditMode,
        upload,
        addFiles,
        updateFile,
        removeFile,
        rejectFiles,
        updateStatus,
        openEdit,
        expandAll,
        collapseAll,
        listRef,
        gRef
    };
};

export default useFileInput;
