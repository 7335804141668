// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Import translation files
import enCommon from "./en/common.json";
import elCommon from "./el/common.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    partialBundledLanguages: true,
    transSupportBasicHtmlNodes: true,
    lng: "el",
    fallbackLng: "el",
    defaultNS: "common",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        common: enCommon,
      },
      el: {
        common: elCommon,
      },
    },
  });

export default i18n;
