import { useFileContext } from "context/FilesV2";
import { ReviewActionsProps } from "../types";
import { Button, Stack, Divider, Typography, Grid } from "@mui/material";
import { Dropdown, TextInput } from "qubCommon/Inputs";
import RadioInput from "qubCommon/Inputs/RadioInput";
import { useForm, FormProvider } from "react-hook-form";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const ReviewActions = (props: ReviewActionsProps) => {
    const { onUpdateStatus, defaultStatus, defaultValidationComments,bundle } = props;
    const {user} = useSelector(state => state.auth)
    const { control } = useFileContext();
    const methods = useForm({
        defaultValues: {
            comments: defaultValidationComments,
            status: defaultStatus,
        },
    });

    useEffect(()=>{
        if (methods.formState.isDirty) control.setIsDirty(true)

    },[methods.formState.isDirty])

    const handleSaveStatus = (data: Record<string, any>) => {
        onUpdateStatus({...bundle,...data,userId:user.id});
    };

    return (
        <FormProvider {...methods}>
            <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
                <Divider sx={{ borderWidth: 1 }} />
           
                <Grid container sx={{gap:2}} >
                <Grid item xs={12}>
                        <RadioInput
                            labelWidth={100}
                            rowDirection='row'
                            name="status"
                            label="Κατάσταση"
                            defaultValue={defaultStatus}
                            changeFn={(e) => { bundle.status = e.target.value}}
                            options={[
                                { Value: 1, Label: "Σε έλεγχο" },
                                { Value: 2,Label: "Αποδεκτό" },
                                { Value: 3, Label: "Μη αποδεκτό" },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                        name="comments"
                        label="Σχόλια"
                        blurFn={(e) => {
                            bundle.reviewComments = (e.target.value)
                        }}
                        defaultFromProps={false}
                        multiline
                        rows={3}
                    />
                    </Grid>
                    {/* <Grid
                        item
                        container
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        xs={12}
                    >
                        <Button
                            onClick={() =>
                                methods.handleSubmit(
                                    (data: Record<string, any>) =>
                                        handleSaveStatus(data)
                                )()
                            }
                            variant="contained"
                            sx={{
                                background: "#4d79bc",
                                color: "#fff",
                                ":hover": {
                                    background: "#79AEC9",
                                    color: "#fff",
                                },
                            }}
                        >
                            Αποθήκευση
                        </Button>
                    </Grid> */}
                </Grid>
            </Stack>
        </FormProvider>
    );
};

export default ReviewActions;
