import axiosInstance from "../axiosInstance";

export const adminApi = {
  post: (path, params, headers, cancelToken) =>
    axiosInstance
      .post(`/Admin${path}`, params, {
        cancelToken: cancelToken?.token,
        headers,
      })
      .then((res) => {
        if (res?.data?.error) throw res.data;
        return res?.data;
        // if (res?.data?.error || res?.data?.status === 'error') throw res.data;
      })
      .catch((err) => {
        throw err.error ? err : err?.response?.data;
      }),
  get: (path, params, options, cancelToken) =>
    axiosInstance
      .get(`/Admin${path}`, {
        params,
        ...options,
        cancelToken: cancelToken?.token,
      })
      .then((res) => {
        if (res?.data?.error) throw res.data;
        return res?.data;
      })
      .catch((err) => {
        throw err.error ? err : err?.response?.data;
      }),
};

export const impersonate = async (params) =>
  adminApi.post("/Impersonate", JSON.stringify(params), {
    "Content-Type": "application/json",
  });

export const exitImpersonation = async (params) =>
  adminApi.get("/ExitImpersonation", JSON.stringify(params), {
    "Content-Type": "application/json",
  });
