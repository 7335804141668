import { Grid, Stack, Typography } from "@mui/material";
import SimpleAccordion from "src/qubCommon/Accordions/SimpleAccordion";
import { House } from "@mui/icons-material";
import ApartmentsTable from "./ApartmentsTable";
import CustomSimpleAccordion from "qubCommon/Accordions/CustomSimpleAccordion";

const Apartments = ({ apartments, branch, canEdit }) => {
  const AccordionTitle = () => (
    <Stack direction="row" gap={1}>
      <House color="primary" fontSize="small" sx={{ marginLeft: "2px" }} />
      <Typography color="primary" sx={{ fontWeight: 1000 }}>
        ΟΜΑΔΕΣ ΣΤΟΧΟΙ / ΔΙΑΜΕΡΙΣΜΑΤΑ
      </Typography>
    </Stack>
  );

  return (
    <CustomSimpleAccordion
      title={<AccordionTitle />}
      content={
        <Grid container>
          <ApartmentsTable
            apartments={apartments}
            branch={branch}
            canEdit={canEdit}
          />
        </Grid>
      }
    />
  );
};

export default Apartments;
