import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

// material-ui
import CssBaseline from "@mui/material/CssBaseline";
import { styled, useTheme } from "@mui/material/styles";
import {
  AppBar,
  Box,
  Grid,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";

// project imports
// import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from "./Header";
// import Sidebar from './Sidebar';
// import navigation from 'menu-items';
// import { drawerWidth } from 'store/constant';
// import { SET_MENU } from 'store/actions';

// assets
import { IconChevronRight } from "@tabler/icons-react";
import Sidebar from "./Sidebar";
import { handleOpen } from "src/store/menuReducer";
import { drawerWidth } from "src/store/constant";
import Footer from "./Footer.jsx";
import "./index.css";
import { logout } from "src/store/authReducer";
import NavbarMenu from "./NavbarMenu";

// styles
// const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
//   ({ theme, open }) => ({
//     ...theme.typography.mainContent,
//     ...(!open && {
//       borderBottomLeftRadius: 0,
//       borderBottomRightRadius: 0,
//       transition: theme.transitions.create("margin", {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.leavingScreen,
//       }),
//       [theme.breakpoints.up("md")]: {
//         marginLeft: -drawerWidth,
//         width: `calc(100% - ${drawerWidth}px)`,
//       },
//       [theme.breakpoints.down("md")]: {
//         marginLeft: "0px",
//         width: `calc(100% - ${drawerWidth}px)`,
//         padding: "16px",
//         paddingTop: "8px",
//         paddingBottom: "8px",
//       },
//       [theme.breakpoints.down("sm")]: {
//         marginLeft: "0px",
//         width: `calc(100% - ${drawerWidth}px)`,
//         padding: "16px",
//         marginRight: "10px",
//         paddingTop: "8px",
//         paddingBottom: "8px",
//       },
//     }),
//     ...(open && {
//       transition: theme.transitions.create("margin", {
//         easing: theme.transitions.easing.easeOut,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//       marginLeft: 0,
//       borderBottomLeftRadius: 0,
//       borderBottomRightRadius: 0,
//       width: `calc(100% - ${drawerWidth}px)`,
//       [theme.breakpoints.down("md")]: {
//         marginLeft: "20px",
//       },
//       [theme.breakpoints.down("sm")]: {
//         marginLeft: "10px",
//       },
//     }),
//   })
// );

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = (props) => {
  const { menuItemId } = props;
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("lg"));

  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.menu.menuOpen);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch(handleOpen(false));
  };

  useEffect(() => {
    // dispatch({ type: SET_MENU, opened: !matchDownMd });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      {/* header */}
      <Stack direction="column">
        <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{
            background: "#003375",
            borderBottom: "8px solid #0065B2",
            transition: leftDrawerOpened
              ? theme.transitions.create("width")
              : "none",
            boxShadow: 3,
          }}
        >
          <Toolbar>
            <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
          </Toolbar>
        </AppBar>
        <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{
            minHeight: "51px",
            top: "64px",
            transition: leftDrawerOpened
              ? theme.transitions.create("width")
              : "none",
            boxShadow: 3,
            justifyContent: "center",
          }}
        >
          <NavbarMenu />
        </AppBar>
      </Stack>

      {/* drawer */}
      {/* <Sidebar
        drawerOpen={leftDrawerOpened}
        drawerToggle={handleLeftDrawerToggle}
      /> */}

      {/* main content */}
      <div
        style={{
          marginTop: "121px",
          width: "100%",
          minHeight: "74vh",
        }}
      >
        {/* <Breadcrumbs separator={IconChevronRight} navigation={menuItems} icon title rightAlign /> */}
        <Outlet />
        <Footer />
      </div>
    </Box>
  );
};

export default MainLayout;
