import { useContext } from 'react';
import { FileContext } from '../fileContext';

const useFileContext = () => {
    const fileContext = useContext(FileContext);
    if (!fileContext) {
        throw new Error('useFileContext must be used within a FileProvider');
    }
    return fileContext;
};

export default useFileContext;
