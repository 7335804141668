import { useSnackbar } from 'notistack';
import { useEffect, useImperativeHandle, useRef, useState, ForwardedRef } from 'react';
import { useFileContext } from 'context/FilesV2';
import { FileGroupHook, FileGroupProps, FileInputHandle, FileGroupHandle } from '../types';
import { IdentifiedFile } from 'context/FilesV2/types';

const useFileGroup = (props: FileGroupProps, ref: ForwardedRef<FileGroupHandle>): FileGroupHook => {
    const { id, bundles, maxBundles, onUpload } = props;
    const { control } = useFileContext();
    const { enqueueSnackbar } = useSnackbar();
    const fgRef = useRef<HTMLDivElement>(null);
    const [toRemove, setToRemove] = useState<string | null>(null);
    const fileInputRefs = useRef<(FileInputHandle | null)[]>([]);

    const addBundle = () => {
        if (maxBundles && bundles && bundles.length >= maxBundles) {
            enqueueSnackbar(`Maximum number of ${maxBundles} bundles reached`, {
                variant: 'error'
            });
            return;
        }
        control.addBundle(id);
        if (bundles && bundles.length > 0) {
            const lastIndex = bundles.length - 1;
            fileInputRefs.current[lastIndex]?.focus();
        }
    };

    const openRemoveDialog = (id: string) => {
        setToRemove(id);
    };

    const closeRemoveDialog = () => {
        setToRemove(null);
    };

    const removeBundle = () => {
        if (toRemove) {
            control.deleteBundle(toRemove);
        }
        setToRemove(null);
    };

    const downloadTemplate = async (dbId: number | string, name: string) => {
        await control.downloadTemplate(dbId, name);
    };

    // todo remove after finishing the refactor
    const upload = async (files: IdentifiedFile[], fileForm: any, bundleId?: string | number) => {
        if (onUpload) {
            const res = await onUpload(files, fileForm, bundleId);
            return res;
        }
    };

    useEffect(() => {
        if (bundles && bundles.length > 0) {
            const lastIndex = bundles.length - 1;
            if (!bundles[lastIndex].saved) fileInputRefs.current[lastIndex]?.focus();
        }
    }, []);

    // todo remove after finishing the refactor
    useImperativeHandle(ref, () => ({
        uploadBundles: () => {
            const uploadPromises = fileInputRefs.current?.map((ref) => ref?.upload()) || [];
            return Promise.all(uploadPromises);
            // fileInputRefs.current?.forEach(async (ref) => ref?.upload());
        },
        validateBundles: () => {
            const validationPromises = fileInputRefs.current?.map((ref) => ref?.validateFileForm()) || [];
            return Promise.all(validationPromises);
        }
    }));

    return {
        addBundle,
        openRemoveDialog,
        closeRemoveDialog,
        removeBundle,
        upload,
        downloadTemplate,
        openDialog: toRemove !== null,
        fileInputRefs,
        fgRef
    };
};

export default useFileGroup;
