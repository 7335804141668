import authReducer from "./authReducer";
import loadingReducer from "./loadingReducer";
import menuReducer from "./menuReducer";
import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import customizationReducer from "./customizationReducer";

// ==============================|| REDUX - MAIN STORE ||============================== //

const rootPersistConfig = {
  key: "rooterp",
  storage,
  blacklist: ["loading"],
};

const reducer = combineReducers({
  auth: authReducer,
  menu: menuReducer,
  loading: loadingReducer,
  customization: customizationReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };
