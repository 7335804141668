import {
  Grid,
  Typography,
  Stack,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import TextInput from "src/qubCommon/Inputs/TextInput";
import { FormProvider } from "react-hook-form";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";

const LoginBox = (props) => {
  const { loginForm, handleLogin } = props;

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleSubmit = async () => {
    setLoading(true);
    await handleLogin();
    setLoading(false);
  };

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ background: "#003375", padding: 22, borderRadius: 20 }}>
        <FormProvider {...loginForm}>
          <Grid item xs={12} mb={2}>
            <h3
              align="center"
              style={{
                color: "#f1f1f1",
                fontWeight: 200,
                marginBottom: 12,
              }}
            >
              Σύνδεση Χρήστη
            </h3>
            <hr style={{ color: "#f1f1f1" }} />
          </Grid>
          <Grid item xs={12} justifyContent="center">
            <form>
              <Stack
                direction="column"
                spacing={1}
                sx={{ marginTop: 2, marginBottom: 2 }}
              >
                <Typography sx={{ color: "#fefefe" }} align="left">
                  Email
                </Typography>
                <TextInput
                  id="email"
                  name="email"
                  placeholder="Email"
                  size="medium"
                  autoComplete="email"
                />
              </Stack>
              <Stack direction="column" spacing={1}>
                <Typography align="left" sx={{ color: "#fefefe" }}>
                  Κωδικός Πρόσβασης
                </Typography>
                <TextInput
                  id="password"
                  name="password"
                  placeholder="Password"
                  autoComplete="current-password"
                  size="medium"
                  type={showPassword ? "text" : "password"}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") handleSubmit();
                  }}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword((show) => !show)}
                          onMouseDown={(e) => e.preventDefault()}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </form>
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              color="secondary"
              variant="contained"
              loading={loading}
              sx={{ width: "100%", marginTop: 6 }}
              onClick={handleSubmit}
            >
              Συνδεση
            </LoadingButton>
          </Grid>
          <Typography
            variant="subtitle1"
            align="left"
            color="secondary"
            onClick={() =>
              window.location.replace("https://www.anynet.gr/MHD/Registration")
            }
            sx={{
              marginTop: 4,
              cursor: "pointer",
              color: "#fefefe",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            Εγγραφή Νέου Χρήστη για την Υποβολή Αίτησης Προγράμματος ΜΗΔ
          </Typography>
        </FormProvider>
      </div>
    </div>
  );
};

export default LoginBox;
