import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from "@mui/material";
import { CssTextField } from "qubCommon/Inputs";

const ConfirmDialog = ({
  open,
  title,
  content,
  onClose,
  onConfirm,
  commentsOn = false,
}) => {
  const [comments, setComments] = useState("");

  const handleCommentsChange = (e) => {
    setComments(e.target.value);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={commentsOn}>
      <DialogTitle>
        <Typography variant="h6">{title}</Typography>
      </DialogTitle>
      <DialogContent size="xl">
        <Typography>{content}</Typography>
        {commentsOn && (
          <CssTextField
            minRows={6}
            multiline
            fullWidth
            value={comments}
            onChange={handleCommentsChange}
            fixedPadding={8}
            sx={{ marginTop: 4 }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error" variant="contained">
          Όχι
        </Button>
        <Button
          onClick={() => onConfirm(comments)}
          color="primary"
          variant="contained"
        >
          Ναι
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
