import * as yup from "yup";
import { FileFormFieldProps } from "../types";
import { isNil } from "lodash";

const fileFormValidation = (fields: FileFormFieldProps[] | undefined) => {
    if (isNil(fields) || (fields && fields.length === 0)) {
        return yup.object().shape({});
    }

    const schema = yup.object().shape(
        fields!.reduce((acc, field) => {
            if (field.isRequired) {
                return {
                    ...acc,
                    [field.name]: yup
                        .string()
                        .required(`${field.label} is required`),
                };
            }
            return acc;
        }, {})
    );

    return schema;
};

export default fileFormValidation;
