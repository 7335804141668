import { memo } from "react";
import { useRoutes } from "react-router-dom";

// routes
import AuthenticationRoutes from "./AuthenticationRoutes";

// store
import { useSelector } from "react-redux";
import MainRoutes from "./MainRoutes";
import { useMenu } from "src/hooks/useMenu";
// ==============================|| ROUTING RENDER ||============================== //

const Routes = memo(() => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const routes = isLoggedIn ? MainRoutes() : AuthenticationRoutes();
  useMenu();

  return useRoutes(routes);
});

export default Routes;
