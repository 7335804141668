/* eslint-disable*/
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Select,
  MenuItem,
  Stack,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import ToolbarExplorer from "src/qubCommon/ExplorerToolbar";

const BringValueToolbar = (params) => {
  const { operators, onSubmit, criteria, originalToolbarProps } = params;
  const { t } = useTranslation();

  const handleFieldChange = (event) => {
    criteria.setSearchField(event.target.value);
  };

  const handleOperatorChange = (event) => {
    criteria.setSearchOperator(event.target.value);
  };

  const handleValueChange = (event) => {
    criteria.setSearchValue(event.target.value);
  };

  const handleSecondaryValueChange = (event) => {
    criteria.setSearchSecondaryValue(event.target.value);
  };

  const handlePageSizeChange = (event) => {
    criteria.setPageSize(event.target.value);
  };

  return (
    <>
      <Grid item>
        {/* <Stack direction="column"> */}
        {/* <Typography variant="subtitle2">{t('Field')}</Typography> */}
        <Select
          id="searchFieldName"
          variant="outlined"
          size="small"
          defaultValue={criteria.searchField}
          onChange={handleFieldChange}
        >
          {criteria.searchFieldOptions?.length > 0 &&
            criteria.searchFieldOptions.map((option) => (
              <MenuItem key={option.fldSearchName} value={option.fldSearchName}>
                {option?.title}
              </MenuItem>
            ))}
        </Select>
      </Grid>
      {/* </Stack> */}
      {/* <Stack direction="column"> */}
      <Grid item>
        {/* <Typography variant="subtitle2">{t('Operation')}</Typography> */}
        <Select
          id="searchOperator"
          variant="outlined"
          size="small"
          defaultValue={operators[0]?.id}
          onChange={handleOperatorChange}
        >
          {operators?.length > 0 &&
            operators.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option?.operator}
              </MenuItem>
            ))}
        </Select>
      </Grid>
      {/* </Stack> */}
      {/* <Stack direction="column"> */}
      {/* <Typography variant="subtitle2">{t('Value')}</Typography> */}
      <Grid item>
        <TextField
          size="small"
          id="searchValue"
          variant="outlined"
          onChange={handleValueChange}
          onKeyDown={async (e) => {
            if (e.key === "Enter") onSubmit();
          }}
          defaultValue={criteria.searchValue ?? ""}
          sx={{ width: 200 }}
        />
        {/* </Stack> */}
      </Grid>
      <Grid item>
        {criteria.secondValueField && (
          <TextField
            size="small"
            id="searchValue2"
            variant="outlined"
            onChange={handleSecondaryValueChange}
            defaultValue={criteria.searchSecondaryValue ?? ""}
            sx={{ width: 200 }}
          />
        )}
      </Grid>
      <Grid item>
        <Button variant="contained" onClick={onSubmit}>
          {t("Search")}
        </Button>
      </Grid>
      <ToolbarExplorer {...originalToolbarProps} />
      <Grid item>
        {criteria.pageSizeOptions?.length > 0 && (
          <Select
            id="pageSize"
            variant="outlined"
            size="small"
            defaultValue={criteria.pageSizeOptions[0]?.value}
            onChange={handlePageSizeChange}
          >
            {criteria.pageSizeOptions.map((option) => (
              <MenuItem key={option.label} value={option.value}>
                {option?.label}
              </MenuItem>
            ))}
          </Select>
        )}
      </Grid>
    </>
  );
};

export default BringValueToolbar;
