import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { CssTextField } from "./CssTextField";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { Chip, Stack, Typography } from "@mui/material";
import RowLabel from "./RowLabel";
import ColumnLabel from "./ColumnLabel";
import { createFilterOptions } from "@mui/material/Autocomplete";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();

const MultiSelect = ({
  onChange,
  options,
  onBlur,
  variant,
  label,
  labelName,
  valueName,
  setValue,
  name,
  extraOnChange,
  defaultValues,
}) => {
  const [selectedOptions, setSelectedOptions] = React.useState(defaultValues);
  const allSelected = options.length === selectedOptions.length;

  React.useEffect(() => {
    setSelectedOptions(defaultValues);
  }, [defaultValues]);

  const handleToggleOption = (selectedOps) => setSelectedOptions(selectedOps);
  const handleClearOptions = () => {
    setValue(name, defaultValues);

    setSelectedOptions(defaultValues);
  };
  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setSelectedOptions(options);
    } else {
      handleClearOptions();
    }
  };

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected);
  };

  const handleChange = async (event, selectedOps, reason, option) => {
    if (reason === "selectOption" || reason === "removeOption") {
      if (selectedOps.find((option) => option.id === -4)) {
        handleToggleSelectAll();
        const result = options.filter((el) => el.id !== -4);
        if (!allSelected) return onChange(result);
        onChange(defaultValues);
      } else {
        if (defaultValues.includes(option?.option)) return;
        handleToggleOption && handleToggleOption(selectedOps);
        onChange(selectedOps);
      }
    } else if (reason === "clear") {
      handleClearOptions && handleClearOptions();
    }
    extraOnChange && extraOnChange();
  };
  return (
    <Autocomplete
      multiple
      limitTags={3}
      id="checkboxes-tags-demo"
      options={options}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return [{ id: -4, display: "Επιλογή όλων" }, ...filtered];
      }}
      onChange={handleChange}
      onBlur={onBlur}
      value={selectedOptions}
      disableCloseOnSelect
      isOptionEqualToValue={(option, newValue) => {
        return option.id === newValue.id;
      }}
      getOptionLabel={(option) => option?.[labelName ?? "label"]}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;
        const selectAllProps = option.id === -4 ? { checked: allSelected } : {};
        return (
          <li key={option?.id} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
              {...selectAllProps}
            />
            {option?.[labelName ?? "label"]}
          </li>
        );
      }}
      renderTags={(value, getTagProps) => {
        const numTags = value.length;
        const limitTags = 2;

        return (
          <>
            {value.slice(0, limitTags).map((option, index) => {
              if (defaultValues?.includes(option))
                return (
                  <Chip
                    {...getTagProps({ index })}
                    key={index}
                    label={option?.[labelName ?? "label"]}
                    onDelete={null}
                  />
                );
              return (
                <Chip
                  {...getTagProps({ index })}
                  key={index}
                  label={option?.[labelName ?? "label"]}
                />
              );
            })}

            {numTags > limitTags && ` +${numTags - limitTags}`}
          </>
        );
      }}
      renderInput={(params) => {
        params.InputProps.disableUnderline = true;
        return (
          <CssTextField
            // variant="outlined"
            label={variant === "standard" ? null : label} // variant = 'outlined
            variant={variant}
            InputProps={{
              disableUnderline: true,
              // endAdornment: (
              //   <Tooltip title="I need help">
              //     <HelpIcon sx={{ color: helpColor }} />
              //   </Tooltip>
              // ),
            }}
            // onFocus={() => setHelpColor("orange")}
            fixedPadding={0}
            fixedPaddingLeft={0}
            size="small"
            // sx={{
            //   "& .MuiFormLabel-root.Mui-disabled": {
            //     color: "red",
            //   },
            // }}
            fullWidth
            {...params}
          />
        );
      }}
    />
  );
};

export default function AutocompleteCheckboxes(props) {
  const { register, formState, setValue, control } = useFormContext();
  const { errors } = formState;
  const { fieldsVariant } = useSelector((state) => state.customization);
  const {
    name,
    rules,
    rowDirection = false,
    label,
    labelWidth,
    variant = "standard",
    defaultValue,
    innerRef,
    inputProps,
    otherInputProps,
    blurFn,
    valueName,
    labelName,
    options = [],
    extraOnChange = () => {},
    defaultValues,
    ...other
  } = props;

  return (
    <Stack direction={rowDirection ? "row" : "column"} spacing={1}>
      {variant === "standard" && (
        <>
          {rowDirection ? ( // variant = 'standard'
            <RowLabel label={label} />
          ) : (
            <ColumnLabel label={label} />
          )}
        </>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          const { onChange, onBlur, value } = field;
          return (
            <MultiSelect
              onChange={onChange}
              options={options}
              onBlur={onBlur}
              variant={variant}
              label={label}
              labelName={labelName}
              valueName={valueName}
              setValue={setValue}
              name={name}
              extraOnChange={extraOnChange}
              defaultValues={defaultValues}
            />
          );
        }}
      />
    </Stack>
  );
}
