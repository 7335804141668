// material-ui
import { styled } from "@mui/material/styles";
import "@grapecity/wijmo.styles/wijmo.css";

// ==============================|| WRAPPER ||============================== //

export const MinimalWrapper = styled("div")(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "light"
      ? theme.palette.primary.light
      : theme.palette.background.default,
  minHeight: "100vh",
  margin: 0,
  width: "100vw",
}));
