import {
  Box,
  Typography,
  Stack,
  Grid,
  Button,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import rest from "assets/rest.pdf";
import { useEffect, useState } from "react";

const PageNotFound = () => {
  const [pdf, setPdf] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, "2000");
  }, []);

  const navigate = useNavigate();
  return (
    <Grid
      justifyContent="center"
      alignItems="center"
      container
      sx={{ height: "100%" }}
    >
      <Grid item xs={6} sm={4}>
        {show && (
          <Stack justifyContent="center" spacing={2} alignItems="center">
            <Typography
              sx={{
                fontSize: "6rem",
                fontWeight: 800,
              }}
            >
              404
            </Typography>
            <Box
              boxShadow={5}
              sx={{
                background: "white",
                width: "inherit",
                p: 2,
                borderRadius: 3,
              }}
            >
              <Typography
                paragraph
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: 800,
                  mb: 0,
                }}
              >
                Η σελίδα δεν βρέθηκε
              </Typography>
            </Box>
            <Typography
              paragraph
              onClick={() => navigate("/")}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
                fontSize: "1rem",
                fontWeight: 800,
                mb: 0,
              }}
            >
              Επιστροφή στην αρχική σελίδα
            </Typography>
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};

export default PageNotFound;
