// Mui Imports
import { Typography, Grid, Button, Skeleton, Stack } from "@mui/material";
import { Add } from "@mui/icons-material";

// React Imports
import { useEffect, useState } from "react";

// Project Imports
import "./Applications.css";
import ApplicationsGrid from "./Components/ApplicationsGrid";
import NewApplicationPanel from "./Components/NewApplicationPanel";
import { getApplications } from "src/apis/controllers/applications";
import { useLoading } from "src/hooks/useLoading";

const Applications = () => {
  const [newApplication, setNewApplication] = useState(false);
  const [applications, setApplications] = useState(false);
  const { setLoading } = useLoading();

  const fetchApplications = async () => {
    setLoading(true);
    try {
      const res = await getApplications();
      setApplications(res?.data);
    } catch {
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchApplications();
  }, []);

  return (
    <div className="applications-container">
      <h2 className="bordered-heading-2">Οι Αιτήσεις μου</h2>
      <Button
        onClick={() => setNewApplication((prev) => !prev)}
        variant="contained"
        startIcon={<Add />}
        sx={{ width: 140 }}
      >
        Νέα Αίτηση
      </Button>
      {applications ? (
        <>
          <NewApplicationPanel visible={newApplication} />
          <ApplicationsGrid applications={applications} />
        </>
      ) : (
        <Stack>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Stack>
      )}
    </div>
  );
};

export default Applications;
