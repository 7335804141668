import React, { useEffect } from "react";
import { Checkbox, FormControlLabel, Typography, Stack } from "@mui/material";
import { useFormContext, useController } from "react-hook-form";

const FormCheckbox = (props) => {
  const {
    name,
    label,
    defaultValue,
    changeFn,
    defaultFromProps = true,
    ...other
  } = props;
  const { getValues, control } = useFormContext();
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue: defaultFromProps ? defaultValue : !!getValues(name),
  });
  const { onChange } = field;

  return (
    <Stack>
      <FormControlLabel
        control={
          /* eslint-disable */
          <Checkbox
            checked={field.value !== undefined ? field.value : false}
            onChange={(e) => {
              onChange(e);
              changeFn && changeFn(e);
            }}
            name={field.name}
            {...other}
          />
        }
        label={
          <Typography variant="subtitle1" sx={{ userSelect: "none" }}>
            {label}
          </Typography>
        }
      />
      {!!fieldState.error && (
        <Typography
          variant="inherit"
          sx={{ color: "#f44336", fontWeight: "bold" }}
        >
          {fieldState.error?.message}
        </Typography>
      )}
    </Stack>
  );
};

export default FormCheckbox;
