import { Can, usePermissions } from "context/permissionContenxt";
import HangfireDashboard from "pages/Admin/Hangfire";
import Impersonation from "pages/Admin/Impersonation";
import { Navigate } from "react-router-dom";
import { LookupProvider } from "src/context/lookupContext";
import MainLayout from "src/layout/MainLayout";
import ApplicationDetails from "src/pages/ApplicationDetails";
import Applications from "src/pages/Applications";
import ApplicationsExplorer from "src/pages/govnr/Applications";
import PageNotFound from "src/pages/PageNotFound";

const HomeNavigation = () => {
  return (
    <>
      <Can I="View" a="MHD_Applications">
        <Navigate to="applications" />
      </Can>
      <Can I="View" a="MHD_GovnrApplications">
        <Navigate to="govnr/applications" />
      </Can>
    </>
  );
};

const PermissionWrapper = (props) => {
  const prm = usePermissions();
  const { action, subject, children } = props;
  if (prm.can(action, subject)) return <>{children}</>;
  return <PageNotFound />;
};

const MainRoutes = () => {
  return [
    {
      path: "",
      element: <MainLayout />,
      children: [
        { path: "home", element: <Navigate to="/" /> },
        {
          path: "applications",
          element: (
            <PermissionWrapper action="View" subject="MHD_Applications">
              <Applications />
            </PermissionWrapper>
          ),
        },
        {
          path: "govnr/applications",
          element: (
            <PermissionWrapper action="View" subject="MHD_GovnrApplications">
              <ApplicationsExplorer />
            </PermissionWrapper>
          ),
        },
        {
          path: "application/:id",
          element: (
            <LookupProvider>
              <ApplicationDetails />
            </LookupProvider>
          ),
        },
        {
          path: "admin/hangfire",
          element: (
            <PermissionWrapper action="View" subject="HangfireDashboard">
              <HangfireDashboard />
            </PermissionWrapper>
          ),
        },
        {
          path: "admin/impersonation",
          element: (
            <PermissionWrapper action="View" subject="MHD_Impersonation">
              <Impersonation />
            </PermissionWrapper>
          ),
        },

        { path: "", element: <HomeNavigation /> },
        {
          path: "*",
          element: <PageNotFound />,
        },
      ],
    },
  ];
};

export default MainRoutes;
