import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  isActive: ["Home.View"], // for active default menu
  menuOpen: false,
  lockMenu: false,
  menuItems: [],
  loading: false,
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const menuSlice = createSlice({
  name: "menu",
  initialState: initialState,
  reducers: {
    handleOpen: (state, action) => {
      return {
        ...state,
        menuOpen: action.payload,
      };
    },
    storeMenu: (state, action) => {
      return {
        ...state,
        menuItems: action.payload,
      };
    },
    menuActive: (state, action) => {
      return {
        ...state,
        isActive: [action.payload],
      };
    },
    resetMenu: () => initialState,
  },
});

export const { storeMenu, resetMenu, menuActive, handleOpen } =
  menuSlice.actions;

export default menuSlice.reducer;
