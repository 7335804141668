import { Grid } from "@mui/material";
import ExplorerGrid, { useExplorerGrid } from "common/ExplorerGrid";
import { Can } from "context/permissionContenxt";
import PageNotFound from "pages/PageNotFound";
import useToolbarProps from "qubCommon/ExplorerToolbar/useToolbarProps";
import { TextInput } from "qubCommon/Inputs";
import ExplorerCard from "wrappers/ExplorerWrapper";

const ApplicationsExplorer = () => {
  const { status, critParams, ...toolbarRest } = useToolbarProps({
    webPageKey: "Applications-Expl",
    hideAddNew: true,
  });

  const { explParams, ...explorerRest } = useExplorerGrid({
    explorerDataKey: "Applications-Expl",
    xlsxName: "Applications",
    navUrl: "/application/{:id}",
    status,
  });

  return (
    <>
      <Can I="View" a="MHD_GovnrApplications">
        <div
          style={{
            margin: 20,
            padding: 20,
            borderRadius: 8,
            backgroundColor: "#f3f3f3",
          }}
        >
          <ExplorerGrid
            explParams={explParams}
            title="Applications"
            breadcrumbs={[{ name: "Applications", path: "govnr/Applications" }]}
            hideAscendingRowNumber
            autoRowHeights
            {...explorerRest}
            {...toolbarRest}
          >
            <Grid item xs={12} md={6} lg={4}>
              <TextInput
                id="Search"
                name="searchFieldValue"
                label={"Αναζήτηση"}
                placeholder={"Αναζήτηση"}
                defaultValue={critParams?.searchFieldValue ?? ""}
              />
            </Grid>
          </ExplorerGrid>
        </div>
      </Can>
      <Can not I="View" a="MHD_GovnrApplications">
        <PageNotFound />
      </Can>
    </>
  );
};
export default ApplicationsExplorer;
