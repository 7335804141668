import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import * as yup from "yup";

// material-ui
import { Button, Grid, Typography } from "@mui/material";

// form
import { FormProvider, useForm } from "react-hook-form";

// project imports
import { TextInput } from "qubCommon/Inputs";

// Apis

import { impersonate } from "apis/controllers/admin";
import { useYupValidationResolver } from "hooks/useYupValidationResolver";
import { useTranslation } from "react-i18next";
import { login } from "store/authReducer";
import { storeMenu } from "store/menuReducer";

const defaultValues = {
  username: "",
};

const Impersonation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const resolver = useYupValidationResolver(
    yup.object({
      username: yup.string().max(255).required("Το πεδίο είναι υποχρεωτικό"),
    })
  );
  const { t } = useTranslation();
  const methods = useForm({
    resolver,
    defaultValues,
    mode: "onSubmit",
    reValidateMode: "onBlur",
  });
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = async (data) => {
    await impersonate({
      username: data.username,
    })
      .then((res) => {
        enqueueSnackbar(t("LoginSuccess"), {
          variant: "success",
        });
        dispatch(storeMenu(res?.menu));
        dispatch(
          login({
            token: res?.token,
            refreshToken: res?.refreshToken,
            user: res?.user,
          })
        );
        navigate("/home");
      })
      .catch((error) => {
        enqueueSnackbar(error || t("SomethingWrong"), {
          variant: "error",
        });
      });
  };

  return (
    <Grid container sx={{ minHeight: "70vh", padding: 4 }}>
      <FormProvider {...methods}>
        <form>
          <Grid
            container
            direction="column"
            justifyContent="center"
            spacing={2}
            sx={{
              borderRadius: 4,
              bgcolor: "#f3f3f3",
              padding: 4,
              minWidth: "600px",
            }}
          >
            <Grid item xs={12}>
              <Typography variant="h4" align="center" mb={4}>
                Impersonation
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="username"
                name="username"
                placeholder={t("Email")}
                size="medium"
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                disabled={methods.formState.isSubmitting}
                fullWidth
                size="large"
                variant="contained"
                type="submit"
                onClick={methods.handleSubmit(onSubmit)}
              >
                {t("Submit")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Grid>
  );
};

export default Impersonation;
