import format from "date-fns/format";
import { store } from "src/store/store";
import * as wjcCore from "@grapecity/wijmo";
import { addClass } from "@grapecity/wijmo";

export const wijmoHideColumns = (flex, columnNames) => {
  flex.columns.forEach((c) => {
    if (columnNames.includes(c.binding)) c.visible = false;
  });
};

export const wijmoAggregateColumns = (flex, columnNames, aggFunction) => {
  flex.columns.forEach((c) => {
    if (columnNames.includes(c.binding)) c.aggregate = aggFunction;
  });
};

export const wijmoMergeColumns = (flex, columnNames) => {
  flex.columns.forEach((c) => {
    if (columnNames.includes(c.binding)) c.allowMerging = true;
  });
};

export const wijmoDateFormat = (
  panel,
  r,
  c,
  cell,
  dateColumn,
  time = false,
  dateFormat = { format: "dd/MM/yyyy" }
) => {
  if (
    panel.columns[c]?.binding === dateColumn &&
    panel.cellType === 1 &&
    cell.innerHTML !== ""
  ) {
    try {
      const date = new Date(cell.innerHTML);
      if (time) cell.innerHTML = format(date, `${dateFormat?.format} HH:mm`);
      else {
        cell.innerHTML = format(date, `${dateFormat?.format}`);
      }
    } catch {
      console.log(
        "wrong value format",
        cell.innerHTML,
        panel.columns[c]?.binding
      );
    }
  }
};

export const wijmoCurrencyFormat = (panel, r, c, cell, currencyColumns) => {
  const currDecimals = 2;
  const currDecimalSep = ",";

  if (
    currencyColumns.includes(panel.columns[c]?.binding) &&
    panel.cellType === 1 &&
    cell.innerHTML !== ""
  ) {
    /* eslint-disable */
    const commasRemoved = cell.innerHTML.replace(/\,/g, "");
    const value = parseFloat(commasRemoved).toFixed(currDecimals);
    const formatValue = parseFloat(value);
    const formatter = new Intl.NumberFormat(
      currDecimalSep === "," ? "el-GR" : "el-GR",
      {
        minimumFractionDigits: currDecimals,
        maximumFractionDigits: currDecimals,
      }
    );
    cell.innerHTML = formatter.format(formatValue);
  }
};

export const wijmoExtendLastColumn = (
  flex,
  groups,
  formatedColumns = false,
  onInit = false,
  rowHeaderChange = ""
) => {
  try {
    const columnWidths = {};
    if (formatedColumns) {
      groups.forEach((column) => {
        columnWidths[column?.binding] = column?.width;
      });
    } else {
      groups.forEach((group) => {
        group.children.forEach((column) => {
          columnWidths[column?.fldName] =
            Number.isNaN(parseInt(column?.colWidth, 10)) ||
            column?.colWidth?.includes("*")
              ? column?.colWidth
              : parseInt(column?.colWidth, 10);
        });
      });
    }

    let maxVisibleIndex = -2;
    let maxRealIndex;
    let colWidthSum = 0;
    if (onInit) colWidthSum = 7;
    if (rowHeaderChange === "enable" && !onInit) {
      colWidthSum = 43;
    }
    if (rowHeaderChange === "disable" && !onInit) {
      colWidthSum = -43;
    }
    flex.columns.forEach((col) => {
      if (col?.visibleIndex > maxVisibleIndex) {
        maxVisibleIndex = col?.visibleIndex;
        maxRealIndex = col?.index;
      }
      if (col?.visible) {
        col.width = columnWidths[col?.binding];
        colWidthSum += col?.width;
      }
    });
    // if (flex.headersVisibility === 3) colWidthSum += flex.rowHeaders.width;

    if (flex.clientSize.width - colWidthSum > 0)
      flex.columns[maxRealIndex].width += flex.clientSize.width - colWidthSum;
  } catch {}
};

export const wijmoStyleGrid = (
  panel,
  r,
  c,
  cell,
  columnStyles,
  cellStyles,
  flex
) => {
  try {
    columnStyles.forEach((style) => {
      if (style?.colCss) {
        const col = flex.getColumn(style?.binding);
        col.cssClass = style?.colCss;
      }
    });
  } catch {
    console.log("column css not applied");
  }
  try {
    // let rowCssBinding;
    // flex.columns.forEach(c=> {
    //     if (c?.binding.toLowerCase() === 'rowcss') rowCssBinding = c?.binding;
    // })
    if (panel?.rows?.[r]?.dataItem?.rowCss) {
      console.log(panel?.rows?.[r]?.dataItem.rowCss);
      panel.rows[r].cssClass = panel.rows[r].dataItem.rowCss;
    }
  } catch {
    console.log("row css not applied");
  }
  try {
    if (cellStyles?.[panel?.columns?.[c]?.binding])
      addClass(
        cell,
        panel?.rows?.[r]?.dataItem?.[cellStyles?.[panel?.columns?.[c]?.binding]]
      );
  } catch {
    console.log("cell css not applied");
  }
};
