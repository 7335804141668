import { getFiles } from "apis/controllers/files";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { getApplication } from "src/apis/controllers/applications";
import { useLookup } from "src/context/lookupContext";
import { useLoading } from "src/hooks/useLoading";

const useApplicationDetails = () => {
  const [data, setData] = useState(null);
  const [files, setFiles] = useState(null);
  const { id } = useParams();
  const { setLoading } = useLoading();
  const [succeeded, setSucceeded] = useState(false);
  const form = useForm();
  const expensesForm = useForm();
  const { setLookups } = useLookup();

  const resetForm = async () => {
    try {
      setLoading(true);
      const res = await getApplication(id);
      const resFiles = await getFiles({
        DataKey: "MHD_Application",
        DataValue: res?.data?.application?.typeId,
        RecordId: null,
        RecordGuid: id,
        ProjectTableId: 4,
      });
      setData(res?.data);
      setFiles(resFiles?.data);
      form.reset(res?.data?.application);
    } catch {}
    setLoading(false);
  };

  const fetchApplication = async () => {
    try {
      setLoading(true);

      setData(null);
      setFiles(null);
      setSucceeded(false);
      const res = await getApplication(id);
      const resFiles = await getFiles({
        DataKey: "MHD_Application",
        DataValue: res?.data?.application?.typeId,
        RecordId: null,
        RecordGuid: id,
        ProjectTableId: 4,
      });
      setFiles(resFiles?.data);
      form.reset(res?.data?.application);
      setData(res?.data);
      setLookups(res?.data?.lookups);
      setSucceeded(res?.succeeded);
    } catch {}
    setLoading(false);
  };

  useEffect(() => {
    fetchApplication();
  }, []);

  return {
    form,
    expensesForm,
    data,
    succeeded,
    files,
    fetchApplication,
    resetForm,
  };
};

export default useApplicationDetails;
