import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
  AppBar,
  Box,
  Grid,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Header from "../MainLayout/Header";
import govLogo from "src/assets/images/gov.png";
import Footer from "../MainLayout/Footer.jsx";

// project imports
// import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
// import Sidebar from './Sidebar';
// import navigation from 'menu-items';
// import { drawerWidth } from 'store/constant';
// import { SET_MENU } from 'store/actions';

// assets

// styles

// ==============================|| MAIN LAYOUT ||============================== //

const MinimalLayout = (props) => {
  // Handle left drawer

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* header */}
      <Stack direction="column">
        <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{
            background: "#003375",
            borderBottom: "8px solid #0065B2",

            boxShadow: 3,
          }}
        >
          <Toolbar>
            <Header />
          </Toolbar>
        </AppBar>
      </Stack>

      {/* drawer */}
      {/* <Sidebar
        drawerOpen={leftDrawerOpened}
        drawerToggle={handleLeftDrawerToggle}
      /> */}

      {/* main content */}
      {/* <Breadcrumbs separator={IconChevronRight} navigation={menuItems} icon title rightAlign /> */}
      <div style={{ marginTop: "84px", width: "100%" }}>
        <Outlet />
        <Footer />
      </div>
    </Box>
  );
};

export default MinimalLayout;
