/* eslint-disable */
import { createContext, useContext, useState } from "react";
import {
  Button,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Box,
} from "@mui/material";
import useBringValue from "src/hooks/bringValue/useBringValue";
import { useSnackbar } from "notistack";
import BringValueToolbar from "./components/BringValueToolbar";
import BringValueGrid from "./components/BringValueGrid";
import BringValueRejectDialog from "./components/BringValueRejectDialog";
import { useTranslation } from "react-i18next";
import { FlexGridXlsxConverter } from "@grapecity/wijmo.grid.xlsx";
import { getData } from "src/apis/controllers/apiEntities";

const BringValueContext = createContext({});

const BringValueProvider = (props) => {
  const { children } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    openDialog,
    setOpenDialog,
    gridInitialized,
    ref,
    gridRef,
    dataKey,
    setDataKey,
    title,
    setTitle,
    columns,
    itemCount,
    setItemCount,
    groupColumns,
    setGroupColumns,
    setColumns,
    showRowHeader,
    showColHeaderGroup,
    setShowRowHeader,
    setShowColHeaderGroup,
    cellCssMapping,
    setCellCssMapping,

    gridData,
    setGridData,
    selectItem,
    onSubmit,
    dataLoading,
    setDataLoading,
    criteria,
    rejectDialog,
  } = useBringValue();

  const openBringValue = async (props) => {
    const {
      dataKey,
      searchValue,
      searchField,
      title,
      onSelect,
      filters = [],
      restData = [],
    } = props;
    ref.current = { onSelect };

    setGridData([]);
    setDataKey(dataKey);
    criteria.setFilters(filters);
    criteria.setRestData(restData);
    criteria.setSearchValue(searchValue);
    criteria.setSearchField(searchField);
    setDataLoading(true);

    const resParams = await getData({
      dataKey: "QUB-BringValue-ParamsGet",
      parameters: { bringValueKey: dataKey },
    });
    const pageSize = resParams?.data?.pageSize?.[0]?.value;
    criteria.setPageSize(pageSize);
    criteria.setPageSizeOptions(resParams?.data?.pageSize);
    const gridColumns = resParams?.data?.gridColumns ?? [];
    const fieldSearch = resParams?.data?.critFldsSearch;
    const cellCssMapping = resParams?.data?.cellCssMapping ?? [];
    setCellCssMapping(cellCssMapping);
    setShowRowHeader(resParams?.data?.showRowHeader);
    setShowColHeaderGroup(resParams?.data?.showColHeaderGroup);

    const helpCols = [];
    setGroupColumns(gridColumns);
    gridColumns.forEach((column) =>
      column.children.forEach((child) =>
        helpCols.push({
          binding: child.fldName,
          header: child.fldTitle,
          visible: child.isVisible,
          format: child.fldFormat,
          width: child.colWidth ?? "*",
          align: child?.colAlign ?? "justify",
        })
      )
    );

    criteria.setSearchFieldOptions(fieldSearch);
    setColumns(helpCols);
    setTitle(resParams?.data?.title ?? title);

    const res = await getData({
      dataKey: "QUB-BringValue-DataGet",
      parameters: {
        bringValueKey: dataKey,
        critFldsSearch: [
          { fldName: searchField, operator: "beginsWith", value1: searchValue },
          ...filters,
        ],
        restData,
        pageSize,
      },
    });

    setItemCount(
      `${res?.ReturnedRecords}/${res?.TotalRecords} ${t("Rows")}` ?? ""
    );
    setDataLoading(false);

    setGridData(res?.data ?? []);
    if (!res?.data) {
      selectItem(false);
      enqueueSnackbar(t("NoDataFound"), { variant: "warning" });
    } else if (res?.data?.length === 1) {
      if (res?.data[0].IsBvDisabled) {
        rejectDialog?.setRejectReason(res?.data[0]?.BvDisabledReason);
        rejectDialog?.setOpenRejectDialog(true);
      } else ref.current.onSelect(res?.data[0]);
    } else setOpenDialog(true);
  };

  const excelHandleClick = (gridRef, webPageKey) => {
    FlexGridXlsxConverter.saveAsync(
      gridRef.current,
      {
        includeColumnHeaders: true,
        includeStyles: false,
      },
      `${webPageKey}_BringValue.xlsx`
    );
  };

  return (
    <BringValueContext.Provider value={{ openBringValue, dataLoading }}>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="xl"
        fullWidth
        sx={{ height: "max-content" }}
      >
        <DialogTitle sx={{ background: "#7EAACA" }}>
          <Typography variant="h4" sx={{ color: "#fefefe" }}>
            {t(title)}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid container item xs={12}>
              <Box
                direction="row"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  gap: 2,
                }}
              >
                <BringValueToolbar
                  operators={criteria.operatorsOptions}
                  onSubmit={onSubmit}
                  criteria={criteria}
                  originalToolbarProps={{
                    gridRef,
                    webPageKey: dataKey,
                    excelHandleClick: () => excelHandleClick(gridRef, dataKey),
                    hideCriteria: true,
                    setToolbarLoading: setDataLoading,
                    toolbarProps: {
                      csvFiles: [],
                      pdfFiles: [],
                      showRowHeader,
                      excelFiles: [
                        { rptId: "default", rptTitle: "Προεπιλογή" },
                      ],
                      columns: groupColumns,
                      status: true,
                      hideAddNew: true,
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <BringValueGrid
                columns={columns}
                groupColumns={groupColumns}
                data={gridData}
                gridInitialized={gridInitialized}
                showRowHeader={showRowHeader}
                showColHeaderGroup={showColHeaderGroup}
                cellCssMapping={cellCssMapping}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Typography variant="subtitle1" mx={2}>
            {itemCount}
          </Typography>
          <Button
            variant="contained"
            sx={{
              borderRadius: 1,
              background: "#709abd",
              ":hover": { background: "#a8d3e4" },
            }}
            onClick={() => selectItem(true)}
          >
            {t("OK")}
          </Button>
          <Button
            variant="contained"
            sx={{
              marginRight: 3,
              borderRadius: 1,
              background: "#b24844",
              ":hover": {
                background: "#c9807d",
              },
            }}
            onClick={() => selectItem(false)}
          >
            {t("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
      <BringValueRejectDialog
        open={rejectDialog?.openRejectDialog}
        setOpen={rejectDialog?.setOpenRejectDialog}
        rejectReason={rejectDialog?.rejectReason}
      />
      {children}
    </BringValueContext.Provider>
  );
};

const useQubBringValue2 = () => {
  const bringValueContext = useContext(BringValueContext);
  if (!bringValueContext) {
    throw new Error("useBringValue must be used within a BringValueProvider");
  }
  return bringValueContext;
};

// when all bvs are migrated this has to change to useQubBringValue :)
export { BringValueProvider, useQubBringValue2 };
