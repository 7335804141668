import { Grid, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "src/store/authReducer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Menu } from "@mui/icons-material";
import { useState } from "react";
import { Can } from "context/permissionContenxt";

const MenuItem = ({
  text,
  color = undefined,
  clickHandler,
  isSelected,
  ...other
}) => (
  <Typography
    className={isSelected ? "menu-text-selected" : "menu-text"}
    sx={{
      padding: "8px",
      fontWeight: "bold",
      lineHeight: "18.75px",
      letterSpacing: "0.05em",
      textAlign: "left",
      color: color ?? "#003375",
      marginRight: "1px",
    }}
    onClick={clickHandler}
    {...other}
  >
    {text}
  </Typography>
);

const NavbarMenu = () => {
  const { menuItems, isActive } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openMenu, setOpenMenu] = useState(false);

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleNavigate = (url) => {
    navigate(url);
  };

  const matches = useMediaQuery("(min-width:860px)");

  return (
    <Grid container>
      <Grid container item xs={12} sx={{ px: matches ? 8 : 2 }}>
        {matches && (
          <>
            {menuItems?.map((item) => (
              <MenuItem
                isSelected={isActive?.[0] === item?.id}
                text={item?.title}
                key={item?.title}
                clickHandler={() => handleNavigate(item?.url)}
              />
            ))}
          </>
        )}
        {!matches && (
          <IconButton onClick={() => setOpenMenu((prev) => !prev)}>
            <Menu />
          </IconButton>
        )}
        <div style={{ marginLeft: "auto", display: "flex" }}>
          <Can I="View" a="HangfireDashboard">
            <MenuItem
              clickHandler={() => handleNavigate("/admin/Hangfire")}
              text="Hangfire"
            />
          </Can>
          <Can I="View" a="MHD_Impersonation">
            <MenuItem
              clickHandler={() => handleNavigate("/admin/impersonation")}
              text="Impersonation"
            />
          </Can>
          <MenuItem clickHandler={handleLogout} text="Έξοδος" color="#B60202" />
        </div>

        {/* <div
              container
              sx={{
                paddingRight: "2px",
                gap: "20px",
                alignItems: "center",
              }}
            >
           
            </Grid> */}
      </Grid>
      {!matches && openMenu && (
        <Grid container item xs={12}>
          {" "}
          {menuItems?.map((item) => (
            <Grid item xs={12}>
              <MenuItem
                isSelected={isActive?.[0] === item?.id}
                text={item?.title}
                key={item?.title}
                clickHandler={() => handleNavigate(item?.url)}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default NavbarMenu;
