/* eslint-disable */
import { FlexGrid, FlexGridColumn } from "@grapecity/wijmo.react.grid";
import * as wjcGrid from "@grapecity/wijmo.grid";
import { useEffect, memo } from "react";
import "./criteria.css";
import * as wjInput from "@grapecity/wijmo.input";
import * as wjcCore from "@grapecity/wijmo";
import { toggleClass } from "@grapecity/wijmo";
import {
  formatToShowDate,
  formatToShowDateTime,
} from "src/qubCommon/helperMethods/dateHelper";

const FieldSearchGrid = memo((props) => {
  const { gridRefs, name, handleDirtyCheck } = props;

  const operators = [
    { id: "contains", operator: "Περιέχει" },
    { id: "beginsWith", operator: "Αρχίζει" },
    { id: "endsWith", operator: "Τελειώνει" },
    { id: "=", operator: "=" },
    { id: ">", operator: ">" },
    { id: ">=", operator: ">=" },
    { id: "<", operator: "<" },
    { id: "<=", operator: "<=" },
    { id: "<>", operator: "<>" },
    { id: "between", operator: "Μεταξύ" },
    { id: "isNull", operator: "NULL" },
    { id: "isEmpty", operator: "ΚΕΝΟ" },
  ];

  var operatorMap = new wjcGrid.DataMap(operators, "id", "operator");

  operatorMap.getDisplayValues = function (rowDataItem) {
    const { dataType } = rowDataItem;
    let availableOptions = operators;
    if (dataType === "date" || dataType === "datetime")
      availableOptions = operators.filter((operator) =>
        ["=", ">", ">=", "<", "<=", "<>", "between", "isNull"].includes(
          operator.id
        )
      );
    if (dataType === "boolean")
      operators.filter((operator) => ["="].includes(operator.id));
    if (dataType === "int" || dataType === "decimal")
      operators.filter((operator) =>
        ["=", ">", ">=", "<", "<=", "<>", "between", "isNull"].includes(
          operator.id
        )
      );

    return availableOptions.map((option) => option.operator);
  };

  const fieldSearchGridInit = (flex) => {
    function isRowEditable(row) {
      var item = row?.dataItem.defaultOperator;
      return item === "between";
    }

    function isEmptyOrNull(row) {
      var item = row?.dataItem.defaultOperator;
      return ["isEmpty", "isNull"].includes(item);
    }

    flex.onSelectionChanged = (e) => {
      var row = flex.rows[e.row];
      if (flex?.columns[e.col]) {
        if (e.col === 2) flex.columns[e.col].isReadOnly = !isRowEditable(row);
      }
      if (flex?.columns[e.col]) {
        if (e.col === 1) flex.columns[e.col].isReadOnly = isEmptyOrNull(row);
      }
    };

    flex.onCellEditEnded = (e) => {
      handleDirtyCheck();
      const grid = e.panel.grid;
      const editingCol = grid.columns[e.col]?.binding;
      const currentItem = grid.rows[e.row].dataItem;
      const value = currentItem?.defaultOperator;
      if (e.col === 0 && currentItem.value2 && value !== 10) {
        flex.setCellData(e.row, e.col + 2, undefined);
      }
      if (
        e.col === 0 &&
        currentItem.value1 &&
        ["isEmpty", "isNull"].includes(value)
      ) {
        flex.setCellData(e.row, e.col + 1, undefined);
      }

      const { dataType, value1, value2, defaultOperator } = currentItem;

      if (dataType === "date") {
        if (editingCol === "value1")
          flex.setCellData(e.row, e.col, formatToShowDate(value1));
        else if (editingCol === "value2")
          flex.setCellData(e.row, e.col, formatToShowDate(value2));
      }
      if (dataType === "datetime") {
        if (editingCol === "value1")
          flex.setCellData(e.row, e.col, formatToShowDateTime(value1));
        else if (editingCol === "value2")
          flex.setCellData(
            e.row,
            e.col,
            formatToShowDateTime(
              value2,
              { format: "dd/MM/yyyy", d: 0, m: 1, y: 2 },
              "dd/MM/yyyy HH:mm",
              null,
              "23:59"
            )
          );
      }
    };

    const extraRow = new wjcGrid.Row();
    extraRow.allowMerging = true;
    var panel = flex.columnHeaders;

    panel.rows.splice(0, 0, extraRow);
    panel.rows.forEach((r) => {
      if (r?.index === 1) r.visible = false;
    });
    for (let colIndex = 0; colIndex <= 2; colIndex++) {
      panel?.setCellData(0, colIndex, "Αναζήτηση");
    }

    // flex.onSelectionChanging.addHandler((s, e) => {
    //   var row = s.rows[e.row];
    // });

    flex.itemFormatter = (panel, r, c, cell) => {
      toggleClass(cell, "cell-custom", true);
      if (panel === flex.rowHeaders && c === 0) {
        cell.textContent = flex.rows[r].dataItem.title;
        flex.rowHeaders.columns[0].width = 140;
      }
      if (c === 2) {
        if (flex.getCellData(r, c - 2) === "between")
          toggleClass(cell, "cell-custom", true);
        else toggleClass(cell, "cell-country", true);
      }
      if (c === 1) {
        if (
          flex.getCellData(r, c - 1) === "isNull" ||
          flex.getCellData(r, c - 1) === "isEmpty"
        )
          toggleClass(cell, "cell-country", true);
        else toggleClass(cell, "cell-custom", true);
      }
    };

    flex.prepareCellForEdit.addHandler((s, e) => {
      if (e.col === 1) {
        const rootEl = document.createElement("div");
        rootEl.style.width = "100%";
        rootEl.style.height = "100%";
        let control;
        const inputType = s.rows[e.row].dataItem["dataType"];
        const lineId = s.rows[e.row].dataItem["fieldId"];
        switch (inputType) {
          case "bool":
            control = new wjInput.ComboBox(rootEl, {
              itemsSource: ["ΝΑΙ", "ΟΧΙ", "‎"],
            });
            break;
          case "text":
            control = document.createElement("TextArea");
            control.value = s.getCellData(e.row, e.col, false);
            rootEl.appendChild(control);
            break;
          default:
            return;
        }
        if (s.activeEditor) {
          const parentCell = s.activeEditor.parentElement;
          parentCell.style.padding = "0";
          parentCell.insertBefore(rootEl, s.activeEditor);
          control.focus();

          if (!wjcCore.isUndefined(control["text"])) {
            control["text"] = s.activeEditor.value;
          }
        }
        const cellEditEndHandler = (s2, args) => {
          s2.cellEditEnding.removeHandler(cellEditEndHandler);
          if (!args.cancel) {
            args.cancel = true;

            switch (inputType) {
              case "number":
              case "string":
              case "date":
                s2.setCellData(args.row, args.col, control.value, false);
                break;
              case "bool":
                s2.setCellData(args.row, args.col, control.selectedItem, false);
                break;
              default:
            }
            if (control.dispose) {
              control.dispose();
            }
          }
        };
        s.cellEditEnding.addHandler(cellEditEndHandler);
      }
    });
    flex.autoSizeColumns(0, flex.columns.length);
    flex.autoSizeColumns(0, flex.columns.length, true);
    flex.autoSizeRows(0, flex.rows.length);
    flex.autoSizeRows(0, flex.rows.length, true);
  };

  const fieldSearchLoadedRows = (flex) => {
    flex.autoSizeColumns(0, flex.columns.length);
    flex.autoSizeColumns(0, flex.columns.length, true);
    flex.autoSizeRows(0, flex.rows.length);
    flex.autoSizeRows(0, flex.rows.length, true);
  };

  return (
    <FlexGrid
      ref={(el) => {
        if (!gridRefs?.current?.find((r) => r.name === name))
          gridRefs.current.push({ grid: el, name });
      }}
      alternatingRowStep={0}
      showMarquee={true}
      initialized={fieldSearchGridInit}
      fieldSearchLoadedRows={fieldSearchLoadedRows}
      itemsSource={props?.data}
      selectionMode={wjcGrid.SelectionMode.Cell}
      allowMerging="ColumnHeaders"
      isReadOnly={false}
    >
      <FlexGridColumn
        header="amounts"
        binding="defaultOperator"
        dataMap={operatorMap}
        cssClass="cell-custom"
      ></FlexGridColumn>
      <FlexGridColumn
        binding="value1"
        minWidth={40}
        width={100}
        isRequired={false}
      />
      <FlexGridColumn
        binding="value2"
        isRequired={false}
        minWidth={40}
        width={100}
        isReadOnly
      />
    </FlexGrid>
  );
});

export default FieldSearchGrid;
