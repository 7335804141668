// action - state management
import { createSlice } from "@reduxjs/toolkit";

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const initialState = {
  isLoggedIn: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    login: (state, action) => {
      return {
        ...state,
        isLoggedIn: true,
        ...action.payload,
      };
    },
    refreshToken: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    logout: (state) => {
      return initialState;
    },
  },
});

export const { login, refreshToken, logout } = authSlice.actions;

export default authSlice.reducer;
