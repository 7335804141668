import { useState } from "react";
import { Box, FormControlLabel, Checkbox, Typography } from "@mui/material";
import { TreeItem } from "@mui/x-tree-view";
import QubCheckbox from "src/qubCommon/Inputs/Checkbox";

const TreeParent = ({
  category,
  methods,
  gridRef,
  extendLastColumn,
  mobileColumnChange = () => {},
}) => {
  const { children } = category;
  const [categoryCheck, setCategoryCheck] = useState(false);

  //   const fetchData = (id) => {
  //     if (data === null) {
  //       children?.forEach((checkbox) => {
  //         checkbox.defaultValue = categoryCheck;
  //       });
  //     }
  //     return dataLines[id];
  //   };

  const handleCheckAll = () => {
    children?.forEach((checkbox) => {
      methods.setValue(`${checkbox.fldName}`, !categoryCheck);
      const c = gridRef.current.getColumn(checkbox.fldName);
      if (c) c.visible = !categoryCheck;
    });
    setCategoryCheck(!categoryCheck);
    mobileColumnChange();
    extendLastColumn();
  };

  const alterHeaders = (name) => {
    const headerChange = methods.getValues(name);
    {
      const c = gridRef.current.getColumn(name);
      if (c) c.visible = !headerChange;
    }
    extendLastColumn();
    mobileColumnChange();
  };

  return (
    <TreeItem
      color="red"
      nodeId={category.grpDescr}
      //   onClick={() => {
      //     if (data === null) setData(() => fetchData(category.id));
      //   }}
      label={
        <Box onClick={(event) => event.stopPropagation()}>
          <FormControlLabel
            label={
              <Typography style={{ fontWeight: "bold" }}>
                {category.grpDescr}
              </Typography>
            }
            control={
              <Checkbox
                onClick={handleCheckAll}
                label={category.name}
                defaultValue={Boolean(category?.isVisible)}
                size="small"
                style={{
                  width: "20px",
                  marginLeft: 5,
                  marginRight: 5,
                  padding: 0,
                }}
              />
            }
          />
        </Box>
      }
    >
      <>
        {category?.children?.map((child) => {
          if (!child?.hideFromPicker)
            return (
              <QubCheckbox
                id={child?.fldName}
                key={child?.fldName}
                name={`${child?.fldName}`}
                onClick={() => alterHeaders(child?.fldName)}
                label={child?.fldTitle}
                defaultValue={Boolean(child?.isVisible) || false}
                size="small"
                style={{
                  width: "20px",
                  marginLeft: 19,
                  marginRight: 5,
                  padding: 0,
                }}
              />
            );
        })}
      </>
    </TreeItem>
  );
};

export default TreeParent;
