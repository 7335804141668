import { PreviewDialogProps, ImageCropRef } from '../types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography } from '@mui/material';
import ImageCrop from './ImageCrop';
import 'react-image-crop/dist/ReactCrop.css';
import { useState, useRef } from 'react';

// todo FIX THIS
const PreviewDialog = (props: PreviewDialogProps) => {
    const { open, onClose, onUpdate, file, aspectRatio, editMode, isReadOnly, setEditMode } = props;
    // const [editMode, setEditMode] = useState(false);
    const imageCropRef = useRef<ImageCropRef>(null);

    const handleClose = () => {
        setEditMode(false);
        onClose();
    };

    const handleUpdate = (updatedFile: File) => {
        onUpdate && onUpdate({ ...file, fileObject: updatedFile });
    };

    const handleSave = () => {
        if (imageCropRef.current?.handleSave()) setEditMode(false);
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={file.fileObject?.type.startsWith('image/') ? 'md' : 'lg'} fullWidth>
            <DialogTitle>
                <Typography variant="h6">{file?.name}</Typography>
            </DialogTitle>
            <DialogContent
                sx={{
                    display: 'flex',
                    height: '75vh',
                    padding: 0
                }}
            >
                {file.fileObject?.type.startsWith('image/') && (
                    <ImageCrop
                        src={file?.fileObject ?? ''}
                        editMode={editMode}
                        onCrop={handleUpdate}
                        ref={imageCropRef}
                        fixedAspectRatio={aspectRatio}
                    />
                )}
                {file.fileObject?.type.startsWith('application/pdf') && (
                    <iframe src={URL.createObjectURL(file.fileObject)} title={file.name} width="100%" height="100%" />
                )}
            </DialogContent>
            <DialogActions>
                {editMode && (
                    <Button
                        variant="contained"
                        onClick={handleSave}
                        sx={{
                            background: '#4d79bc',
                            color: '#fff',
                            ':hover': {
                                background: '#79AEC9',
                                color: '#fff'
                            }
                        }}
                    >
                        Save
                    </Button>
                )}
                {editMode && (
                    <Button
                        variant="contained"
                        onClick={() => setEditMode(!editMode)}
                        sx={{
                            background: '#c42c0e',
                            color: '#fff',
                            ':hover': { background: '#c9807d', color: '#fff' }
                        }}
                    >
                        Cancel
                    </Button>
                )}
                {!editMode && !isReadOnly && file.fileObject?.type.startsWith('image') && (
                    <Button
                        variant="contained"
                        onClick={() => setEditMode(!editMode)}
                        sx={{
                            background: '#4d79bc',
                            color: '#fff',
                            ':hover': {
                                background: '#79AEC9',
                                color: '#fff'
                            }
                        }}
                    >
                        Edit
                    </Button>
                )}
                <Button
                    variant="contained"
                    onClick={handleClose}
                    sx={{
                        background: '#c42c0e',
                        color: '#fff',
                        ':hover': { background: '#c9807d', color: '#fff' }
                    }}
                >
                    Κλείσιμο
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PreviewDialog;
