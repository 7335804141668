import * as yup from "yup";

export const useApartmentValidation = () => {
  return yup.object({
    ageRange: yup.object().shape({
      id: yup.string().required("Παρακαλώ επιλέξτε Ηλικιακή Ομάδα"),
    }),
    gender: yup.object().shape({
      id: yup.string().required("Παρακαλώ επιλέξτε Φύλο"),
    }),
    floor: yup
      .number()
      .nullable()
      .min(0, "Δεν μπορείτε να επιλέξετε αρνητική τιμή."),
    m2: yup
      .number()
      .nullable()
      .min(0, "Δεν μπορείτε να επιλέξετε αρνητική τιμή."),
    capacity: yup
      .string()
      .oneOf(
        ["1", "2", "3", "4"],
        "Οι τιμές του πεδίου πρέπει να ειναι απο 1 έως 4"
      )
      .required("Το πεδίο είναι υποχρεωτικό"),
  });
};
