import FileListItem from './FileListItem';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { SortableContext } from '@dnd-kit/sortable';
import { FileListHandle, FileListProps, FileListItemHandle } from '../types';
import { IdentifiedFile } from 'context/FilesV2/types';
import { createRef, forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogTitle, DialogContent, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { downloadFile } from 'apis/controllers/files';

const FileList = forwardRef<FileListHandle, FileListProps>((props, ref) => {
    const { t } = useTranslation();
    const { files, setFiles, onRemove, onEdit, setPreviewId, aspectRatio, isReadOnly, allExpansion } = props;
    const [listItemRefs, setListItemRefs] = useState<React.RefObject<FileListItemHandle>[]>([]);
    const [toRemove, setToRemove] = useState<IdentifiedFile | null>(null);

    useEffect(() => {
        setListItemRefs((refs) =>
            Array(files.length)
                .fill(null)
                .map((_, i) => refs[i] || createRef<FileListHandle>())
        );
    }, [files]);

    const reorderFiles = (e: DragEndEvent) => {
        if (!e.over) return;
        if (e.active.id !== e.over.id) {
            const oldIndex = files.findIndex((file) => file.id === e.active.id.toString());
            const newIndex = files.findIndex((file) => file.id === e.over!.id.toString());
            setFiles(arrayMove(files, oldIndex, newIndex));
        }
    };

    const handleRemoveFile = (file: IdentifiedFile) => {
        setToRemove(file);
    };

    useImperativeHandle(ref, () => ({
        expandAll() {
            listItemRefs.forEach((ref) => ref.current?.expand());
        },
        collapseAll() {
            listItemRefs.forEach((ref) => ref.current?.collapse());
        }
    }));

    return (
        <>
            <DndContext onDragEnd={reorderFiles}>
                <SortableContext items={files.map((file) => file.id)}>
                    {files.length > 0 &&
                        files.map((file, index) => (
                            <FileListItem
                                key={file.id}
                                file={file}
                                onRemove={() => handleRemoveFile(file)}
                                onPreview={async () => {
                                    try {
                                        const [name,ext] = file.name.split('.')
                                        if (["xlsx","xls"].includes(ext)) {
                                            if (file?.fileObject?.size){
                                            const url = URL.createObjectURL(new Blob([file.fileObject]));
                                            const link = document.createElement('a');
                                            link.href = url;
                                            link.setAttribute('download', `${file.name}`);
                                            document.body.appendChild(link);
                                            link.click();
                                            return;
                                            }
                                            const res = await downloadFile({ Id: file?.dbId }, { responseType: 'blob' })
                                            const url = URL.createObjectURL(new Blob([res]));
                                            const link = document.createElement('a');
                                            link.href = url;
                                            link.setAttribute('download', `${file.name}`);
                                            document.body.appendChild(link);
                                            link.click();
                                            return;
                                        }
                                    }
                                    catch (e) {
                                    console.log(e)
                                 
                                    }
                                    setPreviewId(file.id)
                                }}
                                onEdit={() => onEdit(file.id)}
                                aspectRatio={aspectRatio}
                                isReadOnly={isReadOnly}
                                allExpansion={allExpansion}
                                ref={listItemRefs[index]}
                            />
                        ))}
                </SortableContext>
            </DndContext>
            <Dialog open={toRemove !== null} onClose={() => setToRemove(null)}>
                <DialogTitle>
                    <Typography>{t('Warning')}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography>{t('AreYouSureRemoveFile')}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="text"
                        onClick={() => setToRemove(null)}
                        sx={{
                            color: '#4d79bc',
                            ':hover': {
                                background: '#79AEC9',
                                color: '#fff'
                            }
                        }}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            if (toRemove) {
                                onRemove(toRemove.id);
                            }
                            setToRemove(null);
                        }}
                        sx={{
                            background: '#c42c0e',
                            color: '#fff',
                            ':hover': { background: '#c9807d', color: '#fff' }
                        }}
                    >
                        {t('Remove')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});

export default FileList;
