import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import { Provider } from "react-redux";
import { persistor, store } from "src/store/store";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import "@grapecity/wijmo.styles/wijmo.css";
import "src/assets/scss/style.scss";
import "@grapecity/wijmo.cultures/wijmo.culture.el";
import { I18nextProvider } from "react-i18next";
import i18n from "i18n/index.js";
import { PermissionProvider } from "context/permissionContenxt.jsx";

ReactDOM.createRoot(document.getElementById("root")).render(
  <I18nextProvider i18n={i18n}>
    <SnackbarProvider>
      <Provider store={store}>
        <PermissionProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </PermissionProvider>
      </Provider>
    </SnackbarProvider>
  </I18nextProvider>
);
