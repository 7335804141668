import SimpleAccordion from "qubCommon/Accordions/SimpleAccordion";
import "./LegalFiles.css";

const url = import.meta.env.VITE_REACT_APP_BASE_URL;

const LegalFiles = () => {
  return (
    <SimpleAccordion
      title={
        <span style={{ fontWeight: "bold", color: "#003476" }}>
          ΝΟΜΟΘΕΤΙΚΟ ΠΛΑΙΣΙΟ & ΕΓΓΡΑΦΑ
        </span>
      }
      content={
        <article className="card-body">
          <p>
            Στoν παρακάτω πίνακα παρουσιάζεται το νομοθετικό πλαίσιο και συναφή
            έγγραφα που αφορούν στο Πρόγραμμα ΜΗΔ:
          </p>

          <div className="table-responsive">
            <table className="table bordered-table">
              <thead>
                <tr>
                  <th scope="col">A/A</th>
                  <th style={{ width: "26%" }} scope="col">
                    Τύπος Εγγράφου
                  </th>
                  <th scope="col">Περιγραφή</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">1</td>
                  <td>
                    <a
                      target="blank"
                      className=" ds-link small-font-size "
                      href={`${url}/legislation/ΦΕΚ 2024-ΤΕΥΧΟΣ B-04763.pdf`}
                      download="Ν 3765-2009 ΔΙΑΚΡΑΤΙΚΕΣ ΥΙΟΘΕΣΙΕΣ"
                    >
                      ΚΥΑ 1316 / 20.08.2024 / ΦΕΚ Β’ 4763
                    </a>
                  </td>
                  <td>
                    Κοινή Υπουργική Απόφαση «Ημιαυτόνομη διαβίωση σε
                    διαμερίσματα ατόμων δεκαπέντε ετών και άνω που διαβιούν σε
                    Μονάδες Παιδικής Προστασίας και Φροντίδας», όπως
                    τροποποιήθηκε και ισχύει
                  </td>
                </tr>
                <tr>
                  <td className="text-center">2</td>
                  <td>
                    <a
                      target="blank"
                      className="ds-link small-font-size ds-link small-font-size"
                      href={`${url}/legislation/Ν 4538-ΑΝΑΔΟΧΗ-ΥΙΟΘΕΣΙΑ.pdf`}
                      download="Ν 4538-ΑΝΑΔΟΧΗ-ΥΙΟΘΕΣΙΑ"
                    >
                      Ν.4538/2018
                    </a>
                  </td>
                  <td>
                    Μέτρα για την προώθηση των Θεσμών της Αναδοχής και Υιοθεσίας
                    και άλλες διατάξεις (Α΄ 85). Όπως τροποποιήθηκε και ισχύει
                    με το άρθρο 117 του Ν. 4604/2019 (Α΄ 50).
                  </td>
                </tr>
                <tr>
                  <td className="text-center">3</td>
                  <td>
                    <a
                      target="blank"
                      className=" ds-link small-font-size"
                      href={`${url}/legislation/ΠΡΟΣΚΛΗΣΗ ΥΠΟΒΟΛΗΣ ΑΙΤΗΣΕΩΝ ΜΟΝΑΔΩΝ ΗΜΙΑΥΤΟΝΟΜΗΣ ΔΙΑΒΙΩΣΗΣ.pdf`}
                      download="Ν 4478-2017-ΔΙΑΚΡΑΤΙΚΗ ΑΝΑΔΟΧΗ ΚΑΙ ΦΙΛΟΞΕΝΙΑ ΑΝΗΛΙΚΩΝ"
                    >
                      Πρόσκληση / 26.08.2024
                    </a>
                  </td>
                  <td>
                    ΠΡΟΣΚΛΗΣΗ ΥΠΟΒΟΛΗΣ ΑΙΤΗΣΕΩΝ ΧΡΗΜΑΤΟΔΟΤΗΣΗΣ για το Πρόγραμμα
                    «Ημιαυτόνομης διαβίωσης σε διαμερίσματα ατόμων δεκαπέντε
                    ετών και άνω που διαβιούν σε Μονάδες Παιδικής Προστασίας και
                    Φροντίδας»
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </article>
      }
    ></SimpleAccordion>
  );
};

export default LegalFiles;
