import { Add, AttachFile, Delete } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { getFiles } from "apis/controllers/files";
import { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useLookup } from "src/context/lookupContext";
import DynamicDialog from "src/qubCommon/Dialogs/DynamicDialog";
import Dropdown from "src/qubCommon/Inputs/Dropdown";
import TextInput from "src/qubCommon/Inputs/TextInput";
import { getByAmkaAfm } from "src/util/getAmkaRegistryInfo";
import { v4 as uuidv4 } from "uuid";
import AlertBanner from "../AlertBanner";
import { enqueueSnackbar } from "notistack";

const ListFileItem = ({
  file,
  handlePreview,
  id,
  bundleId,
  handleDeleteAndRemoveFile,
}) => {
  return (
    <Box
      sx={{
        border: "1px solid #4d79bc",

        borderRadius: 2,
        px: 1,
        mb: 1,
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{ width: "100%" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center">
          <AttachFile
            fontSize="small"
            sx={{
              color: "#387de7",
            }}
          />
          <Typography
            onClick={() => {
              if (file.isNew) handlePreview(file, true);
              else handlePreview(file);
            }}
            sx={{
              cursor: "pointer",
              color: "#387de7",
              ":hover": {
                fontWeight: "bold",
              },
            }}
          >
            <u>{file.name ?? file?.origFName}</u>
          </Typography>
        </Stack>

        <IconButton>
          <Delete
            onClick={() => {
              handleDeleteAndRemoveFile(file, id, bundleId);
            }}
            sx={{ color: "#d12639" }}
          />
        </IconButton>
      </Stack>
    </Box>
  );
};

export default function EmployeeFormDialog(props) {
  const {
    open,
    handleClose,
    employeeForm,
    handleAddRow,
    handleEditRow,
    handlePreviewFile,
    handleDeleteFile = () => {},
  } = props;
  const { lookups } = useLookup();
  const [files, setFiles] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialog, setDialog] = useState({ title: "Σφάλμα", message: "Σφάλμα" });

  const editId = employeeForm?.getValues("id");
  const bundleId = employeeForm?.getValues("bundleId");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const findLookupObject = (lookupTable, value, fieldName) => {
    const selectedObject = lookupTable?.find((record) => record?.id === value);
    employeeForm.setValue(fieldName, selectedObject.name);
  };

  const amkaAfmOnBlur = async (e) => {
    const amka = employeeForm.getValues("amka");
    const afm = employeeForm.getValues("afm");
    const { status, data, message } = await getByAmkaAfm(amka, afm);
    if (status === "success") {
      await employeeForm.setValue("lastName", data?.lastName);
      await employeeForm.setValue("firstName", data?.firstName);
      await employeeForm.setValue(
        "fullName",
        `${data?.lastName} ${data?.firstName}`
      );
    }
    if (status === "error") {
      setDialog({
        title: "Σφαλμα",
        message,
        onAccept: () => setDialogOpen(false),
      });
      setDialogOpen(true);
      employeeForm.setValue("lastName", null);
      employeeForm.setValue("firstName", null);
      employeeForm.setValue("fullName", null);
    }
  };

  const amkaAfmOnSubmit = async (e) => {
    const amka = employeeForm.getValues("amka");
    const afm = employeeForm.getValues("afm");
    const { status, data, message } = await getByAmkaAfm(amka, afm);
    if (status === "success") {
      await employeeForm.setValue("lastName", data?.lastName);
      await employeeForm.setValue("firstName", data?.firstName);
      await employeeForm.setValue(
        "fullName",
        `${data?.lastName} ${data?.firstName}`
      );
    }
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;

    for (let i = 0; i < selectedFiles.length; i++) {
      const selectedFile = selectedFiles[i];
      selectedFile.isNew = true;
      selectedFile.rowGuid = uuidv4();
      const validTypes = ["image/jpeg", "image/png", "application/pdf"];
      if (!validTypes.includes(selectedFile.type)) {
        alert("Λάθος τύπος αρχείου. Παρακαλώ επιλέξτε εικόνα ή pdf.");
        return;
      }
      if (selectedFile?.size > 2097152) {
        enqueueSnackbar(`Το αρχείο ${selectedFile?.name} υπερβαίνει τα 2MB.`, {
          variant: "error",
        });
        selectedFile.oversize = true;
      }
    }
    const fileArray = Array.from(selectedFiles).map((file) => file);
    const filteredFileArray = fileArray?.filter((file) => !file?.oversize);
    setFiles((prev) => [...prev, ...filteredFileArray]);
  };

  const fetchFiles = async () => {
    const res = await getFiles({
      DataKey: "MHD_Employee",
      DataValue: null,
      RecordId: null,
      RecordGuid: editId,
      ProjectTableId: 6,
    });
    const pages = res?.data?.[0]?.files?.[0]?.pages || [];
    const pageIds = pages.map((page) => page.id); // Extracting ids from pages
    let newFiles;
    try {
      newFiles = employeeForm.getValues("files").filter((ap) => ap.isNew);
      newFiles = newFiles.filter((file) => !pageIds.includes(file.id));
    } catch {
      newFiles = [];
    }
    setFiles([...pages, ...newFiles]);
    employeeForm.setValue("fileType", res?.data?.[0]?.type);
    employeeForm.setValue(
      "bundleId",
      res?.data?.[0]?.files?.[0]?.bundleId ??
        employeeForm.getValues("bundles")?.[0]
    );
  };

  const handleDeleteAndRemoveFile = async (file, id, bundleId) => {
    if (
      window.confirm(
        `Είστε σίγουροι οτι θέλετε να διαγράψετε το αρχείο ${
          file?.name ?? file?.origFName
        }`
      )
    ) {
      if (file?.id) {
        const res = await handleDeleteFile(file, id, bundleId);
        if (res === "success")
          setFiles((prev) => prev.filter((obj) => file?.id !== obj.id));
      } else {
        setFiles((prev) => prev.filter((obj) => file?.rowGuid !== obj.rowGuid));
      }
    }
  };

  useEffect(() => {
    if (open) fetchFiles();
  }, [open]);

  return (
    <FormProvider {...employeeForm}>
      <DynamicDialog
        open={dialogOpen}
        meta={{
          title: dialog?.title,
          description: dialog?.message,
          action: "OK",
        }}
        onAccept={dialog.onAccept}
        hideSecondaryButton
        type="error"
      />
      <Dialog
        onClose={() => {
          setFiles([]);
          handleClose();
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {editId ? "Τροποποίηση" : "Προσθήκη"} Προσώπου στην Ομάδα Έργου
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Divider />
        <DialogContent
          sx={{
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <AlertBanner
            info={{
              message: ` <div>Η ${
                editId ? "Τροποποίηση" : "Προσθήκη"
              } θα πραγματοποιηθεί μετά την αποθήκευση της αίτησης.</div> `,
              cssClass: "info",
            }}
          />
          <Grid container spacing={2}>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12} md={6}>
                <Dropdown
                  name="jobType.id"
                  options={lookups?.jobTypes}
                  label="Ιδιότητα"
                  labelName="name"
                  valueName="id"
                  changeFn={(e) => {
                    findLookupObject(
                      lookups?.jobTypes,
                      e.target.value,
                      "jobType.name"
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                name="amka"
                label="AMKA"
                onBlur={amkaAfmOnBlur}
                defaultFromProps={false}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                name="afm"
                label="ΑΦΜ"
                onBlur={amkaAfmOnBlur}
                defaultFromProps={false}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                name="lastName"
                label="Επώνυμο"
                disabled
                defaultFromProps={false}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                name="firstName"
                label="Όνομα"
                disabled
                defaultFromProps={false}
              />
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}>
                <TextInput
                  name="comments"
                  label="Σχόλια"
                  minRows={3}
                  defaultFromProps={false}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography mb={2} sx={{ fontStyle: "italic" }}>
                Βιογραφικό Σημείωμα:
              </Typography>
              <Stack
                direction="row"
                gap={2}
                mb={2}
                sx={{ display: "flex", alignItems: "flex-end" }}
              >
                <Button
                  variant="contained"
                  component="label"
                  startIcon={<Add />}
                >
                  Προσθήκη Αρχείων
                  <input
                    type="file"
                    multiple
                    hidden
                    onChange={handleFileChange}
                    accept="image/jpeg,image/png,application/pdf"
                  />
                </Button>
              </Stack>
              {files?.length > 0 &&
                files.map((file, index) => (
                  <ListFileItem
                    key={index}
                    file={file}
                    handlePreview={handlePreviewFile}
                    id={editId}
                    bundleId={bundleId}
                    handleDeleteAndRemoveFile={handleDeleteAndRemoveFile}
                  />
                ))}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ width: "90%", mx: "auto" }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ color: "#fefefe", marginRight: "auto" }}
            autoFocus
            onClick={handleClose}
          >
            Πίσω
          </Button>
          <Button
            sx={{}}
            onClick={employeeForm.handleSubmit(async () => {
              await amkaAfmOnSubmit();

              if (!editId) {
                employeeForm.setValue("id", uuidv4());
                employeeForm.setValue("isNew", true);
              }
              editId
                ? handleEditRow(employeeForm.getValues(), files)
                : handleAddRow(employeeForm.getValues(), files);
            })}
            variant="contained"
            autoFocus
          >
            {editId ? "Τροποποίηση" : "Προσθήκη"}
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}
