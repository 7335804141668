import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

export const CssTextField = styled(TextField, {
  shouldForwardProp: (prop) =>
    prop !== "fixedPadding" &&
    prop !== "fixedPaddingLeft" &&
    prop !== "hasBackground" &&
    prop !== "disabledStyling" &&
    prop !== "focusedStyling" &&
    prop !== "hoverStyling",
})(
  ({
    fixedPadding,
    fixedPaddingLeft,
    hasBackground,
    disabledStyling,
    focusedStyling,
    hoverStyling,
  }) => ({
    root: {},
    "& .MuiInput-input": {
      padding: 0,
    },
    "& .MuiInput-root": {
      backgroundColor: hasBackground ? "#ffffff" : "#EDF2F8",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
      borderRadius: "10px",
      padding: fixedPadding ?? 4,
      paddingLeft: fixedPaddingLeft ?? 12,
      border: hasBackground ? "2px solid #FFFFFF" : "2px solid #EDF2F8",
      "&:hover": {
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        border: "2px solid #FFD35E",
        ...hoverStyling,
      },
      // "&:hover fieldset": {
      //   borderColor: "#FFD35E",
      // },
      "&.Mui-focused ": {
        background: "#FFECBA",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        border: "2px solid #FFD35E",
        ...focusedStyling,
      },
      "&.Mui-disabled ": {
        background: "#EAEAEA",
        border: "2px solid #D9D9D9",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        ...disabledStyling,
      },
      "&.Mui-error ": {
        background: "#FFF2F2",
        border: "2px solid #D10029",
      },
      "&.Mui-focused fieldset ": {
        borderColor: "#FFD35E",
      },
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: hasBackground ? "#ffffff" : "#EDF2F8",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
      padding: fixedPadding ?? 0,
      "& > fieldset": {
        borderColor: "#EDF2F8",
      },
      "&:hover": {
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        ...hoverStyling,
        "& > fieldset": {
          border: "2px solid #FFD35E",
        },
      },

      "&.Mui-focused": {
        background: "#FFECBA",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        ...focusedStyling,
        "& > fieldset": {
          border: "2px solid #FFD35E",
        },
      },
      "&.Mui-error ": {
        background: "#FFF2F2",
        "& > fieldset": {
          border: "2px solid #D10029",
        },
      },
      "&.Mui-disabled ": {
        background: "#EAEAEA",
        "& > fieldset": {
          border: "2px solid #D9D9D9",
          ...disabledStyling,
        },
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
      },
    },
  })
);
