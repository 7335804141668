import { FileFormFieldProps } from '../../types';
import { TextInput, Dropdown, DateInput, DateTimeInput, Checkbox } from 'qubCommon/Inputs';

const FileFormField = (props: FileFormFieldProps) => {
    const { type, options = [], isReadOnly, ...other } = props;

    switch (type) {
        case 'string':
            return <TextInput disabled={isReadOnly}  {...other} />;
        case 'dropdown':
            return <Dropdown disabled={isReadOnly} options={options} {...other} />;
        case 'date':
            return <DateInput blurFn={undefined} tooltip={undefined} defaultFromProps={false} disabled={isReadOnly} {...other} />;
        case 'datetime':
            return <DateTimeInput blurFn={undefined} tooltip={undefined} defaultFromProps={false} disabled={isReadOnly} {...other} />;
        case 'boolean':
            return <Checkbox defaultFromProps={false} disabled={isReadOnly} {...other} />;
        default:
            return null;
    }
};

export default FileFormField;
