import "./App.css";
import "./index.css";
import { ThemeProvider } from "@mui/material/styles";
import Routes from "./routes";
import theme from "./themes";
import { LoaderProvider } from "./context/loaderContext";
import "@grapecity/wijmo.styles/wijmo.css";
import "font-awesome/css/font-awesome.min.css";
import { BringValueProvider } from "./qubCommon/context/BringValueContext/bringValueContext";
import { ModalProvider } from "./qubCommon/context/modalContext";
import { setLicenseKey } from "@grapecity/wijmo";

function App() {
  const wijmoKey = import.meta.env.VITE_WIJMO_KEY;

  setLicenseKey(wijmoKey);

  return (
    <ModalProvider>
      <LoaderProvider>
        <ThemeProvider theme={theme()}>
          <BringValueProvider>
            <Routes />
          </BringValueProvider>
        </ThemeProvider>
      </LoaderProvider>
    </ModalProvider>
  );
}

export default App;
