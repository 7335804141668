import { Alert, Collapse, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { createMarkup } from "src/util/createMarkup";
import CloseIcon from "@mui/icons-material/Close";

const AlertBanner = ({ info, openInfo = true, setOpenMessage = () => {} }) => {
  const [open, setOpen] = useState(openInfo);

  useEffect(() => {
    if (openInfo) setOpen(true);
    else setOpen(false);
  }, [openInfo]);

  return (
    <Collapse in={open}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
              if (setOpenMessage) setOpenMessage("");
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        variant="filled"
        className={info?.cssClass}
        severity={info?.cssClass}
      >
        <div dangerouslySetInnerHTML={createMarkup(info?.message)} />
      </Alert>
    </Collapse>
  );
};
export default AlertBanner;

AlertBanner.propTypes = {
  info: PropTypes.shape({
    name: PropTypes.string,
    message: PropTypes.string,
    cssClass: PropTypes.string,
  }),
};
