import React from "react";
import { Typography, TextField, MenuItem, Stack } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import RowLabel from "./RowLabel";
import ColumnLabel from "./ColumnLabel";
import { CssTextField } from "./CssTextField";
import { useSelector } from "react-redux";

const BaseComponent = (props) => {
  const { formState } = useFormContext();
  const { fieldsVariant } = useSelector((state) => state.customization);
  const {
    name,
    field,
    options,
    isReadOnly,
    defaultValue,
    label,
    valueName,
    labelName,
    disabled,
    menuAnchorRef,
    rowDirection = false,
    optionAll = false,
    inputProps,
    fixedPadding = 8,
    fixedPaddingLeft,
    changeFn = () => {},
    variant = fieldsVariant ?? "standard",
    ...other
  } = props;
  const { onChange, value } = field;
  const { errors } = formState;

  return (
    <Stack direction={rowDirection ? "row" : "column"} spacing={1}>
      {variant === "standard" && (
        <>
          {rowDirection ? ( // variant = 'standard'
            <RowLabel label={label} />
          ) : (
            <ColumnLabel label={label} />
          )}
        </>
      )}
      <CssTextField
        error={!!errors[name]}
        size="small"
        helperText={errors[name]?.message}
        select
        InputProps={{
          disableUnderline: true,
        }}
        SelectProps={{
          ...inputProps,
          id: "dropdown",
          onChange: (e) => {
            onChange(e);
            changeFn(e);
          },
          value: value || "",
          MenuProps: {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          },
          defaultValue,
          displayEmpty: true,
        }}
        fullWidth
        label={variant === "standard" ? null : label}
        variant={variant}
        disabled={isReadOnly || disabled}
        fixedPadding={fixedPadding ?? 6}
        fixedPaddingLeft={fixedPaddingLeft ?? 12}
      >
        {optionAll && (
          <MenuItem key="all" value="">
            <em>Όλα</em>
          </MenuItem>
        )}
        {Array.isArray(options) ? (
          options.map((option) => (
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
              }}
              key={valueName ? option[valueName] : option.Value}
              value={valueName ? option[valueName] : option.Value}
            >
              {labelName ? option[labelName] : option?.Label}
            </MenuItem>
          ))
        ) : (
          <div>No options available...</div>
        )}
      </CssTextField>
    </Stack>
  );
};

const Dropdown = (props) => {
  const { control } = useFormContext();
  const { name, rules, ...other } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={other.defaultValue}
      rules={rules}
      {...other}
      render={(props) => <BaseComponent name={name} {...props} {...other} />}
    />
  );
};

export default Dropdown;
