import { Backdrop, CircularProgress, Dialog } from "@mui/material";
import { createContext, useState } from "react";

export const LoaderContext = createContext(null);

export const LoaderProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  return (
    <LoaderContext.Provider value={{ loading, setLoading }}>
      <Backdrop open={loading} sx={{ zIndex: 99999 }}>
        <div style={{ padding: "30px" }}>
          <CircularProgress size={70} />
        </div>
      </Backdrop>
      {children}
    </LoaderContext.Provider>
  );
};
