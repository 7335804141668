// React Imports
import { useState, forwardRef, createRef, useEffect } from "react";

// MUI Imports
import { InputAdornment, IconButton, Stack } from "@mui/material";
import { DateRangeSharp } from "@mui/icons-material";

// Other Libraries
import format from "date-fns/format";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Project Imports
import {
  formatToShowDate,
  inDateRange,
} from "src/qubCommon/helperMethods/dateHelper";
import { CssTextField } from "./CssTextField";
import { parse } from "date-fns";
import RowLabel from "./RowLabel";
import ColumnLabel from "./ColumnLabel";
import { useFormContext, useController } from "react-hook-form";
import "./DateInputs.css";

const DateInput = ({
  defaultValue = "",
  dateInUse = false,
  locked = false,
  label = "",
  labelWidth = 120,
  variant = "standard",
  cmpyrsValidation = false,
  rowDirection = false,
  name,
  blurFn,
  isFullWidth = false,
  defaultFromProps = true,
  isRequired,
  ...other
}) => {
  const dateFormat = {
    format: "dd/MM/yyyy",
    d: 0,
    m: 1,
    y: 2,
  };

  const dateFrom = "2022-01-01";
  const dateTo = "2022-12-31";
  const { control, setValue, getValues } = useFormContext();
  const { field, fieldState } = useController({ name, control, defaultValue });
  const [input, setInput] = useState(
    defaultValue ? format(new Date(defaultValue), dateFormat?.format) : ""
  );
  const [datePickerValue, setDatePickerValue] = useState(
    defaultValue
      ? format(new Date(defaultValue), dateFormat?.format)
      : format(new Date(), dateFormat?.format)
  );
  const dateRef = createRef();

  const CustomDatePicker = forwardRef(({ value, onClick }, ref) => (
    <IconButton onClick={onClick} ref={ref}>
      <DateRangeSharp />
    </IconButton>
  ));

  useEffect(() => {
    if (defaultValue && defaultFromProps) {
      setValue(name, defaultValue, { shouldDirty: true });
      setInput(format(new Date(defaultValue), dateFormat?.format));
    } else if (!defaultFromProps && getValues(name)) {
      setInput(format(new Date(getValues(name)), dateFormat?.format));
    }
  }, [defaultValue]);

  const handleDatePickerChange = (e) => {
    let eValid;
    if (dateInUse)
      eValid = inDateRange(
        e.getFullYear(),
        e.getMonth(),
        e.getDate(),
        dateFrom,
        dateTo,
        dateFormat?.format
      );
    else
      eValid = format(
        new Date(e.getFullYear(), e.getMonth(), e.getDate()),
        dateFormat?.format
      );
    const eFormatted = parse(eValid, dateFormat?.format, new Date());
    setInput(format(eFormatted, dateFormat?.format));
    setValue(name, format(eFormatted, "yyyy-MM-dd"), {
      shouldDirty: true,
    });
    setDatePickerValue(format(eFormatted, dateFormat?.format));
  };

  return (
    <Stack direction={rowDirection ? "row" : "column"}>
      {variant === "standard" && (
        <>
          {rowDirection ? ( // variant = 'standard'
            <RowLabel labelWidth={labelWidth} label={label} />
          ) : (
            <ColumnLabel label={label} />
          )}
        </>
      )}
      <CssTextField
        fullWidth={isFullWidth}
        disabled={locked}
        size="small"
        variant={variant}
        onBlur={() => {
          if (input) {
            const datePicked = formatToShowDate(
              input,
              dateFormat,
              defaultValue,
              dateInUse,
              dateFrom,
              dateTo
            );
            setInput(datePicked ?? "");
            setValue(
              name,
              format(
                parse(datePicked, dateFormat.format, new Date()),
                "yyyy-MM-dd"
              ),
              { shouldDirty: true }
            );
            setDatePickerValue(
              datePicked ?? format(new Date(), dateFormat?.format)
            );
          }
          if (input === "") setValue(name, "");

          if (blurFn) blurFn();
        }}
        value={input}
        error={!!fieldState.error}
        helperText={fieldState.error?.message}
        onChange={(e) => setInput(e?.target?.value)}
        InputProps={
          locked
            ? { disableUnderline: true }
            : {
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <DatePicker
                      selected={parse(
                        datePickerValue,
                        dateFormat?.format,
                        new Date()
                      )}
                      onChange={(e) => handleDatePickerChange(e)}
                      customInput={<CustomDatePicker ref={dateRef} />}
                      showYearDropdown
                    />
                  </InputAdornment>
                ),
              }
        }
        {...other}
      />
    </Stack>
  );
};

export default DateInput;
