import { updateApplication } from "apis/controllers/applications";
import { FileProvider, useFileContext } from "context/FilesV2";
import { useLoading } from "hooks/useLoading";
import { enqueueSnackbar } from "notistack";
import ConfirmDialog from "qubCommon/Dialogs/ConfirmationDialog";
import { formatFloat } from "qubCommon/wijmoHelpers/numberMethods";
import { useEffect, useRef, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteBundle,
  deleteFile,
  downloadFile,
  formatFiles,
  uploadFiles,
} from "util";
import "./ApplicationDetails.css";
import AlertBanner from "./Components/AlertBanner";
import Apartments from "./Components/Apartments";
import ApplicationActions from "./Components/ApplicationActions";
import Attachments from "./Components/Attachments";
import Budget from "./Components/Budget";
import Employees from "./Components/Employees";
import History from "./Components/History";
import Institute from "./Components/Institute";
import LegalRepresentative from "./Components/LegalRepresentative";
import ScientificDirector from "./Components/ScientificDirector";
import SummaryCard from "./Components/SummaryCard";
import useApplicationDetails from "./useApplicationDetails";

const ApplicationDetails = () => {
  const {
    form,
    data,
    succeeded,
    expensesForm,
    files,
    fetchApplication,
    resetForm,
  } = useApplicationDetails();

  const { id } = useParams();

  if (succeeded)
    return (
      <div className="applications-container">
        <FileProvider
          downloadFile={(fileId, type) => downloadFile(fileId, type)}
          uploadFiles={(fileGroups) => uploadFiles(fileGroups, null)}
          onDeleteBundle={async (bundleId) =>
            await deleteBundle(bundleId, 4, null, id, null)
          }
          onDeleteFile={async (fileId) => {
            await deleteFile(fileId, 4, null, id, null);
          }}
        >
          <Form
            form={form}
            expensesForm={expensesForm}
            data={data}
            files={files}
            fetchApplication={fetchApplication}
            resetForm={resetForm}
          />
        </FileProvider>
      </div>
    );
};

const Form = (props) => {
  const { form, expensesForm, data, files, fetchApplication, resetForm } =
    props;
  const [errorBanner, setErrorBanner] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogProps, setDialogProps] = useState({
    title: "",
    content: "",
    onConfirm: () => {},
  });
  const { id } = useParams();
  const { fileGroups, control } = useFileContext();
  const fgRefs = useRef([]);
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const formattedFiles = formatFiles(files);
      if (formattedFiles !== undefined) {
        control.initialize(formattedFiles);
      } else {
        control.initialize([]);
      }
    } catch (error) {
      console.error("error", error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const handleSave = async () => {
    try {
      setLoading(true);

      const applicationData = form.getValues();
      // const validationPromises = [];
      // fgRefs?.current?.forEach((fg) => {
      //   const bundleValidationPromise = fg?.validateBundles();
      //   if (bundleValidationPromise)
      //     validationPromises.push(bundleValidationPromise);
      // });

      // const validationResults = await Promise.all(validationPromises);
      // if (validationResults.some((result) => result.some((r) => r === false))) {
      //   enqueueSnackbar("Missing required field on a file", {
      //     variant: "error",
      //   });
      //   setLoading(false);
      //   return;
      // }

      // ToDo SOS Uncomment this
      await control.uploadFileGroups();

      // const formData = formatForSave(
      //     data,
      //     id,
      //     "ApplicationTypeId",
      //     fileGroups
      // );

      const values = expensesForm.getValues("budget");
      const formattedValues = [];

      // Iterate through the expenses and their children
      data?.expenses.forEach((expense) => {
        if (expense.header.hasDataEntry) {
          const value = values[`${expense.header.id}`];
          if (value) {
            formattedValues.push({
              id: expense.header.id,
              amount: formatFloat(value),
            });
          }
        }

        // ToDo delete this log

        expense.children.forEach((child) => {
          if (child.hasDataEntry) {
            const value = values[`${child.id}`];
            if (value) {
              formattedValues.push({
                id: child.id,
                amount: formatFloat(value),
              });
            }
          }
        });
      });

      const bundleIds = fileGroups.reduce((acc, group) => {
        return [
          ...acc,
          ...group.bundles
            .filter((bundle) => bundle?.files?.length > 0) // Filter bundles where files length is greater than 0
            .map((bundle) => bundle.id),
        ];
      }, []);

      const uploadData = {
        ...applicationData,
        budget: formattedValues,
        bundles: bundleIds,
        action: "save",
      };
      const res = await updateApplication(uploadData);

      if (res?.status === "success") await fetchApplication();
      if (res?.status === "error") setErrorBanner(res?.message);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const handleApproveSave = async () => {
    try {
      setLoading(true);

      // ToDo SOS Uncomment this
      await control.uploadFileGroups();
      await fetchApplication();

      setLoading(false);
    } catch {
      setLoading(false);
      setErrorBanner("<div>Προέκυψε σφάλμα!</div>");
    }
  };

  const handleOpenDialog = (actionType) => {
    switch (actionType) {
      case "approve":
        setDialogProps({
          title: "Έγκριση Αίτησης",
          content: "Θέλετε σίγουρα να εγκρίνετε την αίτηση;",
          onConfirm: handleApprove,
        });
        break;
      case "reject":
        setDialogProps({
          title: "Απόρριψη Αίτησης",
          content: ` Θέλετε σίγουρα να επιστρέψετε την αίτηση στον αιτούντα; 
            Παρακαλώ αναφέρετε τους λόγους απόρριψης.`,
          onConfirm: handleReject,
          commentsOn: true,
        });
        break;
      case "submit":
        setDialogProps({
          title: "Υποβολή Αίτησης",
          content: "Θέλτε σίγουρα να υποβάλλετε την αίτηση;",
          onConfirm: handleSubmit,
        });
        break;
      case "cancel":
        setDialogProps({
          title: "Ακύρωση Αίτησης",
          content: "Θέλετε σίγουρα να ακυρώσετε την αίτηση;",
          onConfirm: handleCancel,
        });
        break;
      case "backToHome":
        const dirtyFields = Object.keys(form.formState.dirtyFields).map(
          (x) => ({
            field: x,
            value: form.getValues(x),
          })
        );
        if (!!dirtyFields?.length || control.isDirty)
          setDialogProps({
            title: "Προσοχή!",
            content:
              "Έχετε πραγματοποιήσει αλλαγές στη φόρμα σας. Αν αποχωρήσετε οι αλλαγές θα χαθούν. Θέλετε να κλείσετε τη σελίδα;",
            onConfirm: () => navigate("/home"),
          });
        else navigate("/home");
        break;
      default:
        return;
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleApprove = async () => {
    try {
      setLoading(true);
      await control.uploadFileGroups();

      const uploadData = {
        id: form.getValues("id"),
        action: "approve",
      };
      const res = await updateApplication(uploadData);
      if (res?.status === "success") await fetchApplication();
      if (res?.status === "error") setErrorBanner(res?.message);
      setLoading(false);
      setOpenDialog(false);
    } catch {
      setLoading(false);
      setErrorBanner("<div>Προέκυψε Σφάλμα!</div>");
    }
  };

  const handleReject = async (comments) => {
    const uploadData = {
      id: form.getValues("id"),
      action: "reject",
      comments,
    };
    const res = await updateApplication(uploadData);
    if (res?.status === "success") await fetchApplication();
    if (res?.status === "error") setErrorBanner(res?.message);
    setOpenDialog(false);
  };

  const handleSubmit = async () => {
    setLoading(true);

    const applicationData = form.getValues();
    const validationPromises = [];
    // fgRefs?.current?.forEach((fg) => {
    //   const bundleValidationPromise = fg?.validateBundles();
    //   if (bundleValidationPromise)
    //     validationPromises.push(bundleValidationPromise);
    // });

    // const validationResults = await Promise.all(validationPromises);
    // if (validationResults.some((result) => result.some((r) => r === false))) {
    //   enqueueSnackbar("Missing required field on a file", {
    //     variant: "error",
    //   });
    //   return;
    // }
    // ToDo SOS Uncomment this
    await control.uploadFileGroups();

    // const formData = formatForSave(
    //     data,
    //     id,
    //     "ApplicationTypeId",
    //     fileGroups
    // );

    const values = expensesForm.getValues("budget");
    const formattedValues = [];

    // Iterate through the expenses and their children
    data?.expenses.forEach((expense) => {
      if (expense.header.hasDataEntry) {
        const value = values[`${expense.header.id}`];
        if (value) {
          formattedValues.push({
            id: expense.header.id,
            amount: formatFloat(value),
          });
        }
      }

      // ToDo delete this log

      expense.children.forEach((child) => {
        if (child.hasDataEntry) {
          const value = values[`${child.id}`];
          if (value) {
            formattedValues.push({
              id: child.id,
              amount: formatFloat(value),
            });
          }
        }
      });
    });

    const bundleIds = fileGroups.reduce((acc, group) => {
      return [...acc, ...group.bundles.map((bundle) => bundle.id)];
    }, []);
    const uploadData = {
      ...applicationData,
      budget: formattedValues,
      bundles: bundleIds,
      action: "submit",
    };
    const res = await updateApplication(uploadData);
    if (res?.status === "success") await fetchApplication();
    if (res?.status === "error") {
      resetForm();

      setErrorBanner(res?.message);
    }
    setLoading(false);
    setOpenDialog(false);
  };

  const handleCancel = async () => {
    setLoading(true);
    const uploadData = {
      id: form.getValues("id"),
      action: "cancel",
    };
    const res = await updateApplication(uploadData);
    if (res?.status === "success") await fetchApplication();
    if (res?.status === "error") {
      enqueueSnackbar("Προέκυψε σφάλμα!", {
        variant: "error",
      });
    }
    setLoading(false);
    setOpenDialog(false);
  };

  return (
    <FormProvider {...form}>
      <ConfirmDialog
        open={openDialog}
        title={dialogProps.title}
        content={dialogProps.content}
        onClose={handleCloseDialog}
        onConfirm={dialogProps.onConfirm}
        commentsOn={dialogProps.commentsOn}
      />
      <AlertBanner info={data?.application?.status} />
      <SummaryCard
        protocolNumber={data?.application?.protocolNumber}
        protocolNumber2={data?.application?.protocolNumber2}
        status={data?.application?.status}
        created={data?.application?.created}
        updated={data?.application?.updated}
        submitted={data?.application?.submitted}
      />
      <Institute institute={data?.application?.hq} type="hq" />
      {data?.application?.additionalInstitutes?.map((institute, index) => {
        return (
          <Institute
            key={institute?.name + index}
            institute={institute}
            index={index}
            type="additional"
          />
        );
      })}
      <Institute institute={data?.application?.branch} type="branch" />
      <LegalRepresentative rep={data?.application?.legalRepresentative} />
      <ScientificDirector
        dir={data?.application?.scientificDirector}
        jobTypes={data?.lookups?.jobTypes}
        canEdit={data?.application?.canEdit}
      />
      <Apartments
        apartments={data?.application?.appartments}
        branch={data?.application?.branch}
        canEdit={data?.application?.canEdit}
      />
      <Employees
        employees={data?.application?.employees}
        canEdit={data?.application?.canEdit}
      />
      <Budget
        expenses={data?.expenses}
        budget={data?.application?.budget}
        expensesForm={expensesForm}
        canEdit={data?.application?.canEdit}
      />

      <Attachments
        fileGroups={fileGroups}
        canEdit={data?.application?.canEdit}
        canApprove={data?.application?.approveReject?.canApproveAttachments}
      />
      <ApplicationActions
        handleCancel={() => handleOpenDialog("cancel")}
        canEdit={data?.application?.canEdit}
        canCancel={data?.application?.canCancel}
        canSubmit={data?.application?.canSubmit}
        approveReject={data?.application?.approveReject}
        handleSubmit={() => handleOpenDialog("submit")}
        handleApprove={() => handleOpenDialog("approve")}
        handleSave={() => handleSave()}
        handleApproveSave={() => handleApproveSave()}
        handleReject={() => handleOpenDialog("reject")}
        handleBackToHome={() => handleOpenDialog("backToHome")}
      />
      <AlertBanner
        setOpenMessage={setErrorBanner}
        openInfo={errorBanner}
        info={{
          message: `<div>${errorBanner}</div>`,
          cssClass: "error",
        }}
      />
      <History history={data?.application?.history} />
    </FormProvider>
  );
};

export default ApplicationDetails;
