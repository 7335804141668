import { Grid, Stack, Typography } from "@mui/material";
import SimpleAccordion from "src/qubCommon/Accordions/SimpleAccordion";
import { Groups } from "@mui/icons-material";
import EmployeesTable from "./EmployeesTable";
import "./Employees.css";
import CustomSimpleAccordion from "qubCommon/Accordions/CustomSimpleAccordion";

const Employees = ({ employees, canEdit }) => {
  const AccordionTitle = () => (
    <Stack direction="row" gap={1}>
      <Groups color="primary" fontSize="small" sx={{ marginLeft: "2px" }} />
      <Typography color="primary" sx={{ fontWeight: 1000 }}>
        ΣΥΝΘΕΣΗ ΟΜΑΔΑΣ ΕΡΓΟΥ
      </Typography>
    </Stack>
  );

  return (
    <CustomSimpleAccordion
      title={<AccordionTitle />}
      content={
        <Grid container>
          <EmployeesTable employees={employees} canEdit={canEdit} />
        </Grid>
      }
    />
  );
};

export default Employees;
