import { Grid, Typography } from "@mui/material";

const SummaryCard = (props) => {
  const {
    protocolNumber,
    protocolNumber2,
    status,
    created,
    updated,
    submitted,
  } = props;
  return (
    <Grid sx={{ padding: 4 }} container className="card">
      <Grid align="center" item xs={12}>
        <strong>ΑΙΤΗΣΗ ΧΡΗΜΑΤΟΔΟΤΗΣΗΣ</strong>
      </Grid>
      <Grid sx={{ padding: 2 }} align="center" item xs={12} mb={1}>
        <Typography variant="h5">
          Πρόγραμμα «Ημιαυτόνομης διαβίωσης σε διαμερίσματα ατόμων δεκαπέντε
          ετών και άνω που διαβιούν σε Μονάδες Παιδικής Προστασίας και
          Φροντίδας»
        </Typography>
      </Grid>
      <Grid container item xs={12} md={6}>
        <Grid item xs={6} mb={1}>
          <Typography fontWeight="bold">Κατ. Αίτησης:</Typography>
        </Grid>
        <Grid item xs={6} mb={1}>
          <Typography>{status?.name} </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={6}>
        <Grid item xs={6} mb={1}>
          <Typography fontWeight="bold">Ημ. Δημιουργίας</Typography>
        </Grid>
        <Grid item xs={6} mb={1}>
          <Typography>{created?.dateFormatted}</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={6}>
        <Grid item xs={6} mb={1}>
          <Typography fontWeight="bold">A/A Υποβολής:</Typography>
        </Grid>
        <Grid item xs={6} mb={1}>
          <Typography>
            {protocolNumber ?? "(δίνεται μετά την Υποβολή)"}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={6}>
        <Grid item xs={6}>
          <Typography fontWeight="bold">Ημ. Υποβολής:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{submitted?.dateFormatted ?? ""}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SummaryCard;
