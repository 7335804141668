import { Grid, Stack, Typography } from "@mui/material";
import SimpleAccordion from "src/qubCommon/Accordions/SimpleAccordion";
import TextInput from "src/qubCommon/Inputs/TextInput";
import { AccountCircleOutlined, Attachment } from "@mui/icons-material";
import Dropdown from "src/qubCommon/Inputs/Dropdown";
import { getAmkaRegistryInfo } from "src/apis/controllers/applications";
import { getByAmkaAfm } from "src/util/getAmkaRegistryInfo";
import { useFormContext } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import DynamicDialog from "src/qubCommon/Dialogs/DynamicDialog";
import { useFileContext } from "context/FilesV2";
import { formatFiles } from "util";
import { FileGroup } from "common/FileUpload";
import CustomSimpleAccordion from "qubCommon/Accordions/CustomSimpleAccordion";

const Attachments = ({ canEdit, canApprove }) => {
  const fgRefs = useRef([]);
  const { fileGroups: fileGroupsV2, control: filesControl } = useFileContext();

  const AccordionTitle = () => (
    <Stack direction="row" gap={1}>
      <Attachment color="primary" fontSize="small" sx={{ marginLeft: "2px" }} />
      <Typography color="primary" sx={{ fontWeight: 1000 }}>
        ΔΙΚΑΙΟΛΟΓΗΤΙΚΑ
      </Typography>
    </Stack>
  );

  const AccordionContent = useMemo(() => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {fileGroupsV2?.map((group, index) => {
            return (
              <FileGroup
                reviewMode={canApprove}
                isReadOnly={!canEdit}
                key={group.id}
                id={group.id}
                ref={(el) => (fgRefs.current[index] = el)}
                title={group?.title}
                maxSize={2097152}
                maxFiles={5}
                maxBundles={group?.maxBundles}
                fields={group.fields}
                bundles={group?.bundles}
                templates={group?.templates}
                accept={
                  group?.id === 17
                    ? {
                        "application/vnd.ms-excel": [".xls"],
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                          [".xlsx"],
                      }
                    : null
                }
              />
            );
          })}
        </Grid>
      </Grid>
    );
  }, [fileGroupsV2]); // Only recompute when fileGroupsV2 changes

  return (
    <>
      {/* Optionally, you can uncomment and use SimpleAccordion */}
      <CustomSimpleAccordion
        title={<AccordionTitle />}
        content={AccordionContent}
      />
    </>
  );
};

export default Attachments;
