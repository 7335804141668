import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const loadingSlice = createSlice({
  name: "loading",
  initialState: initialState,
  reducers: {
    setLoading: (state, action) => {
      return {
        loading: action.payload,
      };
    },
  },
});

export const { setLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
