import { Link } from "react-router-dom";

// material-ui
import { ButtonBase } from "@mui/material";
import logo from "src/assets/images/anynetLogo.png";

// project imports
// material-ui
// import logo from 'assets/images/logo.png';
// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  return (
    <ButtonBase disableRipple component={Link} to="/home">
      <img src={logo} alt="logo" width="59px" />
    </ButtonBase>
  );
};

export default LogoSection;
