/* QubCommon Methods */
import { CollectionView } from "@grapecity/wijmo";
import { CellRange } from "@grapecity/wijmo.grid";
import { FlexGridXlsxConverter } from "@grapecity/wijmo.grid.xlsx";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { getData } from "src/apis/controllers/apiEntities";
import { useLoading } from "src/hooks/useLoading";
import {
  ModalButtons,
  ModalImages,
  useModal,
} from "src/qubCommon/context/modalContext";
import {
  formatCritFldSearch,
  formatValuesListSearch,
} from "src/qubCommon/helperMethods/formatRequest";

const useExplorerGrid = (props) => {
  const {
    status,
    explorerDataKey,
    explorerDataKeyParams,
    xlsxName = "Report",
    navUrl = "",
    onSelect,
    onPopup,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const form = useForm();
  const { reset, handleSubmit, formState } = form;
  const { isDirty } = formState;
  const [searchParams] = useSearchParams();
  const { openModal } = useModal();
  const [gridData, setGridData] = useState(null);
  const [explParams, setExplParams] = useState(null);
  const [valueList, setValueList] = useState([]);
  const [valueListChips, setValueListChips] = useState([]);
  const [fieldSearchChips, setFieldSearchChips] = useState([]);
  const gridRef = useRef();
  const mobileGridRef = useRef();
  const toolbarRef = useRef();
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const [records, setRecords] = useState({ returned: null, total: null });

  const [contextMenuAnchor, setContextMenuAnchor] = useState(null);

  const [contextMenuData, setContextMenuData] = useState(null);
  const [contextMenuCellData, setContextMenuCellData] = useState(null);
  const [contextMenuAllData, setContextMenuAllData] = useState(null);

  /* useEffect(() => {
        console.log('useExplorerGrid toolbar', toolbarRef.current.criteriaRef?.current?.critValuesList);
        setValueList(toolbarRef.current.criteriaRef?.current?.critValuesList);
    }, [toolbarRef?.current?.criteriaRef?.current?.critFldsSearch, toolbarRef?.current?.criteriaRef?.current?.critValuesList]);

    useEffect(() => {
        const helpChips = [];
        if (valueList && valueList.length > 0) {
            valueList.forEach((valueList) =>
                valueList?.children.forEach((c) => {
                    if (c.isSelected) helpChips.push(c);
                })
            );
        }
        setValueListChips(helpChips.map((chip) => chip?.title));
    }, [valueList]);

    useEffect(() => {
        console.log('useExplorerGrid valueListChips', valueListChips);
    }, [valueListChips]); */

  /* Get the quick access filters */
  useEffect(() => {
    const fetch = async () => {
      if (explorerDataKeyParams) {
        const res = await getData({ dataKey: explorerDataKeyParams });
        setExplParams(res?.data);
      }
    };
    fetch();
  }, [explorerDataKeyParams]);

  /* Clear grid when criteria change */
  useEffect(() => {
    if (isDirty) setGridData({ items: [] });
  }, [isDirty]);

  /* Initialize the grid */
  const gridInitialized = (flex) => {
    gridRef.current = flex;
    flex.selectionMode = "None";

    flex.itemFormatter = (panel, r, c, cell) => {
      if (panel.cellType === 3) {
        cell.innerHTML = `<div class='row-header'> ${
          panel.rows[r]?.index + 1
        }</div>`;
      }
    };

    flex.formatItem.addHandler((s, e) => {
      if (e.panel.cellType === 3) {
        e.cell.innerHTML = `<div class='row-header'> ${
          e.panel.rows[e.row]?.index + 1
        }</div>`;
      }

      // if (e.panel === flex.topLeftCells) {
      //     e.cell.outerHTML =
      //         '<div class="wj-cell wj-header wj-align-center top-left" tabindex="-1" style="left: 0px; top: 0px; width: 44px; height: 35px;"></div>';
      // }
    });

    flex.resizedColumn.addHandler((s, e) => {
      const col = e.panel.columns[e.col];
      // console.log(col.width);
      getData({
        dataKey: "Qub-ColsWidth-Save",
        parameters: {
          fldName: col.binding,
          fldWidth: col.width,
          explorerDataKey,
        },
      });
    });

    /* Enable selection on single click */
    flex.hostElement.addEventListener("mousedown", (e) => {
      if (flex.selectionMode === 0) {
        const ht = flex.hitTest(e);
        flex.selectionMode = "ListBox";
        flex.select(ht.row, 0);
        flex.select(ht.row, 0);
      }
    });

    /* navigatte on double click */
    flex?.hostElement?.addEventListener("dblclick", (e) => {
      console.log(e);
      const ht = flex.hitTest(e.pageX, e.pageY);

      const currentItem = flex.rows[ht.row]?.dataItem;
      if (currentItem && ht.cellType === 1) {
        if (onSelect && typeof onSelect === "function")
          onSelect(currentItem, e.ctrlKey);
        else if (e.ctrlKey) {
          const newWindow = window.open(
            navUrl.replace("{:id}", currentItem?.Id),
            "_blank"
          );
          newWindow.focus();
        } else navigate(navUrl.replace("{:id}", currentItem?.Id));
      }
    });

    /* navigatte on Enter */
    flex.hostElement.addEventListener(
      "keydown",
      (e) => {
        if (e.key === "Enter") {
          if (onSelect && typeof onSelect === "function")
            onSelect(flex?.selectedItems[0], e.ctrlKey);
          else if (e.ctrlKey) {
            const newWindow = window.open(
              navUrl.replace("{:id}", flex?.selectedItems[0].Id),
              "_blank"
            );
            newWindow.focus();
          } else if (flex?.selectedItems[0]?.Id)
            navigate(navUrl.replace("{:id}", flex?.selectedItems[0].Id));
        }
      },
      true
    );

    flex.hostElement.addEventListener("contextmenu", (e) => {
      e.preventDefault();
      e.stopPropagation();
      const ht = flex.hitTest(e);
      if (flex.selectionMode === 0) flex.selectionMode = "ListBox";
      flex.select(ht.row, ht.col);
      const currentItem = flex?.collectionView?.currentItem;
      const visibleCurrentItem = {};
      if (currentItem && ht.cellType === 1) {
        flex.columns.forEach((c) => {
          const property = c?.binding;
          if (c?.visible)
            visibleCurrentItem[`${property}`] = currentItem[`${property}`];
        });
        setContextMenuData(visibleCurrentItem);
        setContextMenuCellData(flex.cells.getCellData(ht.row, ht.col));
        setContextMenuAllData(
          flex.getClipString(
            new CellRange(0, 0, flex.rows.length - 1, flex.columns.length - 1),
            false,
            true
          )
        );
        setContextMenuAnchor(flex.cells.getCellElement(ht.row, ht.col));
        if (onPopup && typeof onPopup === "function") onPopup(currentItem);
      }
    });

    //
    // make rows taller on phone layout
    //
    // hide column headers on narrow layouts
  };

  /* Handle fetch data */
  const fetchData = async (data) => {
    const res = await getData({ dataKey: explorerDataKey, parameters: data });
    return res;
  };

  /* Handle chips */
  const handleChips = () => {
    const helpValueListChips = [];
    const helpFieldSearchChips = [];
    if (
      toolbarRef.current?.criteriaRef?.current?.critValuesList &&
      toolbarRef.current?.criteriaRef?.current?.critValuesList.length > 0
    ) {
      toolbarRef.current?.criteriaRef?.current?.critValuesList.forEach(
        (valueList) => {
          if (valueList?.children) {
            valueList?.children.forEach((c) => {
              if (c.isSelected) helpValueListChips.push(c);
            });
          }
        }
      );
    }
    setValueListChips(helpValueListChips);
    if (
      toolbarRef.current?.criteriaRef?.current?.critFldsSearch &&
      toolbarRef.current?.criteriaRef?.current?.critFldsSearch.length > 0
    ) {
      toolbarRef.current?.criteriaRef?.current?.critFldsSearch.forEach(
        (fieldSearch) => {
          if (fieldSearch.value1) helpFieldSearchChips.push(fieldSearch);
        }
      );
    }
    setFieldSearchChips(helpFieldSearchChips);
  };

  /* Handle search */
  const onSubmit = async (data) => {
    setLoading(true);
    reset(data);
    setRecords({ returned: null, total: null });
    data.critFldsSearch = formatCritFldSearch(
      toolbarRef.current?.criteriaRef?.current?.critFldsSearch
    );
    data.critValuesList = formatValuesListSearch(
      toolbarRef.current?.criteriaRef?.current?.critValuesList
    );
    data = { ...data, critId: searchParams.get("critId") };
    handleChips();
    const res = await fetchData(data);
    if (!res?.data)
      openModal({
        title: t("Explorer"),
        message: t("NoDataFound"),
        imageType: ModalImages.Info,
        buttons: ModalButtons.OK,
        callback: (command) => {},
      });

    const helpData = new CollectionView(res?.data);
    setRecords({
      returned: res?.ReturnedRecords ?? null,
      total: res?.TotalRecords ?? null,
    });
    setGridData(helpData);
    setLoading(false);
  };

  useEffect(() => {
    if (status) handleSubmit(onSubmit)();
  }, [status]);

  /* Handle excel report */
  const excelHandleClick = () => {
    FlexGridXlsxConverter.saveAsync(
      gridRef.current,
      {
        includeColumnHeaders: true,
        includeStyles: false,
      },
      `${xlsxName}.xlsx`
    );
  };

  const handleCloseContextMenu = () => {
    setContextMenuAnchor(null);
  };

  const mobileGridLoadedRows = (flex) => {
    flex.hostElement.addEventListener("mousedown", (e) => {
      if (flex.selectionMode === 0) {
        const ht = flex.hitTest(e);
        flex.selectionMode = "ListBox";
        flex.select(ht.row, 0);
        flex.select(ht.row, 0);
      }
    });
    flex.hostElement.addEventListener("contextmenu", (e) => {
      e.preventDefault();
      e.stopPropagation();
      const ht = flex.hitTest(e);
      if (flex.selectionMode === 0) flex.selectionMode = "ListBox";
      flex.select(ht.row, ht.col);
      const currentItem = flex?.collectionView?.currentItem?.allData;
      const visibleCurrentItem = {};
      if (currentItem && ht.cellType === 1) {
        gridRef.current.columns.forEach((c) => {
          const property = c?.binding;
          if (c?.visible)
            visibleCurrentItem[`${property}`] = currentItem[`${property}`];
        });
        setContextMenuData(visibleCurrentItem);
        // setContextMenuCellData(flex.cells.getCellData(ht.row, ht.col));
        // setContextMenuAllData(flex.getClipString(new CellRange(0, 0, flex.rows.length - 1, flex.columns.length - 1), false, true));
        setContextMenuAnchor(flex.cells.getCellElement(ht.row, ht.col));
        if (onPopup && typeof onPopup === "function") onPopup(currentItem);
      }
    });
    let lastTapTime = 0;

    flex.hostElement.addEventListener("touchstart", function (event) {
      const currentTime = new Date().getTime();
      const timeSinceLastTap = currentTime - lastTapTime;

      if (timeSinceLastTap < 300) {
        // Adjust the time threshold as needed
        // Double-tap detected
        const ht = flex.hitTest(event);

        const currentItem = flex.rows[ht.row]?.dataItem?.allData;
        if (currentItem && ht.cellType === 1) {
          if (onSelect && typeof onSelect === "function") onSelect(currentItem);
          else navigate(navUrl.replace("{:id}", currentItem?.Id));
        }
        event.preventDefault(); // Prevent default behavior (e.g., zooming)
      }

      lastTapTime = currentTime;
    });

    flex?.hostElement?.addEventListener("dblclick", (e) => {
      const ht = flex.hitTest(e.pageX, e.pageY);

      const currentItem = flex.rows[ht.row]?.dataItem?.allData;
      if (currentItem && ht.cellType === 1) {
        if (onSelect && typeof onSelect === "function")
          onSelect(currentItem, e.ctrlKey);
        else if (e.ctrlKey) {
          const newWindow = window.open(
            navUrl.replace("{:id}", currentItem?.Id),
            "_blank"
          );
          newWindow.focus();
        } else navigate(navUrl.replace("{:id}", currentItem?.Id));
      }
    });
  };

  return {
    gridInitialized,
    mobileGridLoadedRows,
    handleSubmit,
    onSubmit,
    excelHandleClick,
    fetchData,
    explorerDataKey,
    gridRef,
    mobileGridRef,
    explParams,
    toolbarRef,
    form,
    isDirty,
    gridData,
    valueListChips,
    setValueListChips,
    fieldSearchChips,
    setFieldSearchChips,
    records,
    navUrl,
    contextMenu: {
      contextMenuAnchor,
      contextMenuData,
      contextMenuCellData,
      contextMenuAllData,
      handleCloseContextMenu,
    },
  };
};

export default useExplorerGrid;
