// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Stack,
  Typography,
} from "@mui/material";

import LanguagePicker from "src/layout/LanguagePicker";

// assets
import { IconMenu2 } from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "src/store/authReducer";
import LogoSection from "../LogoSection";
import LogoutIcon from "@mui/icons-material/Logout";
import anynetLogo from "src/assets/images/anynet.svg";
import { AccountBalance, PersonOutlined } from "@mui/icons-material";
import { exitImpersonation } from "apis/controllers/admin";
import decodeToken from "util/decode-token";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { storeMenu } from "store/menuReducer";
import { login } from "store/authReducer";
import { useNavigate } from "react-router-dom";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { user, isLoggedIn, token } = useSelector((state) => state.auth);
  const data = decodeToken(token);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = async () => {
    await exitImpersonation()
      .then((res) => {
        dispatch(storeMenu(res?.menu));
        dispatch(
          login({
            token: res?.token,
            refreshToken: res?.refreshToken,
            user: res?.user,
          })
        );

        navigate("/home");
      })
      .catch((error) => {
        enqueueSnackbar(error || t("SomethingWrong"), {
          variant: "error",
        });
      });
  };

  return (
    <Box
      sx={{
        height: "31px",
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
      }}
    >
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
        >
          <Stack direction="row" spacing={2}>
            <LogoSection />
            <img src={anynetLogo} />
          </Stack>
        </Box>
      </Box>

      {/* header search */}
      {/* <SearchSection /> */}
      {/* <BreadcrumbSection /> */}

      <Box sx={{ flex: 0.3 }} />
      <Typography
        sx={{
          typography: {
            xs: "h6", // Extra small screen
            sm: "h5", // Small screen
            lg: "h4", // Large screen
          },
          color: "#fefefe !important",
        }}
      >
        Πρόγραμμα Μονάδων Ημιαυτόνομης Διαβίωσης (ΜΗΔ)
      </Typography>
      <Box sx={{ flex: 1 }} />
      {isLoggedIn && (
        <Stack direction="row" gap={2}>
          <div
            style={{
              display: "flex",
              direction: "row",
              alignItems: "center",
            }}
          >
            {data?.original_name ? (
              <Button
                type="submit"
                onClick={onSubmit}
                variant="contained"
                disableElevation
                color="error"
              >
                Exit Impersonation
              </Button>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              direction: "row",
              alignItems: "center",
            }}
          >
            <PersonOutlined sx={{ color: "#fefefe" }} />

            <Typography
              variant="h5"
              align="left"
              sx={{
                typography: {
                  xs: "h6", // Extra small screen
                  sm: "h5", // Small screen
                  lg: "h5", // Large screen
                },
                color: "#fefefe !important",
              }}
            >
              {user?.lastName} {user?.firstName}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              direction: "row",
              alignItems: "center",
            }}
          >
            <AccountBalance sx={{ color: "#fefefe" }} />
            <Typography variant="h5" align="left" sx={{ color: "#fefefe" }}>
              {user?.institute}
            </Typography>
          </div>
        </Stack>
      )}
      {/* <ImpersonationSection /> */}
      {/* <LanguagePicker /> */}
      {/* <FullNameSection
        firstName={whoami?.firstName}
        lastName={whoami?.lastName}
      />
      <ProfileSection /> */}
    </Box>
  );
};

export default Header;
