// project imports
import componentStyleOverrides from "./compStyleOverride";
import themePalette from "./palette";
import themeTypography from "./typography";
import { THEME_PRESETS } from "./presets";
import { createTheme } from "@mui/material/styles";

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */
const theme = (customization) => {
  const color = THEME_PRESETS[customization?.theme ?? "light"];

  const themeOption = {
    colors: color,
    heading: color.grey900,
    paper: color.paper,
    backgroundDefault: color.background,
    background: color.primaryLight,
    darkTextPrimary: color.darkTextPrimary,
    darkTextSecondary: color.darkTextSecondary,
    textDark: color.darkTextPrimary,
    menuSelected: color.secondaryDark,
    menuSelectedBack: color.primaryMain,
    divider: color.grey200,
    mode: color?.mode ?? "light",
    white: color.white,
    black: color.black,
  };

  const themeOptions = {
    direction: "ltr",
    palette: themePalette(themeOption),
    mixins: {
      toolbar: {
        minHeight: "48px",
        padding: "16px",
        "@media (min-width: 600px)": {
          minHeight: "48px",
        },
      },
    },
    typography: themeTypography(themeOption),
  };

  const themes = createTheme(themeOptions);
  themes.components = componentStyleOverrides(themeOption);

  return themes;
};

export default theme;
