import { ShortPreviewProps } from "../types";
import { Box } from "@mui/material";

const ShortPreview = (props: ShortPreviewProps) => {
    const { open, src } = props;

    if (!open) return null;

    if (typeof src === "string") {
        return (
            <Box>
                <img
                    src={src}
                    alt="Preview"
                    style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "contain",
                    }}
                />
            </Box>
        );
    } else {
        switch (src.type) {
            case "image/jpeg":
            case "image/png":
            case "image/gif":
                return (
                    <Box>
                        <img
                            src={URL.createObjectURL(src)}
                            alt={src.name}
                            style={{
                                width: "100%",
                                height: "auto",
                                objectFit: "contain",
                            }}
                        />
                    </Box>
                );
            case "application/pdf":
                return (
                    <Box>
                        <iframe
                            src={URL.createObjectURL(src)}
                            title={src.name}
                            style={{
                                width: "100%",
                                height: "55vh",
                                objectFit: "contain",
                            }}
                        />
                    </Box>
                );
            default:
                return null;
        }
    }
};

export default ShortPreview;
