import React, { createContext, useContext, useEffect, useState } from "react";

const LookupContext = createContext();

export const useLookup = () => useContext(LookupContext);

export const LookupProvider = ({ children }) => {
  const [lookups, setLookups] = useState({});

  const value = {
    lookups,
    setLookups,
  };

  return (
    <LookupContext.Provider value={value}>{children}</LookupContext.Provider>
  );
};
