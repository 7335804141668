import format from "date-fns/format";
import parse from "date-fns/parse";

function formatRequestObject(object) {
  /* eslint-disable */
  let formattedObject = [];
  for (const property in object) {
    formattedObject.push({ dataKey: property, dataValue: object[property] });
  }
  return formattedObject;
}

const formatCritFldSearch = (data) => {
  const helpData = data?.filter(
    (field) =>
      field?.value1 ||
      field?.defaultOperator === "isNull" ||
      field?.defaultOperator === "isEmpty"
  );

  return helpData?.map((field) => {
    const result = {};

    result.fldName = field?.fldSearchName;
    result.operator = field?.defaultOperator;
    if (field?.value1) result.value1 = field?.value1;
    if (field?.value2) result.value2 = field?.value2;
    if (field?.dataType === "datetime") {
      if (field?.value1)
        try {
          result.value1 = format(
            parse(field?.value1, "dd/MM/yyyy HH:mm", new Date()),
            "yyyy-MM-dd HH:mm"
          );
        } catch {
          console.log("comes from db");
        }
      if (field?.value2)
        try {
          result.value2 = format(
            parse(field?.value2, "dd/MM/yyyy HH:mm", new Date()),
            "yyyy-MM-dd HH:mm"
          );
        } catch {
          console.log("comes from db");
        }
    }
    if (field?.dataType === "date") {
      if (field?.value1)
        try {
          result.value1 = format(
            parse(field?.value1, "dd/MM/yyyy", new Date()),
            "yyyy-MM-dd"
          );
        } catch {
          console.log("comes from db");
        }
      if (field?.value2)
        try {
          result.value2 = format(
            parse(field?.value2, "dd/MM/yyyy", new Date()),
            "yyyy-MM-dd"
          );
        } catch {
          console.log("comes from db");
        }
    }
    return result;
  });
};

// format CriteriaValue List it is Generic
const formatValuesListSearch = (data) => {
  function containsTrue(array) {
    let result = false;
    array?.forEach((item) => {
      if (item?.isSelected) result = true;
    });
    return result;
  }
  const helpData = data?.filter((parent) => containsTrue(parent?.children));
  const result = helpData?.map((parent) => {
    const helpResult = parent?.children?.filter((child) => child.isSelected);
    const parentWithChildren = {};
    parentWithChildren.parentId = parent?.value;
    parentWithChildren.children = helpResult
      .map((child) => {
        return child?.value;
      })
      ?.toString();
    return parentWithChildren;
  });
  return result;
};

export { formatRequestObject, formatCritFldSearch, formatValuesListSearch };
