/* dont forget to pass navigate function as prop, unfortunately you cannot call react hook in a simple function in React */

export const dataKeyAthleteDetails = 'AthleteDetails';
export const dataKeyApplicationAthleteDetails = 'ApplicationAthleteDetails';
export const dataKeyApplicationOfficialDetails = 'ApplicationOfficialDetails';
export const dataKeyApplicationClubDetails = 'ApplicationClubDetails';
export const dataKeyOfficialDetails = 'OfficialDetails';
export const dataKeyTransactionHeaders = 'TransactionHeaders';
export const dataKeyAnyApplicationDetails = 'ApplicationDetails';

export const redirectTo = (navigate, dataKey, id, newWindow = false, type = null) => {
    let path;
    if (id) {
        switch (dataKey) {
            case dataKeyAthleteDetails:
                path = '/athletes/manage/{:id}/details';
                break;
            case dataKeyApplicationAthleteDetails:
                if (type === 'Certificate') path = '/applications/Athletes/certificate/{:id}';
                else if (type === 'Batch') path = '/applications/Athletes/batch/{:id}';
                else path = '/applications/Athletes/manage/{:id}';
                break;
            case dataKeyApplicationClubDetails:
                if (type === 'Certificate') path = '/applications/Clubs/certificate/{:id}';
                else if (type === 'Batch') path = '/applications/Clubs/batch/{:id}';
                else path = '/applications/Clubs/manage/{:id}';
                break;
            case dataKeyApplicationOfficialDetails:
                if (type === 'Certificate') path = '/applications/Officials/certificate/{:id}';
                else if (type === 'Batch') path = '/applications/Officials/batch/{:id}';
                else path = '/applications/Officials/manage/{:id}';
                break;
            case dataKeyTransactionHeaders:
                path = '/payments/card/{:id}';
                break;
            default:
                console.log('no redirection found');
        }
    }
    if (path) {
        const finalPath = path.replace('{:id}', id);
        if (newWindow) window.open(finalPath, '_blank');
        else navigate(finalPath);
    }
};
