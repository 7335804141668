import axiosInstance from "../axiosInstance";

export const applicationsApi = {
  post: (path, params, headers, cancelToken) =>
    axiosInstance
      .post(`/Applications${path}`, params, {
        cancelToken: cancelToken?.token,
        headers,
      })
      .then((res) => {
        if (res?.data?.error) throw res.data;
        return res?.data;
        // if (res?.data?.error || res?.data?.status === 'error') throw res.data;
      })
      .catch((err) => {
        throw err.error ? err : err?.response?.data;
      }),
  get: (path, params, headers, cancelToken) =>
    axiosInstance
      .get(
        `/Applications${path}`,
        { params },
        {
          cancelToken: cancelToken?.token,
          headers,
        }
      )
      .then((res) => {
        if (res?.data?.error) throw res.data;
        return res?.data;
      })
      .catch((err) => {
        throw err.error ? err : err?.response?.data;
      }),
};

export const getNewApplicationTypes = async () =>
  applicationsApi.get("/GetNewApplicationTypes");

export const getInstitutes = async (params) =>
  applicationsApi.post(
    "/GetInstitutesByTypeSelection",
    JSON.stringify(params),
    { "Content-Type": "application/json" }
  );

export const getAdditionalInstitutes = async (params) =>
  applicationsApi.post("/GetAdditionalInstitutes", JSON.stringify(params), {
    "Content-Type": "application/json",
  });

export const getApplications = async () =>
  applicationsApi.get("/GetApplications");

export const createApplication = async (params) =>
  applicationsApi.post("/Create", JSON.stringify(params), {
    "Content-Type": "application/json",
  });

export const getApplication = async (id) =>
  applicationsApi.get(`/GetApplication/${id}`);

export const getAmkaRegistryInfo = async (params) =>
  applicationsApi.get("/GetAmkaRegistryInfo", params);

export const updateApplication = async (params) => {
  return applicationsApi.post("/Update", JSON.stringify(params), {
    "Content-Type": "application/json",
  });
};
