export default function formatNumber(value) {
  // Round to two decimal places

  // Convert the number to a string for manipulation
  let [integerPart, decimalPart] = value.replace(/\./g, "").split(",");

  // Add thousand separator to the integer part
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  // Ensure two decimal places; if undefined, use '00'
  decimalPart = decimalPart ? decimalPart.padEnd(2, "0") : "00";
  if (decimalPart.length > 2) {
    decimalPart = decimalPart.substring(0, 2); // Ensures only two digits after rounding
  }

  // Concatenate integer part and decimal part
  return `${integerPart},${decimalPart}`;
}

export const showFloat = (number) => {
  const currDecimals = 2;
  const currDecimalSep = ",";

  const commasRemoved = `${number}`.replace(/\,/g, "");
  const value = parseFloat(commasRemoved).toFixed(currDecimals);
  const formatValue = parseFloat(value);
  const formatter = new Intl.NumberFormat(
    currDecimalSep === "," ? "el-GR" : "el-GR",
    {
      minimumFractionDigits: currDecimals,
      maximumFractionDigits: currDecimals,
    }
  );
  return formatter.format(formatValue);
};

export const formatFloat = (number) => {
  const dotsRemoved = `${number}`.replace(/\./g, "");
  const commasReplaced = dotsRemoved.replace(/\,/g, ".");
  const value = parseFloat(commasReplaced).toFixed(2);
  const formatValue = parseFloat(value);

  return formatValue;
};
