import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Collapse, Box, Stack, Typography } from "@mui/material";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";

const CustomSimpleAccordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className="qub-simple-accordion"
      style={{
        border: "1px solid #ccc",
        overflow: "hidden",
      }}
    >
      <div
        onClick={handleToggle}
        style={{
          cursor: "pointer",
          padding: "10px 15px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>{title}</div>
        <ExpandMoreIcon
          style={{
            transition: "transform 0.3s ease",
            transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
          }}
        />
      </div>
      <Collapse in={isOpen} timeout="auto">
        <Box
          sx={{
            padding: "10px 15px",
            backgroundColor: "#fff",
          }}
        >
          {content}
        </Box>
      </Collapse>
    </div>
  );
};

export default CustomSimpleAccordion;
