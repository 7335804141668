import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTheme } from "@emotion/react";
import { auth } from "src/apis";
import LoginBox from "./Components/LoginBox";
import { useDispatch } from "react-redux";
import { login } from "src/store/authReducer";
import { yupResolver } from "@hookform/resolvers/yup";
import loginResolver from "./Components/loginResolver";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import Intro from "./Components/Intro";
import { storeMenu } from "src/store/menuReducer";

// ==============================|| WRAPPER ||============================== //

const Login = () => {
  const dispatch = useDispatch();
  const loginForm = useForm({ resolver: yupResolver(loginResolver) });

  const navigate = useNavigate();
  const handleLogin = async (data) => {
    await auth
      .login({
        username: data?.email,
        password: data?.password,
      })
      .then((res) => {
        dispatch(
          login({
            token: res?.token,
            refreshToken: res?.refreshToken,
            user: res?.user,
          })
        );

        dispatch(storeMenu(res?.menu));
        navigate("/home");
      })

      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Ανεπιτυχής προσπάθεια σύνδεσης.", {
          variant: "error",
        });
      });
  };

  const theme = useTheme();

  return (
    <Grid
      container
      py={2}
      px={4}
      sx={{
        minHeight: "76vh",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <Grid container item xs={12} justifyContent={"center"} spacing={2}>
        <Grid item xs={12} mb={4}>
          <h1 align="center">
            Καλώς ήλθατε στην ηλεκτρονική πλατφόρμα του Προγράμματος
            Ημιαυτόνομης Διαβίωσης
          </h1>
        </Grid>

        <Grid item sm={10} md={7} lg={8}>
          <Intro />
        </Grid>
        <Grid
          container
          item
          sm={10}
          md={4}
          lg={3}
          sx={{ justifyContent: "center" }}
        >
          <LoginBox
            loginForm={loginForm}
            handleLogin={loginForm.handleSubmit(handleLogin)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
