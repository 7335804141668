import { Box, Typography, Button, Stack } from "@mui/material";
import { CloudUploadOutlined } from "@mui/icons-material";
import { useDropzone, FileRejection } from "React-dropzone";
import { DropAreaProps } from "../types";
import { fileSizeValidator } from "../utils";
// import { useMobileConnection } from 'context/SignalR/mobileActionsConnection';
import { useTranslation } from "react-i18next";
import { store } from "store";
import { ON } from "enums/signalrConnection";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/types";

const DropArea = (props: DropAreaProps) => {
    const { t } = useTranslation();
    const { onFilesSelected, onFilesRejected, maxSize, accept } = props;
    // const signalr = useSelector((state: RootState) => state.signalr);
    const { getRootProps, getInputProps, open, isDragActive, fileRejections } =
        useDropzone({
            onDrop: (acceptedFiles) => {
                onFilesSelected(acceptedFiles);
            },
            validator: (file) => fileSizeValidator({ file, maxSize }),
            noClick: true,
            noKeyboard: true,
            accept: accept || {
                "image/png": [".png"],
                "image/jpeg": [".jpg", ".jpeg"],
                "application/pdf": [".pdf"],
            },
        });
    // const { connection, openQrCode, requestPhoto } = useMobileConnection();

    const base64ToFile = (base64String: string, fileName: string) => {
        const byteCharacters = atob(base64String);
        const arrayBuffer = new ArrayBuffer(byteCharacters.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteCharacters.length; i++) {
            uint8Array[i] = byteCharacters.charCodeAt(i);
        }

        const blob = new Blob([uint8Array], { type: "image/png" });
        const file = new File([blob], fileName, { type: "image/png" });
        return file;
    };

    // const handlePhotoReceived = (data: string) => {
    //     if (connection) {
    //         connection.off(ON.actionResponse, handlePhotoReceived);
    //     }

    //     if (data) {
    //         const response: { actionId: string; actionType: string; userId: string; data: string } = JSON.parse(data);
    //         const date = new Date();
    //         const year = date.getFullYear();
    //         const month = String(date.getMonth() + 1).padStart(2, '0');
    //         const day = String(date.getDate()).padStart(2, '0');
    //         const file = base64ToFile(response.data, `image${year}${month}${day}.png`);
    //         onFilesSelected([file]);
    //     }
    // };

    // const handleRequestPhoto = () => {
    //     if (connection) {
    //         connection.on(ON.actionResponse, handlePhotoReceived);
    //         requestPhoto((data) => handlePhotoReceived(data));
    //     }
    // };

    // useEffect(() => {
    //     if (connection) {
    //         connection.on(ON.actionResponse, handlePhotoReceived);
    //     }
    // }, [connection]);

    useEffect(() => {
        if (onFilesRejected && fileRejections.length > 0) {
            onFilesRejected(fileRejections);
        }
    }, [fileRejections]);

    return (
        <Box>
            <div
                {...getRootProps({ className: "dropzone" })}
                style={{
                    minHeight: 70,
                    borderRadius: 4,
                    backgroundColor: isDragActive ? "#ecffe9" : "#fafafa",
                    border: isDragActive
                        ? "3px dashed #5ab497"
                        : "3px dashed #9badc7",
                }}
            >
                <input {...getInputProps()} />
                <Stack
                    direction="column"
                    spacing={1}
                    alignItems="center"
                    sx={{ py: 2 }}
                >
                    <CloudUploadOutlined
                        sx={{ color: isDragActive ? "#5ab497" : "#4d79bc" }}
                    />

                    <Typography>{t("DragToUpload")}, </Typography>
                    {/* <Typography>or</Typography> */}
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Button
                            size="small"
                            onClick={open}
                            disabled={isDragActive}
                            variant="contained"
                            sx={{
                                background: "#4d79bc",
                                color: "#fff",
                                ":hover": {
                                    background: "#79AEC9",
                                    color: "#fff",
                                },
                            }}
                        >
                            {t("OpenFileExplorer")}
                        </Button>
                        {/* <Typography>{t("or")}</Typography>
                        <Button
                            size="small"
                            onClick={signalr.isDevicePaired ? handleRequestPhoto : openQrCode}
                            disabled={isDragActive}
                            variant="contained"
                            sx={{
                                background: '#4d79bc',
                                color: '#fff',
                                ':hover': {
                                    background: '#79AEC9',
                                    color: '#fff'
                                }
                            }}
                        >
                            {signalr.isDevicePaired ? t('RequestPhoto') : t('PairDevice')}
                        </Button> */}
                    </Stack>
                </Stack>
            </div>
        </Box>
    );
};

export default DropArea;
