import { FileSizeValidatorProps } from "../types";

const fileSizeValidator = (props: FileSizeValidatorProps) => {
    const { file, maxSize } = props;

    if (!file.type.startsWith("image") && maxSize && file.size > maxSize) {
        return {
            code: "file-too-large",
            message: `File is too large. Max size is ${maxSize / 1048576}MB.`,
        };
    }
    return null;
};

export default fileSizeValidator;
