import {
  FlexGrid,
  FlexGridCellTemplate,
  FlexGridColumnGroup,
} from "@grapecity/wijmo.react.grid";
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import { wijmoExtendLastColumn } from "src/qubCommon/wijmoHelpers/columnMethods";

const QubGroupGrid = (props) => {
  const {
    gridColumns,
    gridData,
    initialized,
    editableColumns = [],
    children,
    gridLoadedRows,
  } = props;
  return (
    <FlexGrid
      allowResizing={3}
      initialized={initialized}
      loadedRows={gridLoadedRows}
      itemsSourceChanged={(flex) =>
        wijmoExtendLastColumn(flex, gridColumns, false, true)
      }
      itemsSource={gridData}
      alternatingRowStep={0}
      {...props}
      className="explorer-grid"
    >
      {children}
      <FlexGridFilter />
      {gridColumns?.length > 0 &&
        gridColumns.map((columnGroup, index) => {
          if (columnGroup?.children?.length > 0)
            return (
              <FlexGridColumnGroup
                key={columnGroup?.grpDescr}
                header={columnGroup?.grpDescr ?? ""}
                binding={`group${columnGroup?.grpId}`}
                align="center"
              >
                {columnGroup?.children?.map((child) => (
                  <FlexGridColumnGroup
                    key={child?.fldName}
                    isReadOnly={!editableColumns.includes(child.fldName)}
                    header={child?.fldTitle ?? ""}
                    binding={child?.fldName ?? ""}
                    width={
                      Number.isNaN(parseInt(child?.colWidth, 10)) ||
                      child?.colWidth?.includes("*")
                        ? child?.colWidth
                        : parseInt(child?.colWidth, 10)
                    }
                    format={child?.fldFormat}
                    multiLine={child?.multiline}
                    visible={child?.isVisible}
                    align={child?.colAlign ?? "justify"}
                  >
                    {child.cellTemplates?.map((ct, i) => (
                      <FlexGridCellTemplate
                        key={i}
                        cellType={ct.cellType}
                        template={(ctx) => ct.template(ctx, child)}
                      />
                    ))}
                  </FlexGridColumnGroup>
                ))}
              </FlexGridColumnGroup>
            );
          return (
            <FlexGridColumnGroup
              key={`${columnGroup?.grpDescr}${index}`}
              header={columnGroup?.fldTitle ?? ""}
              binding={columnGroup?.fldName ?? ""}
              visible={columnGroup?.isVisible}
              align="center"
            />
          );
        })}
    </FlexGrid>
  );
};

export default QubGroupGrid;
