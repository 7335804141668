import { auth } from "apis";
import { Can } from "context/permissionContenxt";
import { jwtDecode } from "jwt-decode";
import PageNotFound from "pages/PageNotFound";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { refreshToken as reduxRefreshToken } from "src/store/authReducer";

const HangfireDashboard = () => {
  const baseUrl = import.meta.env.VITE_REACT_APP_BASE_URL;
  const hangfireUrl = `${baseUrl}/hangfire`;
  const { token, refreshToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const jwtToken = token;

  const updateToken = async () => {
    const refreshResponse = await auth.refreshToken({
      refreshToken,
    });
    dispatch(reduxRefreshToken(refreshResponse));
  };

  useEffect(() => {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    const { iat, exp } = jwtDecode(jwtToken);

    if (currentTimestamp > exp) {
      updateToken();
    }
  }, []);

  return (
    <>
      <Can I="View" a="HangfireDashboard">
        <iframe
          src={`${hangfireUrl}?jwt_token=${jwtToken}`}
          style={{ width: "100%", height: "100%" }}
          title="Hangfire Dashboard"
        ></iframe>
      </Can>
      <Can not I="View" a="HangfireDashboard">
        <PageNotFound />
      </Can>
    </>
  );
};
export default HangfireDashboard;
