import { Typography } from "@mui/material";

const ColumnLabel = ({ label }) => {
  if (typeof label === "string")
    return (
      <Typography align="left" sx={{}}>
        {label}
      </Typography>
    );
  else return label;
};

export default ColumnLabel;
