import { createContext, PropsWithChildren } from 'react';
import { FileProviderProps, FileContextType } from './types';
import { useFileProvider } from './hooks';

const initialState: FileContextType = {
    fileGroups: [],
    control: {
        initialize: () => {},
        addBundle: () => {},
        updateBundle: () => {},
        deleteBundle: () => {},
        addFiles: () => {},
        updateFile: () => {},
        deleteFile: () => {},
        getFileObject: () => Promise.resolve(undefined),
        uploadFileGroups: () => Promise.resolve(),
        validateFiles: () => false,
        downloadTemplate: () => Promise.resolve(),
        isDirty: false
    }
};

export const FileContext = createContext<FileContextType>(initialState);

const FileProvider = (props: PropsWithChildren<FileProviderProps>) => {
    const { children } = props;
    const { fileGroups, control } = useFileProvider(props);
    
    return <FileContext.Provider value={{ fileGroups, control }}>{children}</FileContext.Provider>;
};

export default FileProvider;
